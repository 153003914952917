import { THEME } from '@/theme';
import { Button } from '@darrow-ai/comp-lib';
import { useContext, useMemo, useState } from 'react';

import { FilterContext } from '../../providers/FilterProvider';
import { AddFilterItem } from './AddFilterItem';
import FilterCounter from './FilterCounter';
import { FilterItem } from './FilterItem';
import './filter.css';

export const Filter = () => {
    const [openFilter, setOpenFilter] = useState('');
    const { filterCategories, selectedFilters, clearFilterValues } = useContext(FilterContext);

    const Filters = useMemo(() => {
        let filters = filterCategories
            .map(({ name, displayName, columnType }) => (
                <FilterItem
                    key={name}
                    name={name}
                    displayName={displayName}
                    columnType={columnType}
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                />
            ))
            .concat(<AddFilterItem key={'addFilter'} />);

        if (Array.isArray(selectedFilters) && selectedFilters.length > 0) {
            filters = filters.concat(
                <Button
                    variant="text"
                    key={'clearFilters'}
                    onClick={clearFilterValues}
                    style={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        color: THEME.COLORS.PURPLE.DEFAULT,
                    }}
                >
                    Clear All Filters
                </Button>,
            );
        }

        return filters;
    }, [filterCategories, openFilter, selectedFilters, clearFilterValues]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <div style={{ marginBottom: '1rem' }}>
                <FilterCounter />
            </div>

            <div>{Filters}</div>
        </div>
    );
};
