import { THEME } from '@/theme';
import { IconButton } from '@darrow-ai/darrow-design';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { forwardRef } from 'react';
import styled, { keyframes } from 'styled-components';

const overlayShow = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1 }
`;

const contentShow = keyframes`
    0% { opacity: 0; transform: translate(-50%, -48%) scale(.96) }
    100% { opacity: 1; transform: translate(-50%, -50%) scale(1) }
`;

const StyledOverlay = styled(DialogPrimitive.Overlay)`
    background-color: hsla(0, 0%, 0%, 0.439);
    position: fixed;
    inset: 0;
    @media (prefers-reduced-motion: no-preference) {
        animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    }
`;

const StyledContent = styled(DialogPrimitive.Content)`
    background-color: white;
    box-shadow: -4px 4px 4px rgba(21, 51, 108, 0.1);
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 450px;
    max-height: 85vh;
    padding: 12px 32px 12px;
    gap: 12px;

    @media (prefers-reduced-motion: no-preference) {
        animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    }

    &:focus {
        outline: none;
    }
`;

const Content = forwardRef<HTMLDivElement, DialogPrimitive.DialogContentProps>((props, ref) => {
    return (
        <DialogPrimitive.Portal>
            <StyledOverlay />
            <StyledContent ref={ref} {...props}>
                {props.children}
            </StyledContent>
        </DialogPrimitive.Portal>
    );
});

const StyledTitle = styled(DialogPrimitive.Title)`
    margin: 0px;
    font-weight: 400;
    font-size: 17px;
`;

const StyledDescription = styled(DialogPrimitive.Description)`
    margin: 10px 0 20px;
    font-size: 15px;
    line-height: 1.5px;
`;

const StyledHR = styled.hr`
    width: 100%;
    border: none;
    border-top: 1px solid ${THEME.COLORS.GRAY.LIGHT};
`;

type TitleProps = DialogPrimitive.DialogTitleProps & {
    title: string;
    withCloseButton?: boolean;
    handleClose?: () => void;
};

const Title = ({ title, withCloseButton, handleClose, ...props }: TitleProps) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                marginBottom: 20,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <DialogTitle {...props}>{title}</DialogTitle>

                {withCloseButton && (
                    <DialogClose asChild>
                        <IconButton
                            name="ClosePopup"
                            onClick={handleClose}
                            style={{ width: 16, height: 16 }}
                        />
                    </DialogClose>
                )}
            </div>

            <StyledHR />
        </div>
    );
};

// Exports
export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogContent = Content;
export const DialogTitle = StyledTitle;
export const DialogDescription = StyledDescription;
export const DialogClose = DialogPrimitive.Close;
export const DialogHR = StyledHR;
export const StyledDialogTitle = Title;
