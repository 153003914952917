import { StyledLink } from '@components/BasicComponents/StyledLink';

type ImportLeadsProps = {
    displayImportLeads: () => void;
};

export const ImportLeadsLink = ({ displayImportLeads }: ImportLeadsProps) => {
    return (
        <StyledLink style={{ margin: 'auto' }} onClick={() => displayImportLeads()}>
            Or Browse File To Add Multiple Leads
        </StyledLink>
    );
};
