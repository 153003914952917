import { THEME } from '@/theme';
import * as React from 'react';

const Tiki = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="291"
        height="199"
        viewBox="0 0 291 199"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginLeft: 95 }}
    >
        <circle cx="98" cy="86.5" r="73.5" fill={THEME.COLORS.GRAY.LIGHT} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M84.223 1.557C82.022 3.098 82 3.369 82 29.429V55.744L78.25 56.345C67.661 58.04 56.004 67.526 50.708 78.759C47.624 85.299 47.514 86.003 47.015 102.345L46.5 119.19L41.526 115.845C33.009 110.118 28.46 100.874 27.331 87C26.996 82.875 26.155 78.374 25.463 76.998C21.035 68.192 9.757 66.958 3.091 74.549C0.185999 77.858 0 78.552 0 86.087C0 114.682 17.522 139.948 41.15 145.423C44.278 146.147 59.984 146.336 89.15 146L132.5 145.5L137.5 142.825C147.032 137.726 152.886 130.461 155.456 120.54C156.757 115.519 157 105.941 157 59.744V4.909L154.545 2.455C153.195 1.105 151.392 0 150.537 0C149.682 0 144.968 3.6 140.06 8L131.138 16H119.15H107.161L101.831 11.227C88.051 -1.11 88.039 -1.116 84.223 1.557ZM108.6 36.4C111.387 39.187 112 40.557 112 44C112 49.727 106.99 55 101.55 55C94.918 55 90.461 51.53 89.991 46C89.532 40.595 90.119 38.692 93.106 35.901C95.579 33.591 97.126 33 100.706 33C104.484 33 105.742 33.542 108.6 36.4ZM144.889 35.56C152.829 42.241 147.979 55 137.5 55C132.321 55 128.596 52.335 126.995 47.484C123.464 36.785 136.232 28.276 144.889 35.56ZM97.073 39.635C95.933 41.084 95 43.048 95 44C95 47.089 98.911 51 102 51C105.276 51 109 47.031 109 43.539C109 40.611 105.001 37 101.758 37C100.126 37 98.367 37.989 97.073 39.635ZM131.944 38.844C127.154 42.723 130.332 51 136.611 51C139.906 51 143 47.61 143 44C143 38.007 136.641 35.041 131.944 38.844Z"
            fill="#6E80F6"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M131.81 103.057C131.81 103.611 132.258 104.059 132.812 104.059C133.365 104.059 133.814 103.611 133.814 103.057L133.814 96.2986H135.026C135.579 96.2986 136.026 95.8509 136.026 95.2986V87.8603H136.068C136.391 87.8603 136.653 87.5986 136.653 87.2758C136.653 86.953 136.391 86.6913 136.068 86.6913H134.231V84.0154C134.231 82.9108 133.336 82.0154 132.231 82.0154H131.933C131.93 81.5594 131.755 81.1043 131.407 80.7565C130.706 80.0554 129.569 80.0554 128.868 80.7565C128.52 81.1043 128.345 81.5594 128.343 82.0154H126.714C126.162 82.0154 125.714 82.4631 125.714 83.0154V86.6913H123.794C123.471 86.6913 123.209 86.953 123.209 87.2758C123.209 87.5986 123.471 87.8603 123.794 87.8603H123.919V95.2986C123.919 95.8509 124.367 96.2986 124.919 96.2986H126.132V103.057C126.132 103.611 126.581 104.059 127.134 104.059C127.687 104.059 128.136 103.611 128.136 103.057L128.136 96.2986L131.81 96.2986V103.057Z"
            fill="white"
        />
        <path
            d="M129 62C124.914 62 121.791 62 118.597 62C113.44 62 109.128 65.9221 108.642 71.0564L100.358 158.444C99.8718 163.578 95.5603 167.5 90.403 167.5H83.5C79.3579 167.5 76 170.858 76 175V175C76 179.142 79.3579 182.5 83.5 182.5H137.75C142.03 182.5 145.5 185.97 145.5 190.25V190.25C145.5 194.53 148.97 198 153.25 198H291"
            stroke="#6E80F6"
            strokeWidth="2"
        />
        <path
            d="M130 86.5L129.377 70.6081C129.166 65.2419 124.755 61 119.384 61C119.121 61 118.857 61 118.593 61C113.437 61 109.127 64.9196 108.638 70.0519L101.5 145"
            stroke="white"
            strokeWidth="2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M115.172 52C115.172 53.1046 116.067 54 117.172 54H117.172V56.5556C117.026 56.6455 116.89 56.7577 116.766 56.8922L115.469 58.299C114.72 59.1111 114.72 60.4279 115.469 61.2401L115.586 61.3673C116.335 62.1795 117.549 62.1795 118.297 61.3673L119.061 60.5392L119.961 61.5162C120.71 62.3283 121.924 62.3283 122.672 61.5162L122.79 61.3889C123.538 60.5767 123.538 59.26 122.79 58.4478L121.356 56.892C121.297 56.8284 121.235 56.7698 121.172 56.7162V54C122.276 54 123.172 53.1046 123.172 52C123.172 50.8954 122.276 50 121.172 50H117.172C116.067 50 115.172 50.8954 115.172 52Z"
            fill="white"
        />
        <circle cx="136.5" cy="43.5" r="7.5" fill="#6E80F6" />
        <circle cx="101.5" cy="43.5" r="7.5" fill="#6E80F6" />
    </svg>
);

export default Tiki;
