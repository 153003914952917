import { isEmpty } from 'lodash';
import { Row } from 'react-table';

import { EnrichedLead } from '../../types/Types';
import InvestigationPanel from '../InvestigationPanel/InvestigationPanel';
import { useIsEditable } from '../editors/hooks/useIsEditable';
import { BodyProps } from './LeadsTableBody';
import {
    FixedCellContent,
    NumberColumnContent,
    StyledCellContent,
    StyledSpacer,
    StyledTd,
    StyledTr,
} from './LeadsTableBody.styles';

export type TableRowProps = Pick<BodyProps, 'prepareRow'> & {
    row: Row<EnrichedLead>;
    selectedRowIds: any;
    previewMode?: boolean;
};

const TableRow = ({ row, prepareRow, selectedRowIds, previewMode }: TableRowProps) => {
    prepareRow(row);
    const isEditable = useIsEditable(row, row.allCells[0].column);

    return (
        <StyledTr
            //@ts-ignore
            isSelected={row.isSelected}
            selectedRowIds={selectedRowIds}
            previewMode={previewMode}
            // @ts-ignore
            editable={isEditable && !row.isSelected}
            {...row.getRowProps()}
        >
            {row.cells.map(cell => {
                let cellContent;

                if (cell.column.id === 'spacer') {
                    cellContent = <StyledSpacer />;
                } else if (cell.column.id === 'numbers') {
                    cellContent = (
                        <NumberColumnContent>
                            <InvestigationPanel lead={row.original} />

                            <span>{cell.render('Cell')}</span>
                        </NumberColumnContent>
                    );
                    //@ts-ignore
                } else if (cell.column.sticky) {
                    cellContent = <FixedCellContent>{cell.render('Cell')}</FixedCellContent>;
                } else {
                    cellContent = <StyledCellContent>{cell.render('Cell')}</StyledCellContent>;
                }

                return (
                    <StyledTd
                        {...cell.getCellProps()}
                        invalid={
                            !isEmpty(row.original.invalid) &&
                            cell.row.original.invalid[cell.column.Header as string]
                        }
                        //@ts-ignore
                        className={isEditable && !row.isSelected ? 'editable' : ''}
                    >
                        {cellContent}
                    </StyledTd>
                );
            })}
        </StyledTr>
    );
};

export default TableRow;
