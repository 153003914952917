import CoaEditor from '@/components/editors/CoaEditor';
import CoaSectionDropdownEditor from '@/components/editors/CoaSectionDropdownEditor';
import { Coa, CoaSection, CoaSectionMetaData, EnrichedLead, StaticColumns } from '@/types/Types';
import {
    Arbitrator,
    ColumnType,
    Country,
    LeadDecision,
    LeadStatus,
    OrganizationType,
    ServiceType,
    USState,
} from '@/types/enums';
import _ from 'lodash';

import ArbitratorEditor from '../components/editors/ArbitratorEditor';
import AssigneeEditor from '../components/editors/AssigneeEditor';
import BooleanEditor from '../components/editors/BooleanEditor';
import CompanyNameEditor from '../components/editors/CompanyNameEditor';
import CountryDropdownEditor from '../components/editors/CountryDropdownEditor';
import DateEditor from '../components/editors/DateEditor';
import DecisionEditor from '../components/editors/DecisionEditor';
import DropReasonsEditor from '../components/editors/DropReasonsEditor';
import InlineEditor from '../components/editors/InlineEditor';
import NumberEditor from '../components/editors/NumberEditor';
import OrganizationTypeEditor from '../components/editors/OrganizationTypeEditor';
import ServiceTypeEditor from '../components/editors/ServiceTypeEditor';
import StateDropdownEditor from '../components/editors/StateDropdownEditor';
import StatusEditor from '../components/editors/StatusEditor';
import {
    CELL_WIDTH,
    COA_SECTION_PREFIX,
    COA_SECTION_SPACER,
    DEFAULT_PAGE_SIZE,
    STATIC_COLUMNS_CONSTANTS,
} from '../constants';
import { remToPx } from '../utils';
import { DateColumn } from './DateColumn';
import RateComputedField from './RateComputedField';

export const getRowNumberColumns = () => [
    {
        id: STATIC_COLUMNS_CONSTANTS.NUMBERS.ACCESSOR,
        Header: () => <></>,
        Cell: (options: any) => {
            const startLeadIndex = options.state.pageIndex * DEFAULT_PAGE_SIZE + 1;

            return <div style={{ textAlign: 'center' }}>{startLeadIndex + options.row.index}</div>;
        },
        sticky: true,
        // Duo to the way the table is build (using <table>), this is needed
        // it calculates: cell-width + margin + border
        width: remToPx(2) + remToPx(0.75) * 2 + 1,
    },
    {
        id: STATIC_COLUMNS_CONSTANTS.SPACER.ACCESSOR,
        Header: '',
        cell: '',
        sticky: true,
        // Duo to the way the table is build (using <table>), this is needed
        // it calculates: cell-width + margin
        width: remToPx(1) + remToPx(0.75) * 2,
    },
];

export const enrichStaticColumns = (columns?: StaticColumns) => {
    if (!columns) {
        return [];
    }

    const {
        ANNUAL_REVENUE,
        ASSIGNEE,
        COA,
        COMPANY_MAIN_PLACE_OF_BUSINESS_COUNTRY,
        COMPANY_MAIN_PLACE_OF_BUSINESS_STATE,
        COMPANY_NAME,
        DATE_CREATED,
        DATE_MODIFIED,
        DCP,
        DOMAIN,
        INCORPORATION_COUNTRY,
        INCORPORATION_STATE,
        NUMBER_OF_EMPLOYEES,
        COMPANY_TYPE,
        START_DATE_OF_VIOLATION,
        STATUS,
        COMPANY_DOMAIN,
        NUMBER_OF_VICTIMS,
        DROP_REASONS,
        DECISION,
        COMMENTS,
        PRIVACY_POLICY_URL,
        TERMS_OF_SERVICE_URL,
        LEAD_SOURCE_URL,
        TOS_MENTIONS_ARBITRATION,
        TOS_MENTIONS_CLASS_WAIVER,
        TOS_JURISDICTION_VENUE,
        TOS_ARBITRATOR,
        CHOICE_OF_LAW_COUNTRY,
        CHOICE_OF_LAW_STATE,
        NUMBER_OF_USERS,
        SERVICE_TYPE,
        TRAFFIC,
        GLV,
        CASE_TIER,
        GRADING_SCORE,
        LEAD_ID,
        DAMAGES,
    } = columns;

    return [
        {
            Header: LEAD_ID.HEADER,
            accessor: LEAD_ID.ACCESSOR,
            type: LEAD_ID.TYPE,
            editableInBulk: false,
            explanation: 'Unique identifier for the lead; Leave empty for new leads',
            exampleValue: '123456',
        },
        {
            Header: STATUS.HEADER,
            accessor: STATUS.ACCESSOR,
            Cell: StatusEditor,
            type: STATUS.TYPE,
            options: LeadStatus,
            editableInBulk: true,
            exampleValue: LeadStatus.DONE,
        },
        {
            Header: DECISION.HEADER,
            accessor: DECISION.ACCESSOR,
            Cell: DecisionEditor,
            editableInBulk: false,
            explanation: `Decision made on the lead, one of: ${Object.values(LeadDecision).join(
                ', ',
            )}`,
            exampleValue: LeadDecision.TAKE,
        },
        {
            Header: DROP_REASONS.HEADER,
            accessor: DROP_REASONS.ACCESSOR,
            Cell: DropReasonsEditor,
            type: DROP_REASONS.TYPE,
            editableInBulk: false,
        },
        {
            Header: ASSIGNEE.HEADER,
            accessor: ASSIGNEE.ACCESSOR,
            Cell: AssigneeEditor,
            type: ASSIGNEE.TYPE,
            editableInBulk: true,
            explanation: 'User email of the assignee of the lead',
        },
        { Header: DOMAIN.HEADER, accessor: DOMAIN.ACCESSOR, editableInBulk: false },
        {
            Header: COA.HEADER,
            accessor: COA.ACCESSOR,
            type: COA.TYPE,
            editableInBulk: true,
            Cell: CoaEditor,
            explanation: 'CoA must match value in D-Manager; Mandatory field',
            exampleValue: 'CIPA',
        },
        {
            Header: DCP.HEADER,
            accessor: DCP.ACCESSOR,
            editableInBulk: false,
            explanation: 'Mandatory field',
            exampleValue: 'CIPA DCP 14: quiz keywords health industry websites',
        },
        {
            Header: LEAD_SOURCE_URL.HEADER,
            accessor: LEAD_SOURCE_URL.ACCESSOR,
            Cell: InlineEditor,
            type: LEAD_SOURCE_URL.TYPE,
            editableInBulk: true,
            explanation: 'Mandatory field',
            exampleValue: 'https://www.facebook.com',
        },
        {
            Header: COMPANY_NAME.HEADER,
            accessor: COMPANY_NAME.ACCESSOR,
            Cell: CompanyNameEditor,
            editableInBulk: false,
            explanation: 'Mandatory field',
        },
        {
            Header: COMPANY_DOMAIN.HEADER,
            accessor: COMPANY_DOMAIN.ACCESSOR,
            Cell: InlineEditor,
            type: COMPANY_DOMAIN.TYPE,
            editableInBulk: true,
            explanation: 'Must be the main domain, incluing TLD',
            exampleValue: 'facebook.com',
        },
        {
            Header: COMPANY_MAIN_PLACE_OF_BUSINESS_COUNTRY.HEADER,
            accessor: COMPANY_MAIN_PLACE_OF_BUSINESS_COUNTRY.ACCESSOR,
            Cell: CountryDropdownEditor,
            type: COMPANY_MAIN_PLACE_OF_BUSINESS_COUNTRY.TYPE,
            options: Country,
            editableInBulk: true,
        },
        {
            Header: COMPANY_MAIN_PLACE_OF_BUSINESS_STATE.HEADER,
            accessor: COMPANY_MAIN_PLACE_OF_BUSINESS_STATE.ACCESSOR,
            Cell: StateDropdownEditor,
            type: COMPANY_MAIN_PLACE_OF_BUSINESS_STATE.TYPE,
            options: USState,
            editableInBulk: true,
        },
        {
            Header: COMPANY_TYPE.HEADER,
            accessor: COMPANY_TYPE.ACCESSOR,
            Cell: OrganizationTypeEditor,
            type: COMPANY_TYPE.TYPE,
            options: OrganizationType,
            editableInBulk: true,
            exampleValue: OrganizationType.PUBLIC,
        },
        {
            Header: ANNUAL_REVENUE.HEADER,
            accessor: ANNUAL_REVENUE.ACCESSOR,
            Cell: NumberEditor,
            type: ANNUAL_REVENUE.TYPE,
            editableInBulk: true,
            explanation: 'Numbers only',
            exampleValue: '200,000'
        },
        {
            Header: INCORPORATION_COUNTRY.HEADER,
            accessor: INCORPORATION_COUNTRY.ACCESSOR,
            Cell: CountryDropdownEditor,
            type: INCORPORATION_COUNTRY.TYPE,
            options: Country,
            editableInBulk: true,
        },
        {
            Header: INCORPORATION_STATE.HEADER,
            accessor: INCORPORATION_STATE.ACCESSOR,
            Cell: StateDropdownEditor,
            type: INCORPORATION_STATE.TYPE,
            options: USState,
            editableInBulk: true,
        },
        {
            Header: NUMBER_OF_EMPLOYEES.HEADER,
            accessor: NUMBER_OF_EMPLOYEES.ACCESSOR,
            Cell: NumberEditor,
            type: NUMBER_OF_EMPLOYEES.TYPE,
            editableInBulk: true,
        },
        {
            Header: TERMS_OF_SERVICE_URL.HEADER,
            accessor: TERMS_OF_SERVICE_URL.ACCESSOR,
            Cell: InlineEditor,
            type: TERMS_OF_SERVICE_URL.TYPE,
            editableInBulk: true,
        },
        {
            Header: TOS_MENTIONS_ARBITRATION.HEADER,
            accessor: TOS_MENTIONS_ARBITRATION.ACCESSOR,
            Cell: BooleanEditor,
            type: TOS_MENTIONS_ARBITRATION.TYPE,
            editableInBulk: true,
        },
        {
            Header: TOS_MENTIONS_CLASS_WAIVER.HEADER,
            accessor: TOS_MENTIONS_CLASS_WAIVER.ACCESSOR,
            Cell: BooleanEditor,
            type: TOS_MENTIONS_CLASS_WAIVER.TYPE,
            editableInBulk: true,
        },
        {
            Header: TOS_JURISDICTION_VENUE.HEADER,
            accessor: TOS_JURISDICTION_VENUE.ACCESSOR,
            Cell: StateDropdownEditor,
            type: TOS_JURISDICTION_VENUE.TYPE,
            options: USState,
            editableInBulk: true,
        },
        {
            Header: TOS_ARBITRATOR.HEADER,
            accessor: TOS_ARBITRATOR.ACCESSOR,
            Cell: ArbitratorEditor,
            type: TOS_ARBITRATOR.TYPE,
            options: Arbitrator,
            editableInBulk: true,
        },
        {
            Header: NUMBER_OF_USERS.HEADER,
            accessor: NUMBER_OF_USERS.ACCESSOR,
            Cell: NumberEditor,
            type: NUMBER_OF_USERS.TYPE,
            editableInBulk: true,
            explanation: 'Number of users of the service',
            exampleValue: 1000000,
        },
        {
            Header: START_DATE_OF_VIOLATION.HEADER,
            accessor: START_DATE_OF_VIOLATION.ACCESSOR,
            Cell: DateEditor,
            type: START_DATE_OF_VIOLATION.TYPE,
            editableInBulk: true,
            explanation: 'Dates must adhere to ISO 8601 (YYYY-MM-DD) format',
            exampleValue: '2023-06-11',
        },
        {
            Header: TRAFFIC.HEADER,
            accessor: TRAFFIC.ACCESSOR,
            Cell: NumberEditor,
            type: TRAFFIC.TYPE,
            editableInBulk: true,
        },
        {
            Header: NUMBER_OF_VICTIMS.HEADER,
            accessor: NUMBER_OF_VICTIMS.ACCESSOR,
            Cell: NumberEditor,
            type: NUMBER_OF_VICTIMS.TYPE,
            editableInBulk: true,
        },
        {
            Header: PRIVACY_POLICY_URL.HEADER,
            accessor: PRIVACY_POLICY_URL.ACCESSOR,
            Cell: InlineEditor,
            type: PRIVACY_POLICY_URL.TYPE,
            editableInBulk: true,
        },
        {
            Header: SERVICE_TYPE.HEADER,
            accessor: SERVICE_TYPE.ACCESSOR,
            Cell: ServiceTypeEditor,
            type: SERVICE_TYPE.TYPE,
            options: ServiceType,
            editableInBulk: true,
            explanation: `One of: ${Object.values(ServiceType).join(', ')}`,
        },
        {
            Header: CHOICE_OF_LAW_COUNTRY.HEADER,
            accessor: CHOICE_OF_LAW_COUNTRY.ACCESSOR,
            Cell: CountryDropdownEditor,
            type: CHOICE_OF_LAW_COUNTRY.TYPE,
            options: Country,
            editableInBulk: true,
        },
        {
            Header: CHOICE_OF_LAW_STATE.HEADER,
            accessor: CHOICE_OF_LAW_STATE.ACCESSOR,
            Cell: StateDropdownEditor,
            type: CHOICE_OF_LAW_STATE.TYPE,
            options: USState,
            editableInBulk: true,
        },
        {
            Header: GLV.HEADER,
            accessor: GLV.ACCESSOR,
            type: GLV.TYPE,
            editableInBulk: false,
        },
        {
            Header: CASE_TIER.HEADER,
            accessor: CASE_TIER.ACCESSOR,
            type: CASE_TIER.TYPE,
            editableInBulk: false,
        },
        {
            Header: GRADING_SCORE.HEADER,
            accessor: GRADING_SCORE.ACCESSOR,
            type: GRADING_SCORE.TYPE,
            editableInBulk: false,
        },
        {
            Header: DATE_CREATED.HEADER,
            accessor: DATE_CREATED.ACCESSOR,
            Cell: DateColumn,
            type: DATE_CREATED.TYPE,
            editableInBulk: true,
        },
        {
            Header: DATE_MODIFIED.HEADER,
            accessor: DATE_MODIFIED.ACCESSOR,
            Cell: DateColumn,
            type: DATE_MODIFIED.TYPE,
            editableInBulk: true,
        },
        {
            Header: COMMENTS.HEADER,
            accessor: COMMENTS.ACCESSOR,
            Cell: InlineEditor,
            type: COMMENTS.TYPE,
            editableInBulk: true,
        },
        {
            Header: DAMAGES.HEADER,
            accessor: DAMAGES.ACCESSOR,
            Cell: NumberEditor,
            type: DAMAGES.TYPE,
            editableInBulk: true,
            explanation: 'Damages caused by the lead',
            exampleValue: 100000,
        },
    ];
};

export const getCOASectionColumns = (
    coas: Partial<Coa>[],
    coaSectionsMetaData: CoaSectionMetaData[],
): any[] => {
    const allCoaSections =
        coas?.flatMap(
            coa =>
                coa.coaSections?.map(coaSection => {
                    const coaSectionMetaData = coaSectionsMetaData?.find(
                        item => item.sectionId === coaSection.id,
                    );

                    return {
                        accessor: `${COA_SECTION_PREFIX}${coaSection.id}`,
                        coa,
                        coaSection,
                        coaSectionMetaData,
                    };
                }) ?? [],
        ) || [];

    const uniqCoaSectionsColumns = groupDuplicates(allCoaSections).map(parseColumn);
    return uniqCoaSectionsColumns;
};

export const getAllColumns = (
    coas: Partial<Coa>[],
    coaSectionsMetaData: CoaSectionMetaData[],
    staticColumns: StaticColumns,
) => {
    const dynamicColumns = getCOASectionColumns(coas, coaSectionsMetaData);
    const allColumns = [...enrichStaticColumns(staticColumns), ...dynamicColumns].map(col => ({
        ...col,
        // Duo to the way the table is build (using <table>), this is needed
        // it calculates: cell-width + margin + border
        width: remToPx(CELL_WIDTH) + remToPx(0.75) * 2 + 1,
    }));

    return allColumns;
};

const cellComponentCreator =
    (coas: Partial<Coa>[], coaSection: CoaSection, coaSectionMetaData?: CoaSectionMetaData) =>
    (options: any) => {
        const rowLead: EnrichedLead = options.row.original;
        const rowCoa = rowLead.dcp.coa;
        if (!coas.some(coa => coa.id === rowCoa?.id)) {
            return <div />;
        }

        const leadCoaSectionsMap = new Map(
            rowCoa?.coaSections?.map(section => [section.id, section]),
        );

        const cellCoaSection = leadCoaSectionsMap.get(coaSection.id);

        const tcpaCoa = coas.find(({ name }) => name === 'TCPA');

        if (tcpaCoa && coaSection.name === 'Rate') {
            let ftcComplaintsId, numberOfCommentsId, ftcRobocallsComplaintsId, nameSimilarityAvgId;
            for (const section of tcpaCoa.coaSections || []) {
                switch (section.name) {
                    case 'FTC Complaints Number':
                        ftcComplaintsId = section.id;
                        break;
                    case 'Number of Comments':
                        numberOfCommentsId = section.id;
                        break;
                    case 'FTC Robocalls Complaints':
                        ftcRobocallsComplaintsId = section.id;
                        break;
                    case 'Similarity AVG':
                        nameSimilarityAvgId = section.id;
                        break;
                }
            }

            const ftcComplaints =
                ftcComplaintsId && leadCoaSectionsMap.get(ftcComplaintsId)?.answer;
            const numberOfComments =
                numberOfCommentsId && leadCoaSectionsMap.get(numberOfCommentsId)?.answer;
            const ftcRobocallsComplaints =
                ftcRobocallsComplaintsId &&
                leadCoaSectionsMap.get(ftcRobocallsComplaintsId)?.answer;
            const nameSimilarityAvg =
                nameSimilarityAvgId && leadCoaSectionsMap.get(nameSimilarityAvgId)?.answer;

            return (
                <RateComputedField
                    sum={ftcComplaints + numberOfComments}
                    ratio={ftcRobocallsComplaints / ftcComplaints}
                    avg={nameSimilarityAvg}
                />
            );
        }

        switch (coaSectionMetaData?.type) {
            case ColumnType.NUMBER:
                return (
                    <NumberEditor
                        value={cellCoaSection ? cellCoaSection.answer : ''}
                        updateLead={options.updateLead}
                        row={options.row}
                        column={options.column}
                        previewMode={options.previewMode}
                        disabled={coaSectionMetaData?.editable === false}
                    />
                );
            case ColumnType.BOOLEAN:
                return (
                    <BooleanEditor
                        value={cellCoaSection ? cellCoaSection.answer : ''}
                        updateLead={options.updateLead}
                        row={options.row}
                        column={options.column}
                        previewMode={options.previewMode}
                        disabled={coaSectionMetaData?.editable === false}
                    />
                );
            case ColumnType.DATE:
                return (
                    <DateEditor
                        value={cellCoaSection ? cellCoaSection.answer : ''}
                        updateLead={options.updateLead}
                        row={options.row}
                        column={options.column}
                        previewMode={options.previewMode}
                        disabled={coaSectionMetaData?.editable === false}
                    />
                );
            case ColumnType.SINGLE_SELECT:
                return (
                    <CoaSectionDropdownEditor
                        data={coaSectionMetaData?.options?.data}
                        value={cellCoaSection ? cellCoaSection.answer : ''}
                        updateLead={options.updateLead}
                        row={options.row}
                        column={options.column}
                        previewMode={options.previewMode}
                        disabled={coaSectionMetaData?.editable === false}
                    />
                );
            default:
                return (
                    <InlineEditor
                        value={cellCoaSection ? cellCoaSection.answer : ''}
                        updateLead={options.updateLead}
                        row={options.row}
                        column={options.column}
                        previewMode={options.previewMode}
                        disabled={coaSectionMetaData?.editable === false}
                    />
                );
        }
    };

function groupDuplicates(
    coaSections: {
        accessor: string;
        coa: Partial<Coa>;
        coaSection: CoaSection;
        coaSectionMetaData?: CoaSectionMetaData;
    }[],
) {
    return Object.values(_.groupBy(coaSections, 'accessor')).flatMap(accessorCoaSections => {
        const coas = accessorCoaSections.map(({ coa }) => coa);

        const { coaSection, coaSectionMetaData, accessor } = accessorCoaSections[0];

        return {
            accessor,
            coas,
            coaSection,
            coaSectionMetaData,
        };
    });
}

function parseColumn(coaSectionData: {
    accessor: string;
    coas: Partial<Coa>[];
    coaSection: CoaSection;
    coaSectionMetaData?: CoaSectionMetaData;
}) {
    const { accessor, coas, coaSectionMetaData, coaSection } = coaSectionData;
    const Header = `${coas[0].name}${
        coas.length > 1 ? ` (+${coas.length - 1})` : ''
    }${COA_SECTION_SPACER}${coaSection.name}`.trim();

    return {
        Header,
        accessor,
        coas,
        Cell: cellComponentCreator(coas, coaSection, coaSectionMetaData),
        type: coaSectionMetaData?.type ?? ColumnType.TEXT,
        editableInBulk: coaSectionMetaData?.editable,
        editable: coaSectionMetaData?.editable,
        options: coaSectionMetaData?.options?.data,
        isCoaSection: true,
    };
}
