import { ButtonHTMLAttributes, forwardRef } from 'react';

import { PanelTriggerIconButton } from './InvestigationPanel.styles';
import PanelTriggerIcon from './PanelTriggerIcon';

type PanelTriggerProps = ButtonHTMLAttributes<HTMLButtonElement> & {};

const PanelTrigger = forwardRef<HTMLButtonElement, PanelTriggerProps>(({ ...props }, ref) => (
    <PanelTriggerIconButton ref={ref} style={{ width: 26, height: 26 }} {...props}>
        <PanelTriggerIcon />
    </PanelTriggerIconButton>
));

export default PanelTrigger;
