import { Icon, IconButton } from '@darrow-ai/darrow-design';
import { useContext } from 'react';

import { ASSIGNEE_FILTER_KEY } from '../../constants';
import { FilterContext } from '../../providers/FilterProvider';
import { PageContext } from '../../providers/PageProvider';
import { getFilterValueLabel } from '../../utils';
import { Chip } from '../BasicComponents/Chips/Chip';
import { ChipContent } from '../BasicComponents/Chips/StyledChip';

const DELIMITER = ' - ';

export const SelectedFilters = () => {
    const { selectedFilters, removeFilterValue } = useContext(FilterContext);
    const { setPageIndex } = useContext(PageContext);
    const ChipElements = selectedFilters.map((selectedFilter, i) => {
        const { accessor, displayName, value } = selectedFilter;
        const removeChip = () => {
            removeFilterValue({ accessor, displayName, value });
            setPageIndex(0);
        };

        if (!displayName) return null;

        return (
            <Chip key={i}>
                {accessor === ASSIGNEE_FILTER_KEY && (
                    <div style={{ width: 20, height: 20, padding: 3 }}>
                        <Icon name="UserCircle" />
                    </div>
                )}
                <ChipContent>
                    {displayName}
                    {DELIMITER}
                    {getFilterValueLabel(value, accessor)}
                </ChipContent>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 16,
                        height: 16,
                        marginLeft: 5,
                    }}
                >
                    <IconButton
                        name="ClosePopup"
                        onClick={() => removeChip()}
                        style={{ width: 13, height: 13 }}
                    />
                </div>
            </Chip>
        );
    });

    return <div style={{ display: 'flex', flexWrap: 'wrap' }}>{ChipElements}</div>;
};
