import styled from 'styled-components';

interface StyledBadgeProps {
    isMulti?: boolean;
}

export const StyledBadge = styled.div<StyledBadgeProps>`
    display: inline-block;
    padding: 3px 12px;
    margin: 0 5px;
    gap: 10px;

    width: 137px;
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;

    background: ${props =>
        props.isMulti
            ? 'linear-gradient( 72.17deg,rgba(194, 228, 233, 0.8) -81.34%,rgba(176, 217, 255, 0.8) 13.66%);'
            : `linear-gradient(48.76deg, rgba(255, 177, 190, 0.48) 3.7%, rgba(255, 215, 113, 0.6) 190.91%)`};
    border-radius: 60px;
`;
