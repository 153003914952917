// import { Button } from '@darrow-ai/darrow-design';
import { DialogProps } from '@radix-ui/react-dialog';
import { FormEventHandler, useState } from 'react';

import useDropReasons from '../../hooks/useDropReasons';
import { DropReason } from '../../types/Types';
import { getUpdatedValue } from '../../utils';
import RippleLoader from '../BasicComponents/Loaders/RippleLoader';
import DropReasonsMultiSelect from '../BasicComponents/MultiSelect/DropReasonsMultiSelect';
import { Dialog, DialogContent, StyledDialogTitle } from './StyledDialog';

type DropReasonsDialogProps = DialogProps & {
    updateDropReasons: (dropReasons: Partial<DropReason>[]) => any;
};

const DropReasonsDialog = ({ open, onOpenChange, updateDropReasons }: DropReasonsDialogProps) => {
    const [dropReasons, setDropReasons] = useState<{ label: string; value: string }[]>([]);

    const { isLoading, isError, mutate } = useDropReasons();

    const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault();

        const formattedDropReasons = getUpdatedValue(dropReasons, 'dropReasons');

        updateDropReasons(formattedDropReasons);

        setDropReasons([]);

        onOpenChange?.(false);

        mutate();
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent>
                <StyledDialogTitle title="Drop Reason" withCloseButton />

                {isLoading && <RippleLoader />}

                {isError && <div>An error occurred while fetching the drop reasons...</div>}

                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 15,
                        marginTop: 25,
                        justifyContent: 'center',
                    }}
                >
                    <label htmlFor="drop-reasons-multi-select">Drop Reasons</label>
                    <DropReasonsMultiSelect
                        id="drop-reasons-multi-select"
                        value={dropReasons}
                        onChange={setDropReasons}
                    />

                    {/* TODO: Should be design system button */}
                    <button
                        type="submit"
                        style={{
                            backgroundColor: '#6E80F6',
                            borderRadius: 5,
                            padding: '6px 32px',
                            color: 'white',
                        }}
                    >
                        Drop it!
                    </button>
                </form>
            </DialogContent>
        </Dialog>
    );
};
export default DropReasonsDialog;
