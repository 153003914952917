import { THEME } from '@/theme';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@darrow-ai/darrow-design';

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <Button
            style={{
                backgroundColor: THEME.COLORS.PURPLE.DEFAULT,
                borderRadius: 5,
                padding: '6px 32px',
                margin: '250px auto',
                color: 'white',
            }}
            onClick={() => loginWithRedirect()}
        >
            Log In
        </Button>
    );
};

export default LoginButton;
