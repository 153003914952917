import TooltipIconButton from '@/components/BasicComponents/TooltipIconButton';
import { SERVER_URL } from '@/constants';
import { AccessTokenContext } from '@/providers/AccessTokenProvider';
import { ColumnsContext, ColumnsContextType } from '@/providers/ColumnsProvider';
import { downloadFile } from '@/utils';
import axios from 'axios';
import { useContext } from 'react';

const ExportButton = ({ filtersQuery }: { filtersQuery: string }) => {
    const { accessToken } = useContext(AccessTokenContext);
    const {selectedColumns} = useContext(ColumnsContext) as ColumnsContextType;
    const handleClick = async () => {
        const { data: fileContent } = await axios.get(
            `${SERVER_URL}/leads?${filtersQuery}&format=csv&columns=${selectedColumns.map(column => column.accessor).join(',')}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        const filename = `Leads_${new Date().toISOString().slice(0, 19)}.csv`;
        downloadFile(fileContent, filename);
    };

    return (
        <TooltipIconButton
            content="Export"
            iconName="Export"
            style={{
                width: 16,
                height: 16,
                marginRight: 12,
            }}
            onClick={handleClick}
        />
    );
};

export default ExportButton;
