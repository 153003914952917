import RippleLoader from '@/components/BasicComponents/Loaders/RippleLoader';

const TableLoader = () => {
    return (
        <tbody
            style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
            }}
        >
            <tr>
                <td>
                    <RippleLoader />
                </td>
            </tr>
        </tbody>
    );
};

export default TableLoader;
