import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

import { DEFAULT_PAGE_SIZE } from '../constants';
import { TableProfileContext } from './TableProfileProvider';

interface PageContextProps {
    children: ReactNode;
}

export interface PageContextType {
    pageIndex: number;
    setPageIndex: Dispatch<SetStateAction<number>>;
    totalDataCount: number;
    setTotalDataCount: Dispatch<SetStateAction<number>>;
    pageCount: number;
    canPreviousPage: () => boolean;
    canNextPage: () => boolean;
    gotoPage: (pageNUmber: number) => void;
    nextPage: () => void;
    previousPage: () => void;
}

const defaultState: PageContextType = {
    pageIndex: 0,
    setPageIndex: () => {},
    totalDataCount: 0,
    setTotalDataCount: () => {},
    pageCount: 0,
    canPreviousPage: () => false,
    canNextPage: () => false,
    gotoPage: () => {},
    nextPage: () => {},
    previousPage: () => {},
};

export const PageContext = createContext<PageContextType>(defaultState);
PageContext.displayName = 'PageContext';

export const PageProvider = ({ children }: PageContextProps) => {
    const { tableProfile } = useContext(TableProfileContext);
    const [pageIndex, setPageIndex] = useState(0);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [pageCount, setPageCount] = useState(Math.ceil(totalDataCount ?? 0 / DEFAULT_PAGE_SIZE));

    useEffect(() => {
        setPageIndex(0);
    }, [tableProfile]);

    const canPreviousPage = () => pageIndex > 0;
    const canNextPage = () => pageIndex < pageCount - 1;
    const gotoPage = (pageNumber: number) => {
        setPageIndex(pageNumber);
    };

    const nextPage = () => canNextPage() && gotoPage(pageIndex + 1);
    const previousPage = () => canPreviousPage() && gotoPage(pageIndex - 1);

    useEffect(() => {
        setPageCount(Math.ceil(totalDataCount / DEFAULT_PAGE_SIZE));
    }, [totalDataCount]);

    return (
        <PageContext.Provider
            value={{
                pageIndex,
                setPageIndex,
                totalDataCount,
                setTotalDataCount,
                pageCount,
                canPreviousPage,
                canNextPage,
                gotoPage,
                nextPage,
                previousPage,
            }}
        >
            {children}
        </PageContext.Provider>
    );
};
