import RangeDatePicker from '@/components/BasicComponents/DatePicker/RangeDatePicker';
import { FilterContext } from '@/providers/FilterProvider';
import { PageContext } from '@/providers/PageProvider';
import { DateRange } from '@/types/Types';
import { useContext } from 'react';

type FilterDateRangeProps = {
    name: string;
    displayName: string;
};

const FilterDateRange = ({ name, displayName }: FilterDateRangeProps) => {
    const { saveFilterValue } = useContext(FilterContext);

    const { setPageIndex } = useContext(PageContext);

    const onChange = (range: DateRange) => {
        saveFilterValue({ accessor: name, displayName, value: range });
        setPageIndex(0);
    };

    return <RangeDatePicker onRangeSelect={onChange} />;
};

export default FilterDateRange;
