import CircleLoader from '@/components/BasicComponents/Loaders/CircleLoader';
import useLeads from '@/hooks/useLeads/useLeads';
import {
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight,
} from '@styled-icons/heroicons-solid';

import { DEFAULT_PAGE_SIZE } from '../../constants';
import {
    LeadsTablePaginationButton,
    LeadsTablePaginationContainer,
} from './LeadsTablePagination.styles';

type LeadsTablePaginationProps = {
    pageIndex: number;
    pageCount: number;
    totalLeads: number;
    pageSize: number;
    canNextPage: boolean;
    canPreviousPage: boolean;
    nextPage: () => void;
    previousPage: () => void;
    gotoPage: (index: number) => void;
};

export const LeadsTablePagination = ({
    pageIndex,
    pageCount,
    totalLeads,
    pageSize,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    gotoPage,
}: LeadsTablePaginationProps) => {
    const { isTotalCountLoading: isLoading } = useLeads();

    const endLeadIndex =
        totalLeads - pageIndex * pageSize > DEFAULT_PAGE_SIZE
            ? (pageIndex + 1) * pageSize
            : totalLeads;
    const startLeadIndex = pageIndex * pageSize + 1;

    return (
        <LeadsTablePaginationContainer>
            <span style={{ marginRight: '0.75rem' }}>
                <strong>Page {pageIndex + 1}</strong>
            </span>
            <LeadsTablePaginationButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <ChevronDoubleLeft style={{ height: '1rem' }} />
            </LeadsTablePaginationButton>
            <LeadsTablePaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                <ChevronLeft style={{ height: '1rem' }} />
            </LeadsTablePaginationButton>

            {isLoading ? (
                <CircleLoader style={{ height: '1.25rem', width: '1.25rem', margin: 0 }} />
            ) : (
                <span style={{ margin: '0 0.25rem' }}>
                    {startLeadIndex}-{endLeadIndex} Of {totalLeads}
                </span>
            )}
            <LeadsTablePaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
                <ChevronRight style={{ height: '1rem' }} />
            </LeadsTablePaginationButton>
            <LeadsTablePaginationButton
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
            >
                <ChevronDoubleRight style={{ height: '1rem' }} />
            </LeadsTablePaginationButton>
        </LeadsTablePaginationContainer>
    );
};
