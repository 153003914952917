import { useEffect, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { DropReasonColumn } from '../../columns/DropReasonColumn';
import { DropReason, EnrichedLead, UpdateLeadCallback } from '../../types/Types';
import { LeadDecision } from '../../types/enums';
import { getUpdatedValue } from '../../utils';
import DropReasonsMultiSelect from '../BasicComponents/MultiSelect/DropReasonsMultiSelect';
import { useIsEditable } from './hooks/useIsEditable';

const DropReasonsEditor = ({
    row,
    column,
    activeUserId,
    value: initialValue,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    activeUserId: string;
    value: DropReason[];
    updateLead: UpdateLeadCallback;
}) => {
    const isEditable = useIsEditable(row, column) && row.original.decision === LeadDecision.DROP;
    const [value, setValue] = useState<{ label: string; value: string }[] | undefined>(
        initialValue?.map(reason => ({ label: reason.title, value: reason._id })),
    );
    const {
        original: { id: leadId },
    } = row;
    const { id: field } = column;

    const updateDropReasons = (dropReasons: { value: string; label: string }[]) => {
        if (!!field) {
            const updatedValue = getUpdatedValue(dropReasons, field);
            updateLead(leadId, field, updatedValue, initialValue);
            setValue(dropReasons);
        }
    };

    useEffect(() => {
        setValue(initialValue?.map(reason => ({ label: reason.title, value: reason._id })));
    }, [initialValue, row.original.id]);

    if (!isEditable) {
        return <DropReasonColumn value={value?.map(v => ({ title: v.label, _id: v.value }))} />;
    }

    return (
        <DropReasonsMultiSelect
            disabled={!isEditable}
            value={value}
            onChange={updateDropReasons}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            border={false}
        />
    );
};

export default DropReasonsEditor;
