import { Icon } from '@darrow-ai/darrow-design';
import styled from 'styled-components';

export const LeadsManagerContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    margin: 2.666rem 0 2.666rem 4.333rem;
    min-height: 0px;
`;

export const FlexContainer = styled.div`
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding-right: 1rem;
    width: 99%;
`;

export const PlusIcon = styled(Icon)`
    height: 3rem;
    width: 3rem;
    path {
        fill: white;
    }
`;

export const LeadsManagerSearchContainer = styled.div<{ isSearchOpen: boolean }>`
    width: ${props => (props.isSearchOpen ? '25rem' : '13rem')};
    transition: all ease-in-out 0.1s;
    margin-left: auto;
`;
