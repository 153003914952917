import { SERVER_URL } from '@/constants';
import { AccessTokenContext } from '@/providers/AccessTokenProvider';
import { FilterContext } from '@/providers/FilterProvider';
import axios from 'axios';
import _ from 'lodash';
import { useContext } from 'react';
import useSWR from 'swr';

import useAssignees from '../useAssignees';
import useLeadsQueryParams from './useLeadsQueryParams';

export const COUNT_ROUTE_PREFIX = '/leads/count';

const fetcher = ([url, token]: string[]) =>
    axios
        .get(`${SERVER_URL}${url}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => res.data);

export default function useCountLeads(): {
    data?: number;
    currentKey: string;
    isLoading: boolean;
    isError: any;
} {
    const { accessToken: token } = useContext(AccessTokenContext);
    const { selectedFilters } = useContext(FilterContext);
    const { isLoading: isAssigneeLoading } = useAssignees();
    const { stringifyQueryParams } = useLeadsQueryParams();

    const shouldSend = !_.isNil(selectedFilters) && !isAssigneeLoading && token;

    const currentKey = `/leads/count?${stringifyQueryParams({
        filter: true,
    })}`;

    const { data, error, isLoading } = useSWR<{ count: number }>(
        shouldSend ? [currentKey, token] : null,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateIfStale: false,
        },
    );

    return {
        data: data?.count,
        currentKey,
        isLoading,
        isError: error,
    };
}
