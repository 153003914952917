import { IconButton, Typography } from '@darrow-ai/darrow-design';

import { NavLink as NavLinkType } from '../../types/Types';
import { StyledNavLink } from './StyledNavLink';

const NavLink = ({ id, name, href, icon, onClick }: NavLinkType) => {
    const handleClick = (e: any) => {
        !!onClick && onClick(e);
    };

    const isTabActive = id === 'D-Manager';

    return (
        <StyledNavLink href={href} onClick={handleClick} active={isTabActive} name={name}>
            <IconButton className="icon-button-regular" name={icon} />

            <Typography
                variant="p"
                style={{ paddingLeft: name ? 20 : 0, marginTop: 0, marginBottom: 0 }}
                className="tab-name"
            >
                {name}
            </Typography>
        </StyledNavLink>
    );
};

export default NavLink;
