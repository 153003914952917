import { useState } from 'react';

import { StyledDatePicker } from './StyledDatePicker';

type RangeDatePickerProps = {
    onRangeSelect: (range: any) => void;
};

const RangeDatePicker = ({ onRangeSelect }: RangeDatePickerProps) => {
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(null);

    const onChange = (dates: [start: Date, end: Date]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (end) {
            onRangeSelect({ startDate: start, endDate: end });
        }
    };

    return (
        <StyledDatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
        />
    );
};
export default RangeDatePicker;
