import { useEffect, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { useOrganizations } from '../../hooks/useOrganizations';
import { EnrichedLead, UpdateLeadCallback } from '../../types/Types';
import CompanyNameDropdown from '../Forms/CompanyNameDropdown';
import { useIsEditable } from './hooks/useIsEditable';

type CompanyOption = { label: string; value: number; __isNew__?: boolean };

const CompanyNameEditor = ({
    row,
    column,
    value: initialValue,
    previewMode,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    value: string;
    previewMode?: boolean;
    updateLead: UpdateLeadCallback;
}) => {
    const organizationId = row.original?.organization?.id;
    const organizationName = row.original?.organization?.name;
    const leadId = row.original.id;
    const { id: field } = column;

    const isEditable = useIsEditable(row, column);

    const [value, setValue] = useState<CompanyOption>({
        label: organizationName,
        value: organizationId,
    });

    const { createOrganization } = useOrganizations();

    const updateCompany = async (selectedCompany: CompanyOption) => {
        if (!!field && selectedCompany.label !== initialValue) {
            setValue(selectedCompany);
            let organizationId: number;
            if (selectedCompany.__isNew__) {
                const { organization } = await createOrganization(
                    selectedCompany.label,
                    row.original.organization.name,
                );
                organizationId = organization.id;
            } else {
                organizationId = selectedCompany.value;
            }

            if (organizationId) {
                updateLead(leadId, 'organizationId', organizationId, initialValue);
            }
        }
    };

    useEffect(() => {
        const organizationId = row.original?.organization?.id;
        const organizationName = row.original?.organization?.name;

        setValue({
            label: organizationName,
            value: organizationId,
        });
    }, [row.original?.organization?.name, row.original?.organization?.id, row]);

    return (
        <CompanyNameDropdown
            disabled={!isEditable || !!previewMode}
            value={value}
            onChange={updateCompany}
            menuPortalTarget={document.body}
            border={false}
        />
    );
};

export default CompanyNameEditor;
