import { Typography } from '@/components/BasicComponents/Typography';
import { FC } from 'react';

import Tiki from '../SVG/Tiki';

const Error: FC = () => {
    return (
        <div>
            <Tiki />
            <Typography variant="h2" style={{ textAlign: 'center' }}>
                Oh No!
            </Typography>
            <Typography variant="span" style={{ textAlign: 'center' }} size="medium">
                We couldn’t complete your request.
            </Typography>
            <Typography variant="span" style={{ textAlign: 'center' }} size="medium">
                Try to refresh, or contact the system admin if the problem reoccur
            </Typography>
        </div>
    );
};

export default Error;
