import debounce from 'debounce-promise';
import React from 'react';

import { useOrganizations } from '../../hooks/useOrganizations';
import {
    AsyncSingleSelect,
    AsyncSingleSelectProps,
} from '../BasicComponents/SingleSelect/AsyncSingleSelect';

export type CompanyOption = { label: string; value: number; __isNew__?: boolean };

const CompanyNameDropdown = React.forwardRef(
    (
        {
            value,
            onChange,
            disabled,
            border,
            ...props
        }: AsyncSingleSelectProps & {
            value?: CompanyOption;
            onChange: (newValue: CompanyOption) => void;
            disabled?: boolean;
            border?: boolean;
        },
        ref,
    ) => {
        const { searchOrganizationsByName } = useOrganizations();

        const getCompaniesByName = debounce(async (phrase: string) => {
            try {
                return (await searchOrganizationsByName(phrase)).map(({ id, name }) => ({
                    label: name,
                    value: id,
                }));
            } catch (err) {
                return [{ label: 'Error fetching organizations', value: null }];
            }
        }, 1000);

        return (
            <AsyncSingleSelect
                ref={ref}
                disabled={disabled}
                value={value ?? null}
                defaultOptions={[]}
                loadOptions={async (phrase: string) => getCompaniesByName(phrase)}
                onChange={(newValue: CompanyOption) => {
                    onChange(newValue);
                }}
                formatCreateLabel={(inputValue: string) => `Create company: ${inputValue}`}
                isValidNewOption={(inputValue, value, options) =>
                    inputValue !== '' &&
                    !options.some(({ label }) => label.toLowerCase() === inputValue.toLowerCase())
                }
                noOptionsMessage={() => 'Type to search...'}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                border={border}
                {...props}
            />
        );
    },
);

export default CompanyNameDropdown;
