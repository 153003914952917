import RippleLoader from '@/components/BasicComponents/Loaders/RippleLoader';
import useDropReasons from '@/hooks/useDropReasons';
import useDuplicateLeads from '@/hooks/useLeads/useDuplicateLeads';
import { EnrichedLead, PreviewLead } from '@/types/Types';
import { Icon } from '@darrow-ai/darrow-design';
import { forwardRef, useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Column } from 'react-table';

import useAssignees from '@hooks/useAssignees';
import useCoas from '@hooks/useCoas';
import useDcps from '@hooks/useDcps';
import useStaticColumns from '@hooks/useStaticColumns';

import { ColumnsContext, ColumnsContextType } from '@providers/ColumnsProvider';

import { DownloadCSVTemplate } from '../DownloadCSVTemplate';
import { ImportLeadsPreview } from './ImportLeadsPreview';
import {
    StyledBrowseFile,
    StyledCSVEmpty,
    StyledDragAndDrop,
    StyledDragAndDropBody,
} from './StyledImportLeads';
import { parsePreviewLeadsFromCSV } from './importLeadsUtils';

type ImportLeadsProps = {
    closeDialog: () => void;
};

export const ImportLeads = forwardRef<HTMLDivElement, ImportLeadsProps>(
    (props, dialogContentRef) => {
        const { closeDialog } = props;
        const { columns } = useContext(ColumnsContext) as ColumnsContextType;

        const [previewLeads, setPreviewLeads] = useState<PreviewLead[]>([]);
        const [duplicateLeadsRowData, setDuplicateLeadsRowData] = useState<
            Record<string, string>[]
        >([]);
        const [previewColumns, setPreviewColumns] = useState<Column<EnrichedLead>[]>([]);
        const [isEmpty, setIsEmpty] = useState(false);

        const { data: coas, isLoading: isCoasLoading } = useCoas();
        const { data: dcps, isLoading: isDcpsLoading } = useDcps();
        const { data: dropReasons, isLoading: isDropReasonsLoading } = useDropReasons();
        const { data: assignees, isLoading: isAssigneesLoading } = useAssignees();
        const { data: staticColumns, isLoading: isStaticColumnsLoading } = useStaticColumns();
        const {
            getLeadsToCheckIfDuplicate,
            searchDuplicateLeads,
            isLoading: isDuplicateLeadsLoading,
        } = useDuplicateLeads();

        const onDrop = useCallback(
            (acceptedFiles: File[]) => {
                const reader = new FileReader();
                const file = acceptedFiles[0];
                reader.onload = async event => {
                    if (typeof event.target?.result === 'string') {
                        const {
                            previewLeads: parsedPreviewLeads,
                            columnsToDisplay,
                            parsedDataWithUid,
                        } = parsePreviewLeadsFromCSV(
                            event.target.result,
                            columns,
                            coas,
                            dcps,
                            dropReasons,
                            assignees,
                            staticColumns,
                        );
                        let previewLeads = parsedPreviewLeads;

                        const [leadsToCheckIfDuplicate, rest] =
                            getLeadsToCheckIfDuplicate(parsedPreviewLeads);
                        if (leadsToCheckIfDuplicate.length > 0) {
                            const { duplicateUids, nonDuplicateLeads } = await searchDuplicateLeads(
                                leadsToCheckIfDuplicate,
                            );
                            previewLeads = [...nonDuplicateLeads, ...rest];
                            setDuplicateLeadsRowData(
                                parsedDataWithUid
                                    .filter(row => duplicateUids.has(row.uid))
                                    .map(row => row.data),
                            );
                        }

                        setIsEmpty(previewLeads.length === 0);
                        setPreviewLeads(previewLeads);
                        setPreviewColumns(
                            columns.filter(column => columnsToDisplay.has(column.Header as string)),
                        );
                    }
                };
                reader.readAsText(file);
            },
            [
                columns,
                coas,
                dcps,
                dropReasons,
                assignees,
                staticColumns,
                getLeadsToCheckIfDuplicate,
                searchDuplicateLeads,
            ],
        );

        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            //TODO: check file type, check what about Windows
            accept: { 'text/csv': [] },
            multiple: false,
            onDrop,
        });

        if (previewLeads?.length > 0) {
            return (
                <ImportLeadsPreview
                    ref={dialogContentRef}
                    previewLeads={previewLeads}
                    columns={previewColumns}
                    duplicateLeadsRowData={duplicateLeadsRowData}
                    closeDialog={closeDialog}
                />
            );
        }

        if (
            isCoasLoading ||
            isDcpsLoading ||
            isDropReasonsLoading ||
            isAssigneesLoading ||
            isStaticColumnsLoading ||
            isDuplicateLeadsLoading
        ) {
            return (
                <div style={{ height: 25, width: 25, display: 'contents' }}>
                    <RippleLoader />
                </div>
            );
        }

        return (
            <>
                <StyledDragAndDrop {...getRootProps()} style={{ margin: 15 }}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <div>Drop CSV File Here...</div>
                    ) : (
                        <StyledDragAndDropBody isError={isEmpty}>
                            <Icon name="Upload" style={{ width: 28, height: 28 }} />
                            <div>Drag & Drop CSV File Here</div>
                            <StyledBrowseFile>or click to browse</StyledBrowseFile>
                        </StyledDragAndDropBody>
                    )}
                </StyledDragAndDrop>
                {isEmpty && <StyledCSVEmpty>Empty CSV File</StyledCSVEmpty>}
                <div style={{ paddingTop: 20 }}>
                    <DownloadCSVTemplate />
                </div>
            </>
        );
    },
);
