import { COA_SECTION_SPACER, CSV_COMMENT_PREFIX, CSV_DELIMITER } from '@/constants';
import {
    Assignee,
    Coa,
    Dcp,
    DropReason,
    EnrichedLead,
    PreviewLead,
    PreviewLeadsValidation,
    StaticColumns,
} from '@/types/Types';
import { BooleanValues, ColumnType, Country, USState } from '@/types/enums';
import {
    findManualDcp,
    findTCPAPhoneNumberCoaSection,
    setValue,
    validateCompanyDomain,
} from '@/utils';
import { groupBy } from 'lodash';
import Papa from 'papaparse';
import { Column } from 'react-table';
import { v4 as uuid } from 'uuid';

interface LeadRowDataWithUid {
    uid: string;
    data: Record<string, string>;
}

export const parsePreviewLeadsFromCSV = (
    csvString: string,
    columns: (Column<EnrichedLead> & {
        isCoaSection?: boolean;
        type?: string;
    })[],
    coas: Partial<Coa>[],
    dcps: Partial<Dcp>[],
    dropReasons: DropReason[],
    assignees: Assignee[],
    staticColumns: StaticColumns,
) => {
    const csv = Papa.parse<Record<string, string>>(csvString, {
        header: true,
        delimiter: CSV_DELIMITER,
        comments: CSV_COMMENT_PREFIX,
        skipEmptyLines: true,
        transformHeader: header => header.trim(),
    });

    const parsedData = csv?.data;
    const parsedDataWithUid: LeadRowDataWithUid[] = parsedData.map(row => ({
        uid: uuid(),
        data: row,
    }));
    const columnsToDisplay = new Set<string>();
    const invalidCompanies = validateUniqueCompaniesWebsite(parsedData, staticColumns);
    const previewLeads = parsedDataWithUid.map(row => {
        const previewLead = createPreviewLeadFromCSV(
            row,
            columns,
            coas,
            dcps,
            dropReasons,
            assignees,
            invalidCompanies,
            staticColumns,
        );

        previewLead.columns?.forEach(col => columnsToDisplay.add(col));
        return previewLead;
    });

    columnsToDisplay.delete(staticColumns.DOMAIN.HEADER);

    return { previewLeads, columnsToDisplay, parsedDataWithUid };
};

const createPreviewLeadFromCSV = (
    row: LeadRowDataWithUid,
    columns: (Column<EnrichedLead> & {
        isCoaSection?: boolean;
        type?: string;
    })[],
    coas: Partial<Coa>[],
    dcps: Partial<Dcp>[],
    dropReasons: DropReason[],
    assignees: Assignee[],
    invalidCompanies: Set<string>,
    staticColumns: StaticColumns,
) => {
    const { ASSIGNEE, DROP_REASONS } = staticColumns;

    const previewLead: PreviewLead = { columns: new Set(), uid: row.uid };

    Object.entries(row.data).forEach(([key, value]) => {
        // convert key according to accessor
        const fieldColumn = columns.find(column => column.Header === key);
        const accessor = fieldColumn?.accessor as string;

        let valueToSet: any = value;
        let keyToSet = accessor;

        if (accessor && value !== '') {
            previewLead.columns.add(key);

            if (fieldColumn?.isCoaSection) {
                const header = fieldColumn?.Header as string;

                const [, id] = accessor.split('.');
                const [, name] = header.split(COA_SECTION_SPACER);

                if (previewLead.dcp?.coa) {
                    const coaSection = {
                        answer: value,
                        id: +id,
                        name,
                    };
                    previewLead.dcp.coa.coaSections
                        ? previewLead.dcp.coa.coaSections.push(coaSection)
                        : (previewLead.dcp.coa.coaSections = [coaSection]);
                }
            } else {
                switch (fieldColumn?.type) {
                    case ColumnType.NUMBER:
                        valueToSet = +value.replace(/,/g, '');
                        if (isNaN(valueToSet)) {
                            valueToSet = undefined;
                        }
                        break;
                    case ColumnType.BOOLEAN:
                        valueToSet =
                            value?.trim().toLowerCase() === BooleanValues.TRUE.toLowerCase();
                        break;

                    case ColumnType.SINGLE_SELECT:
                        if (key === ASSIGNEE.HEADER) {
                            valueToSet = assignees.find(user => user.email === value)?.userId;
                            keyToSet = 'assigneeId';
                        }
                        break;
                    case ColumnType.MULTI_SELECT:
                        if (key === DROP_REASONS.HEADER) {
                            valueToSet =
                                dropReasons.find(reason => reason.title === value) ??
                                ({ title: value } as Partial<DropReason>);
                        }
                        if (valueToSet) {
                            valueToSet = [valueToSet];
                        }
                        break;
                    case ColumnType.DATE:
                        valueToSet = new Date(value);
                        break;
                }
                // FIXME: is the toString necessary?
                setValue(keyToSet.toString(), valueToSet, previewLead);
            }
        }
    });
    const isDcpEmpty = previewLead?.dcp?.name;
    if (!isDcpEmpty) {
        handleEmptyDcp(previewLead, dcps);
    }

    validatePreviewLead(previewLead, coas, dcps, invalidCompanies, staticColumns);

    return previewLead;
};

const handleEmptyDcp = (previewLead: Partial<EnrichedLead>, dcps: Partial<Dcp>[]) => {
    // Use Manual DCP
    const coaName = previewLead.dcp?.coa?.name;
    const manualDcp = findManualDcp(dcps, coaName);
    if (manualDcp) {
        previewLead.dcp = { ...previewLead.dcp, name: manualDcp.name, id: manualDcp.id! };
    }
};

export const validateRequiredFields = (data: PreviewLead[]): PreviewLeadsValidation => {
    const isDcp = data.some(importedLead => importedLead.dcp?.name);
    const isCoa = data.some(importedLead => importedLead.dcp?.coa?.name);
    const isSource = data.some(imported => imported.sourceUrl);
    const isCompanyData = data.some(
        importedLead =>
            importedLead?.organization?.name ||
            importedLead?.organization?.website ||
            findTCPAPhoneNumberCoaSection(importedLead.dcp?.coa),
    );

    return {
        isDcp,
        isCoa,
        isSource,
        isCompanyData,
    };
};

// imported leads on the same organization can't be with different company website
export const validateUniqueCompaniesWebsite = (data: any[], staticColumns: StaticColumns) => {
    const { COMPANY_NAME, COMPANY_DOMAIN } = staticColumns;

    const groupByCompanyName = groupBy(data, row => row[COMPANY_NAME.HEADER]);

    const invalidCompanies = new Set<string>();
    Object.keys(groupByCompanyName).forEach(companyName => {
        const company = groupByCompanyName[companyName];
        let companyWebsite = '';
        company.forEach(row => {
            if (row[COMPANY_DOMAIN.HEADER]) {
                companyWebsite = companyWebsite ? companyWebsite : row[COMPANY_DOMAIN.HEADER];
                if (row[COMPANY_DOMAIN.HEADER] !== companyWebsite) {
                    // company website is not unique so all the imported leads on this company are invalid
                    invalidCompanies.add(companyName);
                }
            }
        });
    });

    return invalidCompanies;
};

const validatePreviewLead = (
    previewLead: PreviewLead,
    coas: Partial<Coa>[],
    dcps: Partial<Dcp>[],
    invalidCompanies: Set<string>,
    staticColumns: StaticColumns,
) => {
    const { COA, COMPANY_NAME, COMPANY_DOMAIN, DCP, LEAD_SOURCE_URL } = staticColumns;

    const dcp = dcps.find(dcp => previewLead.dcp?.name === dcp.name);
    const coa = coas.find(coa => previewLead.dcp?.coa?.name === coa.name);

    if (!coa || !previewLead.dcp?.coa || !previewLead.dcp.coa.name) {
        setValue(`invalid.${COA.HEADER}`, true, previewLead);
    } else {
        setValue('dcp.coa.id', coa.id, previewLead);
    }

    if (dcp && coa?.id !== dcp?.coa?.id) {
        setValue(`invalid.${DCP.HEADER}`, true, previewLead);
        setValue(`invalid.${COA.HEADER}`, true, previewLead);
    }

    if (!previewLead.sourceUrl) {
        setValue(`invalid.${LEAD_SOURCE_URL.HEADER}`, true, previewLead);
    }

    if (
        !previewLead?.organization?.name &&
        !previewLead?.organization?.website &&
        !findTCPAPhoneNumberCoaSection(previewLead.dcp?.coa)
    ) {
        setValue(`invalid.${COMPANY_DOMAIN.HEADER}`, true, previewLead);
        setValue(`invalid.${COMPANY_NAME.HEADER}`, true, previewLead);
        setValue(`invalid.TCPA - Phone Number`, true, previewLead);
    }

    if (previewLead?.organization?.name && invalidCompanies.has(previewLead.organization.name)) {
        setValue(`invalid.${COMPANY_NAME.HEADER}`, true, previewLead);
    }

    if (
        previewLead?.organization?.website &&
        !validateCompanyDomain(previewLead.organization.website)
    ) {
        setValue(`invalid.${COMPANY_DOMAIN.HEADER}`, true, previewLead);
    }
};
