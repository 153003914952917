import { Checkbox as dsCheckbox } from '@darrow-ai/darrow-design';
import styled from 'styled-components';

import { ColumnType } from '../../../types/enums';

export interface ItemData {
    value: string;
    label: string;
    type?: ColumnType;
    selected?: boolean;
}

export interface ListboxItemProps {
    index: number;
    item: ItemData;
    onClick: (item: ItemData) => void;
}

const StyledItem = styled.div`
    line-height: 1.25rem;
    color: #282a34;
    padding: 0.6rem 1.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &:hover {
        background: #f7f7ff;
        border-radius: 5px;
        outline: none;
    }
`;

const ItemTextValue = styled.div`
    display: inline-block;
    width: fit-content;
    min-width: 10rem;
    padding: 0 0.6rem;
    margin-left: 0.6rem;
    font-size: 14px;
`;

const Checkbox = styled(dsCheckbox)`
    & + label::before {
        height: 0.8333rem;
        width: 0.8333rem;
    }
`;

export const ListboxItem = ({ onClick, item, index }: ListboxItemProps) => {
    return (
        <StyledItem key={`${item.value}_${index}`} onClick={() => onClick(item)}>
            <div
                style={{
                    height: '14px',
                    alignSelf: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                }}
            >
                <Checkbox checked={item.selected} readOnly />
            </div>
            <ItemTextValue>{item.label?.toString() || 'Empty'}</ItemTextValue>
        </StyledItem>
    );
};
