import { SingleSelect } from '../BasicComponents/SingleSelect/SingleSelect';

type DropDownEditorProps = {
    value: any;
    options: any[];
    disabled?: boolean;
    previewMode?: boolean;
    isClearable?: boolean;
    updateValue: (newValue: any) => void;
};

const DropDownEditor = ({
    value,
    options,
    disabled,
    previewMode,
    isClearable,
    updateValue,
}: DropDownEditorProps) => (
    <SingleSelect
        disabled={disabled || previewMode}
        value={value ?? null}
        options={options}
        onChange={(e: any) => updateValue(e)}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        isClearable={isClearable}
        border={false}
        menuPlacement="auto"
    />
);

export default DropDownEditor;
