import { THEME } from '@/theme';
import styled from 'styled-components';

export const ChipContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    min-height: 26px;
    min-width: 137px;
    max-width: 300px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
    background: ${THEME.COLORS.GRAY.LIGHT};
    border-radius: 20px;
    margin: 10px 10px 0 0;
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid transparent;
    color: ${THEME.COLORS.PURPLE.DEFAULT};

    &:hover {
        border: 1px solid;
    }

    svg path {
        fill: ${THEME.COLORS.PURPLE.DEFAULT};
    }

    svg circle {
        stroke: ${THEME.COLORS.PURPLE.DEFAULT};
    }
`;

export const ChipContent = styled.div`
    display: inline-block;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
`;
