import { getRowNumberColumns } from '@/columns/columns';
import { SEARCH_BY_ACCESSORS, SEARCH_TERM_KEY } from '@/constants';
import useLeads from '@/hooks/useLeads/useLeads';
import { ColumnsContext, ColumnsContextType } from '@/providers/ColumnsProvider';
import { FilterContext } from '@/providers/FilterProvider';
import { PageContext } from '@/providers/PageProvider';
import { ProfileOptions, TableProfileContext } from '@/providers/TableProfileProvider';
import { EnrichedLead } from '@/types/Types';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Column } from 'react-table';

import RippleLoader from '../BasicComponents/Loaders/RippleLoader';
import { Typography } from '../BasicComponents/Typography';
import { LeadsManagerContainer } from '../LeadsManager/StyledLeadManager';
import { LeadsTable } from '../LeadsTable';
import { Header } from './Header';

export const SearchManager = () => {
    const [searchParams] = useSearchParams();
    const urlState = searchParams.get(SEARCH_TERM_KEY) ?? '';
    const { leads, isLeadsLoading, totalCount, isTotalCountLoading } = useLeads();

    const { setTotalDataCount } = useContext(PageContext);
    const { tableProfile } = useContext(TableProfileContext);
    const { selectedColumns } = useContext(ColumnsContext) as ColumnsContextType;

    const { user } = useAuth0();

    const columns = useMemo(
        () => [...getRowNumberColumns(), ...selectedColumns],
        [selectedColumns],
    );

    const { selectedFilters, saveFilterValue } = useContext(FilterContext);

    const setFilterByUrlState = useCallback(() => {
        const found = (selectedFilters ?? []).find(
            selectedFilter => selectedFilter.value === urlState,
        );

        if (!found) {
            saveFilterValue(
                SEARCH_BY_ACCESSORS.map(accessor => ({
                    accessor,
                    displayName: '',
                    value: urlState,
                })),
                true,
            );
        }
    }, [urlState, selectedFilters, saveFilterValue]);

    useEffect(() => setFilterByUrlState(), [setFilterByUrlState]);

    useEffect(() => {
        !isTotalCountLoading && setTotalDataCount(totalCount!);
    }, [isTotalCountLoading, setTotalDataCount, totalCount]);

    if (tableProfile !== ProfileOptions.SEARCH) {
        return null;
    }

    if (isLeadsLoading) {
        return <RippleLoader />;
    }

    return (
        <LeadsManagerContainer>
            <Header />
            <div style={{ marginTop: '1rem' }}>
                <Typography>
                    Showing {totalCount} Results For <b>"{urlState}"</b>
                    In All Leads
                </Typography>
            </div>
            <LeadsTable
                data={leads || []}
                columns={columns as Column<Partial<EnrichedLead>>[]}
                activeUserId={user?.sub!}
                totalLeadsCount={totalCount || 0}
                isLoading={isLeadsLoading}
            />
        </LeadsManagerContainer>
    );
};
