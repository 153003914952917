import {
    Typography as DSTypography,
    TypographyProps as DSTypographyProps,
} from '@darrow-ai/darrow-design';
import styled, { css } from 'styled-components';

type TypographySize = 'normal' | 'medium' | 'large' | 'xl';

type TypographyProps = DSTypographyProps & {
    size?: TypographySize;
};

const StyledTypography = styled(DSTypography)<TypographyProps>`
    ${({ size }) => handleSize(size)};
`;

const handleSize = (size?: TypographySize) => {
    switch (size) {
        case 'medium':
            return css`
                font-size: 1.2rem;
            `;
        case 'large':
            return css`
                font-size: 1.5rem;
            `;
        case 'xl':
            return css`
                font-size: 1.66667rem;
            `;
        case 'normal':
            return css`
                font-size: 1rem;
            `;
        default:
            return css``;
    }
};

export const Typography = ({ size, variant, ...props }: TypographyProps) => {
    return (
        <StyledTypography
            size={(!variant && !size) || (variant === 'span' && !size) ? 'normal' : size}
            variant={variant}
            {...props}
        />
    );
};
