import { CSV_COMMENT_PREFIX, CSV_DELIMITER } from '@/constants';
import { downloadFile } from '@/utils';
import { Button } from '@darrow-ai/darrow-design';
import { useContext, useEffect, useRef } from 'react';

import useStaticColumns from '@hooks/useStaticColumns';

import { ColumnsContext, ColumnsContextType } from '@providers/ColumnsProvider';

const FILE_NAME = 'import-leads-template.csv';

export const DownloadCSVTemplate = () => {
    const { columns } = useContext(ColumnsContext) as ColumnsContextType;
    const bodyRef = useRef<string>();

    const { data: staticColumns } = useStaticColumns();

    useEffect(() => {
        const csvColumns = columns.filter(column => column.Header !== staticColumns.DOMAIN?.HEADER);
        const headerRow = csvColumns.map(column => `"${column.Header}"`).join(CSV_DELIMITER);
        const explanationRow = csvColumns
            .map(column => `"${column.explanation ?? ''}"`)
            .join(CSV_DELIMITER);
        const exampleValueRow = csvColumns
            .map(column => `"${column.exampleValue ?? ''}"`)
            .join(CSV_DELIMITER);
        bodyRef.current = [
            headerRow,
            ...[explanationRow, exampleValueRow].map(row => `${CSV_COMMENT_PREFIX} ${row}`),
        ].join('\n');
    }, [columns, staticColumns.DOMAIN?.HEADER]);

    const downloadCSV = () => {
        const csvContent = bodyRef.current || '';
        downloadFile(csvContent, FILE_NAME);
    };
    return (
        <Button
            style={{
                color: '#6E80F6',
                border: '1px solid #6E80F6',
                borderRadius: 5,
                margin: '15px auto 5px auto',
            }}
            onClick={() => downloadCSV()}
        >
            Download CSV Template
        </Button>
    );
};
