import useCoas from '@/hooks/useCoas';
import { useAuth0 } from '@auth0/auth0-react';
import { Popover } from '@darrow-ai/darrow-design';
import axios from 'axios';
import { useContext, useState } from 'react';
import { mutate } from 'swr';

import TooltipIconButton from '@components/BasicComponents/TooltipIconButton';

import { SERVER_URL } from '../../../constants';
import { AccessTokenContext } from '../../../providers/AccessTokenProvider';
import { AddColumnContent } from './AddColumnContent';
import './AddColumnPopover.css';

export const AddColumnPopover = () => {
    const [open, setOpen] = useState(false);

    const { accessToken } = useContext(AccessTokenContext);

    const { user } = useAuth0();

    const { data: coas, isLoading: isCoasLoading, isError: isCoasError } = useCoas();

    const addColumnRequest = async (name: string, coaId?: String) => {
        setOpen(false);
        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const body = {
            name,
            userEmail: user?.email,
        };

        await axios.post(`${SERVER_URL}/coas/${coaId}/coa-section`, body, config);

        await mutate(['/coas', accessToken]);
    };

    if (isCoasLoading) {
        return (
            <div
                style={{
                    width: '2rem',
                    height: '2rem',
                }}
            />
        );
    }

    if (isCoasError) {
        console.error(isCoasError);
        return null;
    }

    return (
        <Popover.Root open={open} onOpenChange={setOpen}>
            <Popover.Trigger
                asChild
                style={{
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderRadius: 1,
                    display: 'flex',
                }}
            >
                <TooltipIconButton
                    className="add-column-popover-trigger-icon"
                    iconName="Plus"
                    content="Add Column"
                    style={{
                        width: '2rem',
                        height: '2rem',
                    }}
                />
            </Popover.Trigger>
            <Popover.Portal>
                <AddColumnContent open={open} coas={coas} onSubmitCallback={addColumnRequest} />
            </Popover.Portal>
        </Popover.Root>
    );
};
