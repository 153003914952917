import { useEffect, useMemo, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { EnrichedLead } from '../../types/Types';
import { getUpdatedValue } from '../../utils';
import DropDownEditor from './DropDownEditor';
import { FlexEditorContainer } from './Editor.styles';
import { useIsEditable } from './hooks/useIsEditable';

const CoaSectionDropdownEditor = ({
    data,
    row,
    column,
    value: initialValue,
    previewMode,
    disabled,
    updateLead,
}: {
    data: string[];
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    value: string;
    previewMode?: boolean;
    disabled?: boolean;
    updateLead: (id: number, field: string, newValue: any, oldValue: any) => any;
}) => {
    let options: { label: string; value: string }[] = useMemo(
        () => (data ? data.map(d => ({ label: d, value: d })) : []),
        [data],
    );

    const isEditable = useIsEditable(row, column);
    const [value, setValue] = useState(options.find(option => option.value === initialValue));
    const {
        original: { id: leadId },
    } = row;
    const { id: field } = column;

    const updateField = (newValue?: { label: string; value: string }) => {
        if (!!field && newValue?.value !== initialValue) {
            const updatedValue = getUpdatedValue(newValue?.value, field, row.original);
            updateLead(leadId, field, updatedValue, initialValue);
        }
        setValue(newValue);
    };

    useEffect(() => {
        setValue(options.find(option => option.value === initialValue));
    }, [initialValue, row.original.id, options]);

    return (
        <FlexEditorContainer>
            <div style={{ width: '90%' }}>
                <DropDownEditor
                    disabled={!isEditable || disabled}
                    value={value}
                    options={options}
                    previewMode={previewMode}
                    isClearable
                    updateValue={newValue => {
                        updateField(newValue);
                    }}
                />
            </div>
        </FlexEditorContainer>
    );
};

export default CoaSectionDropdownEditor;
