import { SERVER_URL } from '@/constants';
import { AccessTokenContext } from '@/providers/AccessTokenProvider';
import { FilterContext } from '@/providers/FilterProvider';
import { PageContext } from '@/providers/PageProvider';
import { SortContext } from '@/providers/SortProvider';
import { EnrichedLead } from '@/types/Types';
import { getKeysByRoute } from '@/utils';
import axios from 'axios';
import _ from 'lodash';
import { useContext } from 'react';
import useSWR, { preload } from 'swr';

import useAssignees from '../useAssignees';
import useLeadsQueryParams from './useLeadsQueryParams';

const fetcher = ([url, token]: string[]) =>
    axios
        .get(`${SERVER_URL}${url}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => res.data);

export default function useFetchLeads(): {
    data?: EnrichedLead[];
    currentKey: string;
    isLoading: boolean;
    isError: any;
} {
    const { accessToken: token } = useContext(AccessTokenContext);
    const { pageIndex, canNextPage, canPreviousPage } = useContext(PageContext);
    const { selectedFilters } = useContext(FilterContext);
    const { sortedBy } = useContext(SortContext);
    const { isLoading: isAssigneeLoading } = useAssignees();
    const { stringifyQueryParams } = useLeadsQueryParams();

    const shouldSend =
        !_.isNil(sortedBy?.accessor) &&
        !_.isNil(selectedFilters) &&
        !isAssigneeLoading &&
        token &&
        pageIndex !== undefined;

    const currentKey = `/leads?${stringifyQueryParams({
        filter: true,
        sort: true,
        pageIndex,
    })}`;

    const { data, error, isLoading } = useSWR<EnrichedLead[]>(
        shouldSend ? [currentKey, token] : null,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateIfStale: false,
        },
    );

    if (shouldSend && typeof pageIndex === 'number') {
        if (canNextPage()) {
            preload(
                [
                    `/leads?${stringifyQueryParams({
                        filter: true,
                        sort: true,
                        pageIndex: pageIndex + 1,
                    })}`,
                    token,
                ],
                fetcher,
            );
        }

        if (canPreviousPage()) {
            preload(
                [
                    `/leads?${stringifyQueryParams({
                        filter: true,
                        sort: true,
                        pageIndex: pageIndex - 1,
                    })}`,
                    token,
                ],
                fetcher,
            );
        }
    }

    return {
        data,
        currentKey,
        isLoading,
        isError: error,
    };
}

export const allLeadsPagesMatcher = (keys: string[]): boolean => !!getKeysByRoute(keys, '/leads');
