import { IconButton, Popover } from '@darrow-ai/darrow-design';
import copy from 'copy-to-clipboard';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import isURL from 'validator/lib/isURL';

const StyledPopoverContent = styled(Popover.Content)`
    background-color: #282a34;
    width: auto;
    height: auto;
    padding: 10px;
    z-index: 999;
`;

const StyledArrow = styled(Popover.Arrow)`
    fill: #282a34;
    visibility: visible;
`;

const StyledCopyButton = styled(IconButton)`
    height: 16px;
    width: 16px;
    &:hover svg path {
        fill: #a0adff;
    }
`;

const StyledLink = styled.a`
    margin-right: 2rem;
    text-decoration: none;
    color: #ffffff;
    :hover {
        color: #a0adff;
    }
`;

export const UrlPopover = ({
    url,
    open: externalOpen,
    onOpenChange: externalOnOpenChange,
    children,
    disabled,
}: {
    url?: string;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    disabled?: boolean;
    children: ReactNode;
}) => {
    const [internalOpen, setInternalOpen] = useState<boolean>(false);

    const isControlled = externalOpen !== undefined && externalOnOpenChange;

    const isOpen = isControlled ? externalOpen : internalOpen;
    const onOpenChange = isControlled ? externalOnOpenChange : setInternalOpen;

    if (!url || disabled || !isURL(encodeURI(url))) {
        return <div>{children}</div>;
    }

    const onCopyClick = () => {
        copy(url);
        onOpenChange(false);
    };

    return (
        <Popover.Root open={isOpen} onOpenChange={onOpenChange}>
            <Popover.Trigger asChild>
                <div>{children}</div>
            </Popover.Trigger>
            <Popover.Portal>
                <StyledPopoverContent
                    side={'top'}
                    align={'start'}
                    alignOffset={100}
                    arrowPadding={15}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '1em',
                        }}
                    >
                        <StyledLink
                            href={url.startsWith('http') ? url : `http://${url}`}
                            target="_blank"
                            rel={'noopener noreferrer'}
                        >
                            Open link in new tab
                        </StyledLink>
                        <StyledCopyButton name="Copy" onClick={onCopyClick}></StyledCopyButton>
                    </div>
                    <StyledArrow width={6} height={3} />
                </StyledPopoverContent>
            </Popover.Portal>
        </Popover.Root>
    );
};
