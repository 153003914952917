import { Icon, Typography } from '@darrow-ai/darrow-design';
import { useContext } from 'react';

import { FilterContext } from '../../providers/FilterProvider';

const FilterCounter = () => {
    const { selectedFilters } = useContext(FilterContext);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 20, height: 20 }}>
                <Icon name="Filter" />
            </div>

            <Typography weight="bold" style={{ color: 'black' }}>
                Filter
            </Typography>

            <span
                style={{
                    backgroundColor: '#282A34',
                    color: 'white',
                    borderRadius: '50%',
                    width: 15,
                    height: 15,
                    fontSize: 10,
                    fontWeight: 700,
                    padding: 2,
                    margin: '0 5px',
                    textAlign: 'center',
                    lineHeight: 1.5,
                }}
            >
                {selectedFilters.length || 0}
            </span>
        </div>
    );
};

export default FilterCounter;
