import { THEME } from '@/theme';
import styled, { css } from 'styled-components';

interface StyledNavLinkProps {
    readonly active?: boolean;
    readonly name?: string;
}

const activeStyle = css`
    background: linear-gradient(55.78deg, #ffffff 12.23%, ${THEME.COLORS.PURPLE.LIGHT} 93.84%);
    .icon-button-regular svg path,
    .nav-link .icon-button-regular:not([name='Logout']) svg path {
        fill: ${THEME.COLORS.GRAY.LIGHT};
    }

    .icon-button-regular svg path {
        fill: ${THEME.COLORS.PURPLE.DEFAULT} !important;
    }

    p.tab-name {
        color: #373b68;
    }

    color: #373b68;
`;

const inActiveStyle = css`
    &:hover {
        background-color: #d6d7df;
        color: #373b68;

        .icon-button-regular svg path {
            fill: #373b68;
        }

        .icon-button-regular[name='Logout'] svg path {
            stroke: #373b68;
            fill: #d6d7df;
        }

        p.tab-name {
            color: white;
        }
    }
`;

export const StyledNavLink = styled.a<StyledNavLinkProps>`
    display: flex;
    align-items: center;
    width: ${props => (props.name ? '85%' : '55%')};
    padding-right: 0.5rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: ${props => (props.name ? '1.25rem' : '1.5rem')};
    font-size: 0.875rem;
    line-height: 1.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    text-decoration-line: none;
    color: ${THEME.COLORS.PURPLE.DEFAULT};

    ${({ active }) => (active ? activeStyle : inActiveStyle)};

    .icon-button-regular {
        width: 1.5rem;
        height: 1.75rem;
    }

    .icon-button-regular[name='Logout'] svg path {
        stroke: ${THEME.COLORS.GRAY.LIGHT};
    }
`;
