import { InvestigationPanelContext } from '@/providers/InvestigationPanelProvider';
import { EnrichedLead } from '@/types/Types';
import { Drawer } from '@darrow-ai/comp-lib';
import { Suspense, lazy, useContext, useState } from 'react';

import PanelTrigger from './PanelTrigger';

type InvestigationPanelProps = {
    lead: EnrichedLead;
};

const InvestigationPanelContent = lazy(() => import('./InvestigationPanelContent'));

const InvestigationPanel = ({ lead }: InvestigationPanelProps) => {
    const { setSelectedLeadId } = useContext(InvestigationPanelContext);

    const [open, setOpen] = useState(false);

    return (
        <Drawer.Root direction="right" open={open} onOpenChange={setOpen}>
            <Drawer.Trigger asChild onClick={() => setSelectedLeadId(lead.id)}>
                <PanelTrigger />
            </Drawer.Trigger>
            {open && (
                <Suspense>
                    <InvestigationPanelContent />
                </Suspense>
            )}
        </Drawer.Root>
    );
};
export default InvestigationPanel;
