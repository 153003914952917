import { useEffect, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { D_MANAGER_SOURCE } from '../../constants';
import { EnrichedLead, ReferencedValue, UpdateLeadCallback } from '../../types/Types';
import { Arbitrator } from '../../types/enums';
import { getUpdatedValue } from '../../utils';
import { SourceTooltip } from '../SourceTooltip';
import DropDownEditor from './DropDownEditor';
import { FlexEditorContainer } from './Editor.styles';
import { useIsEditable } from './hooks/useIsEditable';

const options: { label: string; value: string }[] = [];

for (const [key, value] of Object.entries(Arbitrator)) {
    options.push({
        label: value,
        value: key,
    });
}

const ArbitratorEditor = ({
    row,
    column,
    value: initialValue,
    previewMode,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    value: string | ReferencedValue<Arbitrator>;
    previewMode?: boolean;
    updateLead: UpdateLeadCallback;
}) => {
    const isEditable = useIsEditable(row, column);
    const [value, setValue] = useState(
        options.find(option =>
            typeof initialValue === 'string'
                ? option.value === initialValue
                : option.value === initialValue?.value,
        ),
    );
    const {
        original: { id: leadId },
    } = row;
    const { id: field } = column;

    const updateArbitrator = (newArbitrator?: { label: Arbitrator; value: string }) => {
        if (!!field && newArbitrator?.value !== initialValue) {
            const updatedValue = getUpdatedValue(
                { value: newArbitrator?.value },
                field,
                row.original,
            );
            updateLead(leadId, field, updatedValue, initialValue);
        }
        setValue(newArbitrator);
    };

    useEffect(() => {
        setValue(
            options.find(option =>
                typeof initialValue === 'string'
                    ? option.value === initialValue
                    : option.value === initialValue?.value,
            ),
        );
    }, [initialValue, row.original.id]);

    return (
        <FlexEditorContainer>
            <div style={{ width: '90%' }}>
                <DropDownEditor
                    disabled={!isEditable}
                    value={value}
                    options={options}
                    previewMode={previewMode}
                    isClearable
                    updateValue={newValue => {
                        updateArbitrator(newValue);
                    }}
                />
            </div>
            {initialValue &&
                typeof initialValue !== 'string' &&
                initialValue.source !== D_MANAGER_SOURCE && <SourceTooltip value={initialValue} />}
        </FlexEditorContainer>
    );
};

export default ArbitratorEditor;
