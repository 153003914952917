import { SelfServiceEnrichmentOption } from '../../../types/Types';
import { StyledButton } from '../../BasicComponents/Buttons/Button.styles';
import { SingleSelect } from '../../BasicComponents/SingleSelect/SingleSelect';

type BodyProps = {
    selectedOption?: SelfServiceEnrichmentOption;
    data: SelfServiceEnrichmentOption[];
    onClick: () => Promise<void>;
    onChange: (newOption: { label: string; value: SelfServiceEnrichmentOption }) => void;
};

export const Body = ({ selectedOption, data, onChange, onClick }: BodyProps) => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        Enrichment Name
        <SingleSelect
            value={{ label: selectedOption?.name, value: selectedOption }}
            options={data?.map(option => ({ label: option.name, value: option })) || []}
            onChange={onChange}
        />
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <StyledButton
                style={{ position: 'relative', marginTop: 10, width: '20%' }}
                isDisabled={!selectedOption}
                onClick={onClick}
            >
                Submit
            </StyledButton>
        </div>
    </div>
);
