import { THEME } from '@/theme';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0;
    margin-top: 0.5rem;
    width: 99%;
`;

export const StyledContainer = styled.div<{ selectedRowIds?: any }>`
    background-color: ${THEME.COLORS.GRAY.LIGHT};
    border-radius: ${props =>
            props.selectedRowIds && !!Object.keys(props.selectedRowIds).length ? '0px' : '10px'}
        0px 0px 10px;
    padding-top: 0.625rem;
    padding-bottom: 1rem;
    /* padding-left: 0.2rem; */
    flex: 1 1 auto;
    min-height: 0;
`;

export const StyledTable = styled.table`
    border-spacing: 0;
    display: block;
    white-space: nowrap;
`;
