import RippleLoader from '@/components/BasicComponents/Loaders/RippleLoader';
import StyledScrollbarsContainer from '@/components/BasicComponents/StyledScrollbarsContainer';
import { ChangeEvent, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';

import { ItemData, ListboxItem } from './ListboxItem';
import { ListboxSearch } from './ListboxSearch';

export interface ListboxProps {
    items: Array<ItemData>;
    onItemSelected?: (item: ItemData) => void;
    searchable: boolean;
    isLoading?: boolean;
}

const ListboxContainer = styled(StyledScrollbarsContainer)`
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    height: inherit;
    max-height: inherit;
    min-height: inherit;
`;

export const Listbox = ({ items, onItemSelected, searchable, isLoading = false }: ListboxProps) => {
    const [searchPhrase, setSearchPhrase] = useState('');
    const [displayedItems, setDisplayedItems] = useState<ItemData[]>(items);
    const [listHeight, setListHeight] = useState(window.innerHeight);

    const handleItemClick = (item: ItemData) => {
        onItemSelected && onItemSelected(item);
    };

    const filterBySearchPhrase = (items: ItemData[], searchPhrase: string) =>
        searchPhrase === ''
            ? items
            : items.filter(item => item.label?.toLowerCase()?.includes(searchPhrase.toLowerCase()));

    const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchPhrase(e.target.value);
    };

    useEffect(() => {
        setDisplayedItems(filterBySearchPhrase(items, searchPhrase));
    }, [searchPhrase, items]);

    return (
        <ListboxContainer>
            {searchable ? (
                <ListboxSearch
                    searchTerm={searchPhrase}
                    onSearchChange={onSearchInputChange}
                    searchPlaceholder="Search"
                />
            ) : (
                <></>
            )}
            {isLoading ? (
                <RippleLoader withText={false} />
            ) : (
                <Virtuoso
                    style={{ height: listHeight }}
                    data={displayedItems}
                    totalListHeightChanged={newHeight => setListHeight(newHeight)}
                    itemContent={(index, item) => (
                        <ListboxItem
                            key={index}
                            onClick={handleItemClick}
                            item={item}
                            index={index}
                        />
                    )}
                />
            )}
        </ListboxContainer>
    );
};
