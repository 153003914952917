import { Checkbox } from '@/components/BasicComponents/Checkbox';
import { MutableRefObject, forwardRef, useEffect, useRef } from 'react';

type IndeterminateCheckboxProps = {
    indeterminate: boolean;
};

const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
    ({ indeterminate, ...props }, ref) => {
        const defaultRef = useRef<HTMLInputElement>();
        const resolvedRef = (ref as MutableRefObject<HTMLInputElement>) || defaultRef;

        useEffect(() => {
            if (resolvedRef && resolvedRef.current) {
                resolvedRef.current.indeterminate = indeterminate;
            }
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <Checkbox ref={resolvedRef} {...props} />
            </>
        );
    },
);

export default IndeterminateCheckbox;
