import { IconButton } from '@darrow-ai/darrow-design';
import { Dispatch, SetStateAction } from 'react';

import { NavLink as NavLinkType } from '../../types/Types';
import NavLink from '../NavLink/NavLink';
import {
    SideNavContainer,
    SideNavItemList,
    SideNavLogo,
    SideNavRoot,
    SideNavSeparator,
    StyledNavBarExpand,
    StyledSideNav,
} from './StyledSideNav';

type SideNavProps = {
    navLinks: NavLinkType[];
    secondaryNavLinks: NavLinkType[];
    isSideNavFull: boolean;
    setIsSideNavFull: Dispatch<SetStateAction<boolean>>;
};

const SideNav = ({
    navLinks,
    secondaryNavLinks,
    isSideNavFull,
    setIsSideNavFull,
}: SideNavProps) => {
    return (
        <SideNavRoot isSideNavFull={isSideNavFull}>
            <SideNavContainer>
                <SideNavLogo isSideNavFull={isSideNavFull}>
                    <img
                        src="img/darrow-internal.svg"
                        alt="darrow's internal logo"
                        style={{
                            visibility: isSideNavFull ? 'visible' : 'hidden',
                        }}
                        width={isSideNavFull ? 180 : 0}
                        height={50}
                    />

                    <img
                        src="img/small-logo.svg"
                        alt="darrow's d letter logo"
                        style={{
                            visibility: isSideNavFull ? 'hidden' : 'visible',
                        }}
                        width={30}
                        height={30}
                    />
                </SideNavLogo>
                <SideNavSeparator />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: isSideNavFull ? 'flex-start' : 'center',
                        alignItems: 'center',
                    }}
                >
                    <StyledNavBarExpand
                        width="2rem"
                        height="2rem"
                        name={isSideNavFull ? 'DoubleChevronLeft' : 'DoubleChevronRight'}
                        style={{
                            ...(isSideNavFull
                                ? { alignSelf: 'flex-start', marginLeft: '1rem' }
                                : { alignSelf: 'center' }),
                            marginTop: '1rem',
                            marginBottom: '1rem',
                            height: '1.75rem',
                        }}
                        onClick={() => setIsSideNavFull(prev => !prev)}
                    />
                    {isSideNavFull ? <span style={{ color: 'white' }}>Close Menu</span> : <></>}
                </div>
                <SideNavSeparator />
                <StyledSideNav aria-label="Sidebar">
                    <SideNavItemList>
                        {navLinks.map(item => (
                            <NavLink
                                id={item.name}
                                key={item.name}
                                name={isSideNavFull ? item.name : ''}
                                href={item.href}
                                icon={item.icon}
                                onClick={item.onClick}
                            />
                        ))}
                    </SideNavItemList>

                    <div style={{ marginTop: 'auto', paddingTop: 24 }}>
                        <SideNavItemList>
                            {secondaryNavLinks.map(item => (
                                <NavLink
                                    id={item.name}
                                    key={item.name}
                                    name={isSideNavFull ? item.name : ''}
                                    href={item.href}
                                    icon={item.icon}
                                    outsideLink={item.outsideLink}
                                    onClick={item.onClick}
                                />
                            ))}
                        </SideNavItemList>
                    </div>
                </StyledSideNav>
            </SideNavContainer>
        </SideNavRoot>
    );
};

export default SideNav;
