import { FallbackProps } from 'react-error-boundary';

import { StyledButton } from '../BasicComponents/Buttons/Button.styles';
import { Typography } from '../BasicComponents/Typography';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
            }}
        >
            <Typography variant="h2">ERROR</Typography>
            <Typography variant="h3">
                Tell Insight Creation Squad the error is: {error.message}
            </Typography>
            <Typography variant="h3">{error.stack}</Typography>
            <StyledButton onClick={resetErrorBoundary}>
                Click the button to return to the D-Manager
            </StyledButton>
        </div>
    );
};

export default ErrorFallback;
