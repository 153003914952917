import { THEME } from '@/theme';
import { DialogTrigger } from '@radix-ui/react-dialog';
import styled from 'styled-components';

export const StyledDivider = styled.div`
    border: 1px solid rgba(21, 51, 108, 0.1);
    margin-top: 10px;
    margin-bottom: 20px;
`;

export const StyledAddLeadsDialogTrigger = styled(DialogTrigger)`
    background-color: ${THEME.COLORS.ORANGE.DEFAULT};
    border-radius: 50%;
    color: white;
    white-space: nowrap;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    gap: 0.25rem;
`;
