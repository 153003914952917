import { THEME } from '@/theme';
import { Drawer, IconButton } from '@darrow-ai/comp-lib';
import styled from 'styled-components';

export const DrawerContent = styled(Drawer.Content)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 66%;
    color: #170d44;
    font-size: 14px;
    height: 100vh;
    box-sizing: border-box;

    & > button {
        top: 0.75rem;
        right: 0.75rem;
        width: 1.5rem;
        height: 1.5rem;
    }
`;

export const PanelTriggerIconButton = styled(IconButton)`
    &[data-state='open'] {
        color: white;
        background: ${THEME.COLORS.PURPLE.DEFAULT};
    }
`;
