import { SEARCH_TERM_KEY } from '@/constants';
import { ReactNode, createContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export enum ProfileOptions {
    MAIN = 'main',
    SEARCH = 'search',
}

interface TableProfileProviderProps {
    children: ReactNode;
}

interface TableProfileContextType {
    tableProfile: ProfileOptions;
    setTableProfile: React.Dispatch<React.SetStateAction<ProfileOptions>>;
}

const defaultState: TableProfileContextType = {
    tableProfile: ProfileOptions.MAIN,
    setTableProfile: () => {},
};

export const TableProfileContext = createContext(defaultState);
TableProfileContext.displayName = 'TableProfileContext';

export const TableProfileProvider = ({ children }: TableProfileProviderProps) => {
    const [searchParams] = useSearchParams();
    const [tableProfile, setTableProfile] = useState(
        searchParams.get(SEARCH_TERM_KEY) ? ProfileOptions.SEARCH : ProfileOptions.MAIN,
    );

    return (
        <TableProfileContext.Provider
            value={{
                tableProfile,
                setTableProfile,
            }}
        >
            {children}
        </TableProfileContext.Provider>
    );
};
