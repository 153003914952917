import { useSavedValue } from '@/hooks/useSavedValue';
import useStaticColumns from '@/hooks/useStaticColumns';
import { DateRange, NumberRange } from '@/types/Types';
import { ReactNode, createContext, useContext, useState } from 'react';

import {
    ASSIGNEE_FILTER_KEY,
    COA_FILTER_KEY,
    DCP_FILTER_KEY,
    DROP_REASONS_FILTER_KEY,
} from '../constants';
import { ColumnType } from '../types/enums';
import { ColumnsContext, ColumnsContextType } from './ColumnsProvider';

interface FilterContextProps {
    children: ReactNode;
}

type SaveFilterInput = SelectedFilter | SelectedFilter[];

export type SelectedFilter = {
    accessor: string;
    displayName: string;
    value: string | number | DateRange | NumberRange;
};

export type FilterCategory = { name: string; displayName: string; columnType?: ColumnType };

export type FilterOptions = { [key: string]: Set<any> };

interface FilterContextType {
    selectedFilters: SelectedFilter[];
    filterCategories: FilterCategory[];
    setFilterCategories: (selectedColumns: FilterCategory[]) => void;
    saveFilterValue: (saveFilterInput: SaveFilterInput, clear?: boolean) => void;
    removeFilterValue: (item: SelectedFilter) => void;
    clearFilterValues: () => void;
}

const defaultState: FilterContextType = {
    selectedFilters: [],
    filterCategories: [],
    setFilterCategories: () => {},
    saveFilterValue: () => {},
    removeFilterValue: () => {},
    clearFilterValues: () => {},
};

export const FilterContext = createContext(defaultState);
FilterContext.displayName = 'FilterContext';

export const FilterProvider = ({ children }: FilterContextProps) => {
    const [selectedFilters, setSavedFilters] = useSavedValue<SelectedFilter[]>(
        'selected-filters',
        [],
    );
    const { setDefaultCoASelectedColumns } = useContext(ColumnsContext) as ColumnsContextType;

    const { data: columns } = useStaticColumns();

    const [filterCategories, setFilterCategories] = useState<FilterCategory[]>([
        { name: DCP_FILTER_KEY, displayName: 'DCP' },
        { name: COA_FILTER_KEY, displayName: 'COA' },
        { name: DROP_REASONS_FILTER_KEY, displayName: 'Drop Reason' },
        { name: ASSIGNEE_FILTER_KEY, displayName: 'Assignee' },
    ]);

    const saveFilterValue = (saveFilterInput: SaveFilterInput, clear = false) => {
        const newFiltersToSave = Array.isArray(saveFilterInput)
            ? saveFilterInput
            : [saveFilterInput];

        const updatedFilters: SelectedFilter[] = clear
            ? [...newFiltersToSave]
            : [...selectedFilters, ...newFiltersToSave];

        newFiltersToSave.forEach(({ accessor }) => {
            if (accessor === columns?.COA?.ACCESSOR) {
                setDefaultCoASelectedColumns(updatedFilters);
            }
        });

        setSavedFilters(updatedFilters);
    };

    const removeFilterValue = ({ accessor, value }: SelectedFilter) => {
        const index = selectedFilters.findIndex(
            sortedValue => sortedValue.accessor === accessor && sortedValue.value === value,
        );

        if (index > -1) {
            selectedFilters.splice(index, 1);
        }

        if (accessor === columns?.COA?.ACCESSOR) setDefaultCoASelectedColumns(selectedFilters);
        setSavedFilters(selectedFilters);
    };

    const clearFilterValues = () => {
        setSavedFilters([]);
    };

    const value = {
        selectedFilters,
        filterCategories,
        saveFilterValue,
        setFilterCategories,
        removeFilterValue,
        clearFilterValues,
    };

    return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};
