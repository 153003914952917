import { THEME } from '@/theme';
import { IconButton } from '@darrow-ai/darrow-design';
import styled, { css } from 'styled-components';

interface SideNavProps {
    readonly isSideNavFull: boolean;
}

export const SideNavRoot = styled.div<SideNavProps>`
    position: fixed;
    top: 0px;
    height: 100vh;
    display: flex;
    width: ${props => (props.isSideNavFull ? '16rem' : '5rem')};
    z-index: 10;
    overflow: hidden;

    ${props =>
        props.isSideNavFull
            ? css`
                  width: 16rem;

                  @media (min-width: 1536px) {
                      width: 20rem;
                  }
              `
            : css`
                  width: 5rem;
              `}

    @media (prefers-reduced-motion: reduce) {
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }
`;

export const SideNavContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: ${THEME.COLORS.PURPLE.DEFAULT};
    padding-top: 1.25rem;
    padding-bottom: 1rem;
    overflow-y: auto;
    border-radius: 0 1rem 1rem 0;
`;

export const SideNavLogo = styled.div<SideNavProps>`
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    justify-content: center;
`;

export const StyledSideNav = styled.nav`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 1.25rem;
`;

export const SideNavItemList = styled.div`
    padding-right: 0.5rem;
    & > :not([hidden]) ~ :not([hidden]) {
        margin-top: calc(0.25rem * calc(1 - 0));
        margin-bottom: calc(0.25rem * 0);
    }
`;

export const SideNavSeparator = styled.div`
    background-color: ${THEME.COLORS.PURPLE.LIGHT};
    height: 1px;
`;

export const StyledNavBarExpand = styled(IconButton)`
    svg > path {
        fill: white !important;
    }
`;
