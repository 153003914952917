import { Icon, Tooltip } from '@darrow-ai/darrow-design';

import { ReferencedValue } from '../types/Types';
import { getTodayFormat } from '../utils';

export const SourceTooltip = ({ value }: { value: ReferencedValue<any> }) => (
    <Tooltip>
        <Tooltip.Trigger>
            <Icon name="Source" style={{ height: 20, width: 20 }} />
        </Tooltip.Trigger>
        <Tooltip.Content>
            <div style={{ display: 'flex', fontSize: 'larger' }}>
                <div style={{ marginRight: 10 }}>{value?.source}</div>
                {value?.dateCreated && getTodayFormat(value.dateCreated)}
            </div>
            <Tooltip.Arrow />
        </Tooltip.Content>
    </Tooltip>
);
