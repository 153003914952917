import styled from 'styled-components';

import { AddColumnPopover } from './AddColumn/AddColumnPopover';
import SelectColumnPopover from './SelectColumn/SelectCoulmnPopover';

const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & > * {
        margin-right: 0.25rem;
    }

    & :last-child {
        margin-right: 0;
    }
`;

const HeaderActions = () => (
    <StyledHeader>
        <AddColumnPopover />
        <SelectColumnPopover />
    </StyledHeader>
);

export default HeaderActions;
