import useFilterOptions from '@/hooks/useFilterOptions';
import { useContext } from 'react';

import { FilterContext } from '../../providers/FilterProvider';
import { ColumnType } from '../../types/enums';
import FilterDateRange from './FilterDateRange';
import { FilterDropDown } from './FilterDropDown';
import FilterNumberRange from './FilterNumberRange';

interface FilterContentProps {
    name: string;
    displayName: string;
    columnType?: ColumnType;
}

export const FilterContent = ({ name, displayName, columnType }: FilterContentProps) => {
    const shouldGetOptions = columnType !== ColumnType.DATE && columnType !== ColumnType.NUMBER;
    const { data: filterOptions, isLoading } = useFilterOptions(shouldGetOptions ? name : null);
    const { selectedFilters } = useContext(FilterContext);

    switch (columnType) {
        case ColumnType.NUMBER:
            return <FilterNumberRange name={name} displayName={displayName} />;

        case ColumnType.DATE:
            return <FilterDateRange name={name} displayName={displayName} />;

        default:
            return (
                <FilterDropDown
                    name={name}
                    displayName={displayName}
                    checkedValues={
                        new Set(
                            selectedFilters.reduce((accumulator, currentValue) => {
                                if (currentValue.accessor === name) {
                                    accumulator.push(currentValue.value as string | number);
                                }
                                return accumulator;
                            }, [] as (string | number)[]),
                        )
                    }
                    values={filterOptions || []}
                    isLoading={isLoading}
                />
            );
    }
};
