export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const INVALID_DOMAIN_ERROR_MESSAGE = 'Please use the following format domain.com';
export const COMPANY_DOMAIN_REGEX_EXPRESSION =
    '^(?!www.)(((?!-))(xn--)?[a-z0-9-_]{0,61}[a-z0-9]{1,1}.)*(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}).[a-z]{2,}$';

export const D_MANAGER_SOURCE = 'd-manager';
export const CSV_DELIMITER = ',';
export const CSV_COMMENT_PREFIX = '#';
export const COA_SECTION_PREFIX = 'coaSection.';
export const COA_SECTION_DELIMITER = '-';
export const COA_SECTION_SPACER = ` ${COA_SECTION_DELIMITER} `;
export const MANUAL_DCP = 'Manual DCP';

export const OVERALL = 'Overall';
export const CELL_WIDTH = 12;
export const DEFAULT_PAGE_SIZE = 20;
export const ORGANIZATION_TO_DELETE = 'ORGANIZATION_TO_DELETE';

export const DCP_FILTER_KEY = 'dcp.name';
export const COA_FILTER_KEY = 'dcp.coa.name';
export const DROP_REASONS_FILTER_KEY = 'dropReasons';
export const ASSIGNEE_FILTER_KEY = 'assignee';

/// Column Headers
export const STATIC_COLUMNS_CONSTANTS = {
    NUMBERS: { ACCESSOR: 'numbers' },
    SPACER: { ACCESSOR: 'spacer' },
};

// Add Leads Dialog sizes
export const DIALOG_DEFAULT_WIDTH = '35rem';
export const PREVIEW_MODE_WIDTH_LARGE = '75%';
export const PREVIEW_MODE_HEIGHT_LARGE = '55rem';
export const PREVIEW_MODE_HEIGHT_SMALL = '12rem';

export enum FeatureFlags {
    SERVER_SIDE_PAGINATION = 'server-side-pagination',
}

export const DRAWER_Z_INDEX = 999;

export const SEARCH_QUERY_PREFIX = 'search';
export const SEARCH_BY_ACCESSORS = ['organization.name', 'organization.website'];
export const SEARCH_TERM_KEY = 'searchTerm';
