import { IconButton, Popover } from '@darrow-ai/darrow-design';
import { forwardRef, useContext, useEffect, useState } from 'react';

import { ColumnsContext, ColumnsContextType } from '../../../providers/ColumnsProvider';
import { Listbox } from '../../BasicComponents/Listbox/Listbox';
import { ItemData } from '../../BasicComponents/Listbox/ListboxItem';
import PopoverContent from '../../BasicComponents/Popover/PopoverContent';
import PopoverHeader from '../../BasicComponents/Popover/PopoverHeader';

type AddColumnContentProps = {
    open: boolean;
};

const SelectColumnContent = forwardRef<HTMLDivElement, AddColumnContentProps>(({ open }, ref) => {
    const { selectedColumns, setSelectedColumnsCallback, columns } = useContext(
        ColumnsContext,
    ) as ColumnsContextType;

    const [items, setItems] = useState<ItemData[]>([]);

    useEffect(() => {
        setItems(
            columns.map<ItemData>(column => ({
                value: `${column.Header}`,
                label: `${column.Header}`,
                selected: !!selectedColumns.find(
                    selectedColumn => selectedColumn.accessor === column.accessor,
                ),
            })),
        );
    }, [columns, selectedColumns]);

    return (
        <PopoverContent ref={ref} alignOffset={-5} sideOffset={10}>
            <Popover.Close asChild>
                <IconButton name="ClosePopup" style={{ width: 16, height: 16 }} />
            </Popover.Close>
            <PopoverHeader text="Columns" iconName="Columns" />
            <div style={{ maxHeight: '25rem', paddingTop: '1rem' }}>
                <Listbox
                    searchable={true}
                    items={items}
                    onItemSelected={setSelectedColumnsCallback}
                />
            </div>
        </PopoverContent>
    );
});
export default SelectColumnContent;
