import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import queryString from 'query-string';
import { useCallback, useContext } from 'react';

import { SEARCH_BY_ACCESSORS, SERVER_URL } from '../constants';
import { AccessTokenContext } from '../providers/AccessTokenProvider';
import { EnrichedLead, EnrichedOrganization, Service } from '../types/Types';
import useLeads from './useLeads/useLeads';
import { FilterOperators } from './useLeads/useLeadsQueryParams';

export const useOrganizations = () => {
    const { accessToken } = useContext(AccessTokenContext);
    const { user } = useAuth0();
    const { leads } = useLeads();

    const createOrganization = useCallback(
        async (orgName: string, previousOrgName?: string) => {
            let lead: EnrichedLead | undefined;

            if (previousOrgName) {
                lead = leads?.find(lead => lead.organization.name === previousOrgName);
            }

            let organizationInput = previousOrgName
                ? { name: orgName }
                : { ...lead?.organization, name: orgName };

            const { data } = await axios.post<{
                organization: EnrichedOrganization;
                service: Service;
            }>(
                `${SERVER_URL}/organizations`,
                { organization: organizationInput, service: lead?.service, userEmail: user?.email },
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
            );
            return data;
        },
        [accessToken, user?.email, leads],
    );

    const searchOrganizationsByName = useCallback(
        async (searchPhrase: string) => {
            const { data } = await axios.get<{ name: string; id: number }[]>(
                `${SERVER_URL}/organizations`,
                {
                    params: {
                        name: searchPhrase,
                    },
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
            );

            return data;
        },
        [accessToken],
    );

    const getOrganizationById = useCallback(
        async (id: number) => {
            const { data } = await axios.get<EnrichedOrganization>(
                `${SERVER_URL}/organizations/${id}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
            );

            return data;
        },
        [accessToken],
    );

    const findOrganizationsWithLead = useCallback(
        async (searchTerm: string) => {
            const filtersQuery = SEARCH_BY_ACCESSORS.reduce(
                (accumulator, accessor) => {
                    const key = `${accessor}[contains]`;
                    accumulator[key] = searchTerm;
                    return accumulator;
                },
                { operator: FilterOperators.OR } as { [key: string]: string },
            );

            const { data } = await axios.get<EnrichedOrganization[]>(
                `${SERVER_URL}/organizations/?${queryString.stringify({
                    ...filtersQuery,
                    onlyWithLeads: true,
                })}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
            );
            return data;
        },
        [accessToken],
    );

    return {
        createOrganization,
        searchOrganizationsByName,
        getOrganizationById,
        findOrganizationsWithLead,
    };
};
