import { THEME } from '@/theme';
import * as React from 'react';

const PaperAirplane = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={147} height={147} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={73.5} cy={73.5} r={73.5} fill={THEME.COLORS.GRAY.LIGHT} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m28.949 51.988 93.895-36.205c4.288-1.947 8.427 1.03 6.79 7.596L113.643 98.73c-1.116 5.353-4.352 6.635-8.834 4.161L80.451 84.896 68.743 96.28l-.11.107c-1.31 1.275-2.393 2.33-4.764 2.33-2.474 0-2.681-.732-3.178-2.495a23.401 23.401 0 0 0-.533-1.705l-8.286-27.27-24.091-7.52c-5.203-1.592-5.242-5.167 1.168-7.739Zm34.629 38.18c.136.45.758.488.95.06l7.367-16.476c.064-.144.155-.275.268-.386l42.057-41.408c.427-.421-.1-1.105-.616-.8l-56.72 33.65a1.27 1.27 0 0 0-.568 1.462l7.262 23.897Z"
            fill="#6E80F6"
        />
    </svg>
);

export default PaperAirplane;
