import mixpanelPlugin from '@analytics/mixpanel';
import { useAuth0 } from '@auth0/auth0-react';
import Analytics from 'analytics';
import { ReactNode, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { AnalyticsProvider } from 'use-analytics';

type UserAnalyticsProps = {
    children: ReactNode;
};

const MIXPANEL_TOKEN = process.env.REACT_APP_ANALYTICS_TOKEN;
const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;

const UserAnalytics = ({ children }: UserAnalyticsProps) => {
    const analytics = Analytics({
        app: 'D-Manager',
        plugins: [
            mixpanelPlugin({
                token: MIXPANEL_TOKEN,
                enabled: false,
            }),
        ],
    });

    const { user } = useAuth0();

    const IS_PROD_ENV = process.env.NODE_ENV === 'production';

    useEffect(() => {
        const startHotjar = () => {
            if (HOTJAR_ID) {
                const hotjarId: number = parseInt(HOTJAR_ID);

                hotjar.initialize(hotjarId, 6);

                hotjar.identify(user?.sub!, {
                    name: user?.nickname,
                    email: user?.email,
                });
            }
        };

        const startMixpanel = async () => {
            await analytics.plugins.enable('mixpanel');

            analytics.identify(user?.sub ?? '', {
                name: user?.nickname,
                email: user?.email,
            });
        };

        if (user && user.sub && IS_PROD_ENV) {
            startHotjar();
            startMixpanel();
        }
    }, [user, analytics, IS_PROD_ENV]);

    return <AnalyticsProvider instance={analytics}>{children}</AnalyticsProvider>;
};

export default UserAnalytics;
