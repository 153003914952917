import styled from 'styled-components';

export const StyledFormContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & > div:first-child {
        margin-top: 0;
    }
    & > div:last-child {
        margin-bottom: 0;
    }
`;

export const LabeledInput = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0.75rem 0;

    label {
        margin-bottom: 0.25rem;
        color: #373b68;
    }
`;

export const StyledButton = styled.button`
    margin-top: 0.75rem;
    background-color: #6e80f6;
    border-radius: 10px;
    color: white;
    white-space: nowrap;
    padding: 0.5rem;
    width: 12rem;
    align-self: center;
`;

export const ErrorMessage = styled.div`
    color: rgba(218, 76, 76, 0.8);
`;
