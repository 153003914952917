import { Toast } from '@darrow-ai/comp-lib';
import { ReactNode } from 'react';

import AccessTokenProvider from './AccessTokenProvider';
import UserAnalytics from './AnalyticsProvider';
import { ColumnsProvider } from './ColumnsProvider';
import { FilterProvider } from './FilterProvider';
import { PageProvider } from './PageProvider';
import { SortProvider } from './SortProvider';
import { TableProfileProvider } from './TableProfileProvider';

export const ProviderWrapper = ({ children }: { children: ReactNode }) => (
    <UserAnalytics>
        <Toast.Provider>
            <AccessTokenProvider>
                <TableProfileProvider>
                    <ColumnsProvider>
                        <FilterProvider>
                            <PageProvider>
                                <SortProvider>{children}</SortProvider>
                            </PageProvider>
                        </FilterProvider>
                    </ColumnsProvider>
                </TableProfileProvider>
            </AccessTokenProvider>

            <Toast.Viewport />
        </Toast.Provider>
    </UserAnalytics>
);
