import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled, { css } from 'styled-components';

interface StyledDatePickerProps {
    disabled?: boolean;
    previewMode?: boolean;
}

export const StyledDatePicker = styled(ReactDatePicker)<StyledDatePickerProps>`
    border: none;
    background-color: transparent;
    color: rgb(84, 84, 84);
    font-size: inherit;

    ${props =>
        props.previewMode &&
        css`
            color: black;
            opacity: 1;
        `}
`;
