import { Typography } from '@/components/BasicComponents/Typography';

import PaperAirplane from '../../SVG/PaperAirplane';

export const ConfirmationContent = () => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            gap: 15,
        }}
    >
        <PaperAirplane />
        <div>
            <div>
                <Typography variant="span" size="xl" weight="bold">
                    Sent Successfully
                </Typography>
            </div>
            <Typography size="medium">
                Your enrichment request was sent to the Data Enablement squad. Check email for
                updates.
            </Typography>
        </div>
    </div>
);
