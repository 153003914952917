import useStaticColumns from '@/hooks/useStaticColumns';
import { Dispatch, SetStateAction } from 'react';

import TooltipIconButton from '@components/BasicComponents/TooltipIconButton';

import { DropReason } from '../../../types/Types';
import { LeadDecision } from '../../../types/enums';
import { useUpdateLead } from '../hooks/useUpdateLead';

type BulkDropButtonProps = {
    filtersQuery: string;
    setDropReasonDialog: Dispatch<
        SetStateAction<{
            open: boolean;
            updateDropReasons: (dropReasons: Partial<DropReason>[]) => any;
        }>
    >;
};

const BulkDropButton = ({ filtersQuery, setDropReasonDialog }: BulkDropButtonProps) => {
    const { data: columns } = useStaticColumns();
    const { bulkUpdateLeads } = useUpdateLead();

    const updateDropReasons = (dropReasons: Partial<DropReason>[]) => {
        bulkUpdateLeads(filtersQuery, columns.DROP_REASONS.ACCESSOR, dropReasons);
    };

    const handleDropLeads = () => {
        bulkUpdateLeads(filtersQuery, columns.DECISION.ACCESSOR, LeadDecision.DROP);

        setDropReasonDialog({ open: true, updateDropReasons });
    };

    return (
        <TooltipIconButton
            content="Drop"
            iconName="CornerRightDown"
            onClick={handleDropLeads}
            style={{ width: 16, height: 16 }}
        />
    );
};
export default BulkDropButton;
