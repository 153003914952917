import { Button } from '@darrow-ai/darrow-design';
import styled from 'styled-components';

import { StyledButton } from './StyledImportLeads';

export const StyledDiscardButton = styled(Button)`
    border-radius: 10px;
    padding: 10px;
    border: none;
    background: linear-gradient(
        240.52deg,
        rgba(218, 76, 76, 0.8) 17.46%,
        rgba(200, 21, 74, 0.8) 106.53%
    );
    border-radius: 5px;
`;

type DiscardContentProps = {
    discard: () => void;
    cancel: () => void;
};

export const DiscardContent = ({ discard, cancel }: DiscardContentProps) => (
    <div>
        <p style={{ textAlign: 'center' }}>
            Are you sure you want to discard updating these leads?
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <StyledButton onClick={() => cancel()}>Return To Preview</StyledButton>
            <StyledDiscardButton onClick={() => discard()}>Discard</StyledDiscardButton>
        </div>
    </div>
);
