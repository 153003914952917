import { AccessTokenContext } from '@/providers/AccessTokenProvider';
import axios from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';

import { SERVER_URL } from '../constants';
import { Dcp, RecursivePartial } from '../types/Types';

const fetcher = ([url, token]: string[]) =>
    axios
        .get<RecursivePartial<Dcp>[]>(`${SERVER_URL}${url}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => res.data);

export default function useDcps(): {
    data: RecursivePartial<Dcp>[];
    isLoading: boolean;
    isError: any;
} {
    const { accessToken: token } = useContext(AccessTokenContext);

    const { data, error, isLoading } = useSWR(token ? ['/dcps', token] : null, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateIfStale: false,
    });
    return {
        data: data!,
        isLoading,
        isError: error || data == null,
    };
}
