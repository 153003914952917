const PanelTriggerIcon = (props: any) => (
    <svg width={15} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.121 13H13a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v4.257a4.5 4.5 0 0 0-1 .501V2a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H9.121l-1-1ZM4.75 5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM7 4.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5Zm0 3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5Zm4.5 3.5H7.972a4.5 4.5 0 0 0 0-1H11.5a.5.5 0 0 1 0 1ZM.384 8.905A3.5 3.5 0 0 0 5.599 13.3l2.543 2.558a.5.5 0 0 0 .707-.708L6.3 12.6A3.5 3.5 0 1 0 .384 8.905Zm5.194.206A2.5 2.5 0 1 1 1.42 11.89 2.5 2.5 0 0 1 5.578 9.11Z"
            fill="currentColor"
        />
    </svg>
);

export default PanelTriggerIcon;
