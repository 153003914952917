import RippleLoader from '@/components/BasicComponents/Loaders/RippleLoader';
import { COA_SECTION_PREFIX, SEARCH_BY_ACCESSORS } from '@/constants';
import { useSavedValue } from '@/hooks/useSavedValue';
import { EnrichedLead, LeadsTableColumn } from '@/types/Types';
import { includesAny } from '@/utils';
import _ from 'lodash';
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Column } from 'react-table';

import { ItemData } from '@components/BasicComponents/Listbox/ListboxItem';

import useCoaSectionsMetaData from '@hooks/useCoaSectionsMetaData';
import useCoas from '@hooks/useCoas';
import useStaticColumns from '@hooks/useStaticColumns';

import { getAllColumns } from '../columns/columns';
import { SelectedFilter } from './FilterProvider';
import { ProfileOptions, TableProfileContext } from './TableProfileProvider';

type SavedColumnItem = {
    Header: string;
    accessor: string;
    sticky?: boolean | null;
};

interface ColumnsContextProps {
    children: ReactNode;
}

export interface ColumnsContextType {
    selectedColumns: Column<EnrichedLead>[];
    setSelectedColumnsCallback: (newColumns: any) => void;
    columns: LeadsTableColumn[];
    setColumns: React.Dispatch<React.SetStateAction<Column<EnrichedLead>[]>>;
    reorderColumns: (firstColumnHeader?: string, secondColumnHeader?: string) => void;
    setColumnSticky: (column: any, sticky: boolean) => void;
    setDefaultCoASelectedColumns: (selectedFilters: SelectedFilter[]) => void;
}

export const ColumnsContext = createContext({});
ColumnsContext.displayName = 'ColumnsContext';

export const ColumnsProvider = ({ children }: ColumnsContextProps) => {
    const { tableProfile } = useContext(TableProfileContext);
    const { data: coas } = useCoas();
    const { data: coaSectionsMetaData } = useCoaSectionsMetaData();
    const { data: staticColumns, isLoading: isStaticColumnsLoading } = useStaticColumns();

    const [columns, setColumns] = useState<LeadsTableColumn[]>([]);

    const [savedColumns, setSavedColumns] = useSavedValue<SavedColumnItem[]>(
        'selected-columns',
        [],
    );

    useEffect(() => {
        const allColumns = getAllColumns(coas, coaSectionsMetaData, staticColumns);
        setColumns(allColumns);
    }, [coas, coaSectionsMetaData, staticColumns]);

    const setSelectedColumnsCallback = useCallback(
        (item: ItemData) => {
            const header = item.value;

            let newColumns: SavedColumnItem[];
            const column = savedColumns?.find(savedColumn => savedColumn?.Header === header);

            if (column && savedColumns) {
                newColumns = _.without(savedColumns, column);
            } else {
                const chosenColumn = columns.find(col => header === col.Header)!;
                newColumns = [
                    ...savedColumns,
                    {
                        accessor: chosenColumn.accessor as string,
                        Header: chosenColumn.Header as string,
                        sticky: chosenColumn.sticky,
                    },
                ];
            }
            setSavedColumns(newColumns);
        },
        [columns, savedColumns, setSavedColumns],
    );

    const setColumnSticky = (column: Column<EnrichedLead>, sticky?: boolean) => {
        const chosenColumn = savedColumns?.find(
            savedColumns => savedColumns.Header === column.Header,
        );
        if (chosenColumn) {
            chosenColumn.sticky = sticky;
        }

        savedColumns.sort(stickyComparator);
        setSavedColumns([...savedColumns]);
    };

    const reorderColumns = (firstColumnHeader?: string, secondColumnHeader?: string) => {
        if (!firstColumnHeader || !secondColumnHeader) return;

        const firstColumnIndex = savedColumns.findIndex(col => col.Header === firstColumnHeader);
        const secondColumnIndex = savedColumns.findIndex(col => col.Header === secondColumnHeader);

        if (!firstColumnIndex || !secondColumnIndex) return;

        if (firstColumnIndex === -1 || secondColumnIndex === -1) {
            return;
        }

        const updatedColumns = [...savedColumns];
        const firstItem = updatedColumns?.splice(firstColumnIndex, 1);
        if (firstItem?.length) {
            updatedColumns?.splice(secondColumnIndex, 0, firstItem[0]);
        }

        setSavedColumns(updatedColumns.sort(stickyComparator));
    };

    const setDefaultCoASelectedColumns = (selectedFilters: SelectedFilter[]) => {
        const coas = selectedFilters.reduce((accu: string[], filter) => {
            if (filter.accessor === staticColumns.COA.ACCESSOR) {
                accu.push(filter.value as string);
            }
            return accu;
        }, []);

        const coaSectionColumns = columns.reduce((accumulator, column) => {
            if (column.isCoaSection) {
                const isInclude = includesAny(
                    column.coas.map(({ name }) => name),
                    coas,
                );
                return isInclude
                    ? [
                          ...accumulator,
                          {
                              accessor: column.accessor as string,
                              Header: column.Header as string,
                              sticky: column.sticky,
                          },
                      ]
                    : accumulator;
            }

            return accumulator;
        }, [] as SavedColumnItem[]);

        const staticColumnsToSave =
            savedColumns?.filter(
                savedColumn => !savedColumn.accessor.startsWith(COA_SECTION_PREFIX),
            ) || [];

        const updatedColumns = [...staticColumnsToSave, ...coaSectionColumns];
        setSavedColumns(updatedColumns);
    };

    const getSelectedColumnsBySavedValue = useCallback(
        () =>
            savedColumns.reduce((accumulator, savedColumn) => {
                const column = columns.find(({ accessor }) => accessor === savedColumn.accessor);
                if (column) {
                    accumulator.push({
                        ...column,
                        sticky: savedColumn.sticky || undefined,
                    });
                }
                return accumulator;
            }, [] as LeadsTableColumn[]),
        [columns, savedColumns],
    );

    const getDefaultSelectedColumns = useCallback(() => {
        const columnMap = Object.fromEntries(columns.map(column => [column.accessor, column]));

        let columnsToDisplay = Object.values(staticColumns).map(
            staticColumn => columnMap[staticColumn.ACCESSOR],
        );

        if (tableProfile === ProfileOptions.SEARCH) {
            const searchColumns = SEARCH_BY_ACCESSORS.map(accessor => columnMap[accessor]);

            columnsToDisplay = searchColumns
                .map(column => ({ ...column, sticky: true }))
                .concat(
                    columnsToDisplay.filter(
                        column => !SEARCH_BY_ACCESSORS.includes(column.accessor),
                    ),
                );
        }

        setSavedColumns(
            columnsToDisplay.map(({ accessor, Header, sticky = false }) => ({
                accessor,
                Header,
                sticky,
            })),
        );
        return columnsToDisplay;
    }, [columns, staticColumns, tableProfile, setSavedColumns]);

    const getSelectedColumns = useCallback(() => {
        if (!_.isEmpty(savedColumns)) {
            return getSelectedColumnsBySavedValue();
        }

        if (!_.isEmpty(staticColumns) && !_.isEmpty(columns)) {
            return getDefaultSelectedColumns();
        }

        return [];
    }, [
        columns,
        staticColumns,
        savedColumns,
        getSelectedColumnsBySavedValue,
        getDefaultSelectedColumns,
    ]);

    const selectedColumns = useMemo(() => getSelectedColumns(), [getSelectedColumns]);

    const value = {
        selectedColumns,
        setSelectedColumnsCallback,
        columns,
        setColumns,
        reorderColumns,
        setColumnSticky,
        setDefaultCoASelectedColumns,
    };

    if (isStaticColumnsLoading) {
        return <RippleLoader />;
    }

    return <ColumnsContext.Provider value={value}>{children}</ColumnsContext.Provider>;
};
const stickyComparator = (
    colA: { sticky?: boolean | null },
    colB: { sticky?: boolean | null },
): number => {
    if (colA.sticky && colB.sticky) return 0;

    if (colA.sticky) return -1;

    return 1;
};
