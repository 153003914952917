import { FilterContext } from '@/providers/FilterProvider';
import { ProfileOptions, TableProfileContext } from '@/providers/TableProfileProvider';
import { ArrowLeft } from '@styled-icons/fa-solid/ArrowLeft';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '../BasicComponents/Typography';

export const Navigation = () => {
    const navigate = useNavigate();

    const { clearFilterValues } = useContext(FilterContext);
    const { setTableProfile } = useContext(TableProfileContext);

    return (
        <div style={{ display: 'flex' }}>
            <button
                style={{ display: 'flex' }}
                onClick={() => {
                    clearFilterValues();
                    setTableProfile(ProfileOptions.MAIN);
                    navigate('/');
                }}
            >
                <ArrowLeft
                    size={18}
                    style={{ cursor: 'pointer', paddingRight: '1rem', color: 'rgb(55, 59, 104)' }}
                />
                <Typography variant="span">
                    <b>Back To Filters</b>
                </Typography>
            </button>
        </div>
    );
};
