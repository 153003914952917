import { createContext } from 'react';

export interface InvestigationPanelContextType {
    selectedLeadId?: number;
    setSelectedLeadId: (leadId: number) => void;
}

export const InvestigationPanelContext = createContext<InvestigationPanelContextType>({
    selectedLeadId: undefined,
    setSelectedLeadId: () => {},
});
