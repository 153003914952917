import { Popover } from '@darrow-ai/darrow-design';
import styled from 'styled-components';

const PopoverContent = styled(Popover.Content).attrs(({ align }) => ({
    align: align ?? 'start',
}))`
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0px rgba(77, 77, 77, 0.04), -2px 0px 4px 0px rgba(151, 151, 151, 0.09);
`;

export default PopoverContent;
