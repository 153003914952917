import { Icon, Popover } from '@darrow-ai/darrow-design';

import { ColumnType } from '../../types/enums';
import PopoverContent from '../BasicComponents/Popover/PopoverContent';
import { FilterContent } from './FilterContent';

interface FilterItemProps {
    name: string;
    displayName: string;
    columnType?: ColumnType;
    openFilter: string;
    setOpenFilter: (arg: any) => void;
}

export const FilterItem = ({
    name,
    displayName,
    columnType,
    openFilter,
    setOpenFilter,
}: FilterItemProps) => {
    const onOpenChange = (isOpen: boolean) => {
        const newState = isOpen ? name : '';
        setOpenFilter(newState);
    };

    return (
        <Popover.Root key={name} onOpenChange={isOpen => onOpenChange(isOpen)}>
            <Popover.Trigger>
                <div
                    className={
                        openFilter === name
                            ? 'filter-selector filter-selector-open'
                            : 'filter-selector'
                    }
                >
                    {displayName}
                    <div className={openFilter === name ? 'rotated rotated-open' : 'rotated'}>
                        <div style={{ width: 16, height: 16 }}>
                            <Icon name="ChevronRight" />
                        </div>
                    </div>
                </div>
            </Popover.Trigger>
            <Popover.Portal>
                <PopoverContent style={{ width: '25rem' }}>
                    <div style={{ maxHeight: '20rem' }}>
                        <FilterContent
                            name={name}
                            displayName={displayName}
                            columnType={columnType}
                        />
                    </div>
                </PopoverContent>
            </Popover.Portal>
        </Popover.Root>
    );
};
