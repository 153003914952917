import { THEME } from '@/theme';
import * as React from 'react';

const Duplicates = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5123 3.46341H28.3415C29.5538 3.46341 30.5366 4.44619 30.5366 5.65851V22.4877C30.5366 23.4434 29.9258 24.2565 29.0732 24.5579V25.4145C29.0732 25.6435 29.0521 25.8676 29.0119 26.0849C30.7123 25.77 32 24.2792 32 22.4877V5.65851C32 3.63797 30.362 2 28.3415 2H11.5123C9.7247 2 8.23653 3.28209 7.91718 4.97691C6.40409 5.22952 5.20539 6.41077 4.92683 7.91491C5.14417 7.87466 5.36825 7.85362 5.59724 7.85362H5.65851C3.63797 7.85362 2 9.4916 2 11.5121V28.3413C2 30.3618 3.63797 31.9998 5.65851 31.9998H22.4877C24.2836 31.9998 25.7772 30.7058 26.0872 28.9994C27.756 28.6595 29.0117 27.1837 29.0117 25.4145V8.58533C29.0117 6.56478 27.3738 4.92681 25.3532 4.92681H9.44211C9.74346 4.07424 10.5566 3.46341 11.5123 3.46341ZM26.1462 27.462C26.9666 27.144 27.5483 26.3472 27.5483 25.4145V8.58533C27.5483 7.373 26.5655 6.39022 25.3532 6.39022H8.52405C7.56829 6.39022 6.75519 7.00105 6.45385 7.85362H22.4877C24.5082 7.85362 26.1462 9.4916 26.1462 11.5121V27.462ZM22.4877 9.31703H5.65851C4.44619 9.31703 3.46341 10.2998 3.46341 11.5121V28.3413C3.46341 29.5536 4.44619 30.5364 5.65851 30.5364H22.4877C23.7 30.5364 24.6828 29.5536 24.6828 28.3413V11.5121C24.6828 10.2998 23.7 9.31703 22.4877 9.31703Z"
                fill={THEME.COLORS.PURPLE.DEFAULT}
            />
        </svg>
    );
};

export default Duplicates;
