import { SERVER_URL } from '@/constants';
import { AccessTokenContext } from '@/providers/AccessTokenProvider';
import axios from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';

const fetcher = async ([url, token, accessor]: string[]) => {
    const { data } = await axios.get<any[]>(`${SERVER_URL}${url}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            accessor,
        },
    });

    return data;
};

export default function useFilterOptions(accessor: string | null) {
    const { accessToken: token } = useContext(AccessTokenContext);
    const { data, isLoading, error } = useSWR(
        token && accessor ? ['/filters', token, accessor] : null,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateIfStale: false,
        },
    );

    return {
        data,
        isLoading,
        isError: error,
    };
}
