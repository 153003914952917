import { THEME } from '@/theme';
import styled, { css } from 'styled-components';

import { CELL_WIDTH } from '../../constants';

export const StyledTd = styled.td<{ invalid?: boolean }>`
    padding: 0.333rem 0;
    border-bottom: 1px solid #cccccc;
    background-color: inherit;
    min-height: 4rem;

    ${props =>
        props.invalid &&
        css`
            border: 2px solid rgba(218, 76, 76, 0.8);
            border-radius: 10px;
            padding-right: 0.75rem;
            padding-left: 0.75rem;
            min-height: 2.75rem;
        `}
`;

export const StyledTr = styled.tr<any>`
    background-color: ${props => (props.isSelected ? THEME.COLORS.PURPLE.MEDIUM : 'white')};

    td:nth-child(2) {
        border-left: 4px solid transparent;
    }

    td:first-child div {
        opacity: ${props =>
            props.selectedRowIds && !!Object.keys(props.selectedRowIds).length ? '1' : '0'};
    }

    &:hover {
        background-color: ${props =>
            props.isSelected ? THEME.COLORS.PURPLE.MEDIUM : THEME.COLORS.GRAY.LIGHT};
        transition: background 0.1s ease;

        td:first-child div {
            /* Show checkbox on hover */
            opacity: 1;
        }
    }

    ${StyledTd}:first-child div {
        opacity: ${props =>
            (props.selectedRowIds && !!Object.keys(props.selectedRowIds).length) ||
            props.previewMode
                ? '1'
                : '0'};
    }

    ${props =>
        props.editable
            ? css`
                  td {
                      background-color: #fbf9ff !important;
                      border-color: ${THEME.COLORS.PURPLE.DEFAULT} !important;
                  }
              `
            : css`
                  & + tr td.editable {
                      border-top: 1px solid;
                  }
              `}
`;

export const StyledTbody = styled.tbody`
    tr td:first-child {
        width: 1rem;
        background-color: ${THEME.COLORS.GRAY.LIGHT};
        border-bottom: none;
        border-top: none;

        div {
            width: 1rem;
            padding: 0 0.3rem;
            margin: 0 auto;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    tr:first-child td:nth-child(2) {
        border-top-left-radius: 5px;
    }

    tr:last-child td:nth-child(2) {
        border-bottom-left-radius: 5px;
    }

    td:last-child > div {
        border-right: 0px;
    }

    tr:last-child > td {
        border-bottom: 0;
    }
`;

export const StyledCellContent = styled.div`
    border-right: 1px solid #f0f0f2;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
`;

export const FixedCellContent = styled(StyledCellContent)`
    width: ${CELL_WIDTH}rem;

    &:not(& div) {
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const StyledSpacer = styled(StyledCellContent)`
    border-right: 0;
    width: 1rem;
    min-width: 1rem;
`;

export const StyledCheckboxContent = styled(StyledCellContent)``;

export const NumberColumnContent = styled(StyledCellContent)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem;
    min-width: 2rem;
`;
