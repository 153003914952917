import { ASSIGNEE_FILTER_KEY, DEFAULT_PAGE_SIZE } from '@/constants';
import { ColumnsContext, ColumnsContextType } from '@/providers/ColumnsProvider';
import { FilterContext, SelectedFilter } from '@/providers/FilterProvider';
import { SortContext } from '@/providers/SortProvider';
import { ProfileOptions, TableProfileContext } from '@/providers/TableProfileProvider';
import { Assignee, DateRange, EnrichedLead, LeadsTableColumn, NumberRange } from '@/types/Types';
import { ColumnType } from '@/types/enums';
import { findProp } from '@/utils';
import _ from 'lodash';
import queryString from 'query-string';
import { useContext } from 'react';
import { Row } from 'react-table';

import useAssignees from '../useAssignees';

const getFilterQuery = (
    currentValue: SelectedFilter,
    columns: LeadsTableColumn[],
    assignees: Assignee[],
    tableProfile: ProfileOptions,
) => {
    const column = columns.find(col => col.accessor === currentValue.accessor);
    if (currentValue.accessor === ASSIGNEE_FILTER_KEY) {
        const value = assignees?.find(user => user.fullName === currentValue.value);
        return { [`${currentValue.accessor}[eq]`]: [value ? value.userId : null] };
    }
    switch (column?.type) {
        case ColumnType.DATE:
            const dateValue = currentValue.value as DateRange;
            return {
                [`${currentValue.accessor}[range]`]: [
                    JSON.stringify({
                        start: dateValue.startDate,
                        end: dateValue.endDate,
                    }),
                ],
            };
        case ColumnType.NUMBER:
            let numberValue = currentValue.value as NumberRange;
            return {
                [`${currentValue.accessor}[range]`]: [
                    JSON.stringify({
                        start: numberValue.minValue,
                        end: numberValue.maxValue,
                        isExcluded: numberValue.isExcluded,
                    }),
                ],
            };
        default:
            const operator = tableProfile === ProfileOptions.SEARCH ? 'contains' : 'eq';
            return { [`${currentValue.accessor}[${operator}]`]: [currentValue.value] };
    }
};

const getSortQuery = (accessor?: string, isSortedDesc?: boolean) => {
    const sortQuery = { sort_by: '' };
    if (accessor) {
        sortQuery.sort_by = `${accessor}.${isSortedDesc ? 'desc' : 'asc'}`;
    }
    return sortQuery;
};

const getPageQuery = (pageIndex: number, size: number) => ({ page: pageIndex, size: size });

export enum FilterOperators {
    AND = '$and',
    OR = '$or',
}

export default function useLeadsQueryParams() {
    const { tableProfile } = useContext(TableProfileContext);
    const { selectedFilters } = useContext(FilterContext);
    const { sortedBy } = useContext(SortContext);
    const { columns } = useContext(ColumnsContext) as ColumnsContextType;
    const { data: assignees } = useAssignees();

    const defaultFilterQuery = {
        operator: tableProfile === ProfileOptions.SEARCH ? FilterOperators.OR : FilterOperators.AND,
    };

    const filtersQuery = selectedFilters.reduce((filtersParams, filter) => {
        const filterParam = getFilterQuery(filter, columns, assignees, tableProfile);
        return _.mergeWith(filtersParams, filterParam, mergeArray);
    }, defaultFilterQuery);

    const sortQuery = getSortQuery(sortedBy?.accessor, sortedBy?.isSortedDesc);

    const stringifyQueryParams = ({
        sort,
        filter,
        pageIndex,
    }: {
        sort?: boolean;
        filter?: boolean;
        pageIndex?: number;
    }) => {
        const query = { page: {}, filter: {}, sort: {} };
        if (typeof pageIndex === 'number') query.page = getPageQuery(pageIndex, DEFAULT_PAGE_SIZE);
        if (filter) query.filter = filtersQuery || {};
        if (sort) query.sort = sortQuery;
        return queryString.stringify(
            { ...query.page, ...query.filter, ...query.sort },
            { skipEmptyString: true },
        );
    };

    const getCustomFiltersQuery = (selectedRows: Row<EnrichedLead>[], accessor: string) => {
        const column = columns.find(column => column.accessor === accessor);

        const customFiltersQuery = selectedRows.reduce((filtersParams, row) => {
            const filterParam = {
                [`${column?.accessor}[eq]`]: [findProp(row.original, accessor)],
            };
            return _.mergeWith(filtersParams, filterParam, mergeArray);
        }, {});

        return queryString.stringify(customFiltersQuery, {
            skipEmptyString: true,
        });
    };

    return { stringifyQueryParams, getCustomFiltersQuery };
}

const mergeArray = (objValue: any, srcValue: any) => {
    if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
    }
};
