import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, SetStateAction, createContext, useEffect, useState } from 'react';

const defaultState = {
    accessToken: '',
    setAccessToken: (value: SetStateAction<string>) => {},
};

export const AccessTokenContext = createContext(defaultState);

interface AccessTokenProviderProps {
    children: ReactNode | ReactNode[];
}

const AccessTokenProvider = ({ children }: AccessTokenProviderProps) => {
    const [accessToken, setAccessToken] = useState('');

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchToken = async () => {
            const token = await getAccessTokenSilently();

            setAccessToken(token);
        };
        fetchToken();
    }, [getAccessTokenSilently]);

    return (
        <AccessTokenContext.Provider value={{ accessToken, setAccessToken }}>
            {children}
        </AccessTokenContext.Provider>
    );
};

export default AccessTokenProvider;
