import { AccessTokenContext } from '@/providers/AccessTokenProvider';
import { CoaSectionMetaData } from '@/types/Types';
import axios from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';

import { SERVER_URL } from '../constants';

const fetcher = ([url, token]: string[]) =>
    axios
        .get<CoaSectionMetaData[]>(`${SERVER_URL}${url}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => res.data);

export default function useCoaSectionsMetaData(): {
    data: CoaSectionMetaData[];
    isLoading: boolean;
    isError: any;
} {
    const { accessToken: token } = useContext(AccessTokenContext);

    const { data, error, isLoading } = useSWR(
        token ? ['/coas/coaSectionsMetaData', token] : null,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateIfStale: false,
        },
    );
    return {
        data: data!,
        isLoading,
        isError: error,
    };
}
