import RippleLoader from '@/components/BasicComponents/Loaders/RippleLoader';
import { allLeadsPagesMatcher } from '@/hooks/useLeads/useFetchLeads';
import { EnrichedLead } from '@/types/Types';
import { useAuth0 } from '@auth0/auth0-react';
import { SingleSelect } from '@darrow-ai/darrow-design';
import axios from 'axios';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { mutate } from 'swr';
import isURL from 'validator/es/lib/isURL';

import { StyledInput } from '@components/BasicComponents/StyledInput';

import { INVALID_DOMAIN_ERROR_MESSAGE } from '../../../constants';
import useCoas from '../../../hooks/useCoas';
import useDcps from '../../../hooks/useDcps';
import { AccessTokenContext } from '../../../providers/AccessTokenProvider';
import {
    findManualDcp,
    findTCPAPhoneNumberCoaSection,
    validateCompanyDomain,
} from '../../../utils';
import CompanyNameDropdown, { CompanyOption } from '../../Forms/CompanyNameDropdown';
import {
    ErrorMessage,
    LabeledInput,
    StyledButton,
    StyledFormContainer,
} from './AddSingleLead.styles';

export type AddSingleLeadInput = {
    coa: { value: number; label: string };
    dcp: { value: number; label: string };
    leadSourceUrl: string;
    companyName: CompanyOption;
    companyDomain: string;
    companyPhoneNumber: string;
};

type AddSingleLeadProps = {
    onError: () => void;
    close: () => void;
};

const COMPANY_MANDATORY_FIELDS_ERROR_MESSAGE =
    'Please add Company Domain/Company Name/Phone Number';

const COMPANY_DOMAIN = 'companyDomain';
const COMPANY_NAME = 'companyName';
const COMPANY_PHONE_NUMBER = 'companyPhoneNumber';

export const AddSingleLead: FC<AddSingleLeadProps> = ({ onError, close }) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        watch,
        control,
        setValue,
        trigger,
    } = useForm<AddSingleLeadInput>({
        mode: 'onBlur',
    });

    const { accessToken } = useContext(AccessTokenContext);
    const { user } = useAuth0();

    const validateCompanyRequiredFields = (value: string) =>
        getValues(COMPANY_NAME) !== undefined ||
        getValues(COMPANY_DOMAIN) !== undefined ||
        getValues(COMPANY_PHONE_NUMBER) !== undefined
            ? true
            : COMPANY_MANDATORY_FIELDS_ERROR_MESSAGE;

    const onSubmit: SubmitHandler<AddSingleLeadInput> = async data => {
        try {
            setIsLoading(true);
            let coaSections = undefined;
            if (companyPhoneNumberSection) {
                const coaSection = {
                    value: data.companyPhoneNumber,
                    dcpId: +data.dcp.value,
                    id: companyPhoneNumberSection.id,
                };

                coaSections = [coaSection];
            }

            const { status } = await axios.post<Partial<EnrichedLead>>(
                `${process.env.REACT_APP_SERVER_URL}/leads/`,
                {
                    userEmail: user?.email,
                    organizationId:
                        data.companyName && !data.companyName?.__isNew__
                            ? data.companyName.value
                            : undefined,
                    organizationName: data.companyName?.label,
                    organizationUrl: data.companyDomain,
                    leadSourceUrl: data.leadSourceUrl,
                    dcpId: +data.dcp.value,
                    coaSections,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            );
            if (status !== 201) {
                onError();
            } else {
                await mutate(allLeadsPagesMatcher);
                setIsLoading(false);
                close();
            }
        } catch (err) {
            console.log(err);
            onError();
        }
    };

    const { data: coas, isLoading: isCoasLoading } = useCoas();
    const { data: dcps, isLoading: isDcpsLoading } = useDcps();

    const selectedCoa = watch('coa');

    const getSelectedCoa = useCallback(
        () => coas.find(coa => coa.id === selectedCoa?.value),
        [coas, selectedCoa],
    );

    const getManualDCP = useCallback(() => {
        const dcp = findManualDcp(dcps, getSelectedCoa()?.name);

        if (!dcp) {
            return null;
        }

        return {
            label: dcp.name!,
            value: dcp.id!,
        };
    }, [dcps, getSelectedCoa]);

    useEffect(() => {
        if (!isDcpsLoading && !isCoasLoading) {
            const manualDcp = getManualDCP();
            if (manualDcp) {
                setValue('dcp', manualDcp);
            }
        }
    }, [isDcpsLoading, isCoasLoading, setValue, getManualDCP]);

    const companyPhoneNumberSection = findTCPAPhoneNumberCoaSection(getSelectedCoa());

    useEffect(() => {
        if (!companyPhoneNumberSection) {
            // reset value when coa is changed
            setValue(COMPANY_PHONE_NUMBER, '');
        }
    }, [setValue, getSelectedCoa, companyPhoneNumberSection]);

    if (isLoading) {
        return <RippleLoader />;
    }

    return (
        <StyledFormContainer onSubmit={handleSubmit(onSubmit)}>
            <LabeledInput>
                <label>CoA</label>
                <Controller
                    name="coa"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <SingleSelect
                            options={
                                isCoasLoading
                                    ? []
                                    : coas?.map(coa => ({
                                          label: coa.name,
                                          value: coa.id,
                                      }))
                            }
                            {...field}
                        >
                            <SingleSelect.Control />
                            <SingleSelect.MenuList />
                            <SingleSelect.Option />
                            <SingleSelect.DropdownIndicator />
                        </SingleSelect>
                    )}
                />

                {errors.coa && <ErrorMessage>Please add CoA</ErrorMessage>}
            </LabeledInput>
            <LabeledInput>
                <label>DCP</label>
                <Controller
                    name="dcp"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <SingleSelect
                            id="dcp"
                            options={
                                isDcpsLoading
                                    ? []
                                    : dcps
                                          .filter(dcp => dcp.coa?.id === selectedCoa?.value)
                                          .map(dcp => ({
                                              label: dcp?.name,
                                              value: dcp?.id,
                                          }))
                            }
                            {...field}
                        >
                            <SingleSelect.Control />
                            <SingleSelect.MenuList />
                            <SingleSelect.Option />
                            <SingleSelect.DropdownIndicator />
                        </SingleSelect>
                    )}
                />
                {errors.dcp && <ErrorMessage>Please add DCP</ErrorMessage>}
            </LabeledInput>
            <LabeledInput>
                <label htmlFor="leadSourceUrl">Lead source URL</label>
                <StyledInput
                    id="leadSourceUrl"
                    type="text"
                    {...register('leadSourceUrl', {
                        required: 'Please add a Lead source URL',
                        validate: value =>
                            isURL(value) ? true : 'Lead source URL must be a valid URL',
                    })}
                    error={!!errors.leadSourceUrl}
                />
                {errors.leadSourceUrl && (
                    <ErrorMessage>{errors.leadSourceUrl.message}</ErrorMessage>
                )}
            </LabeledInput>
            <LabeledInput>
                <label htmlFor={COMPANY_NAME}>Company Name</label>
                <Controller
                    name={COMPANY_NAME}
                    control={control}
                    rules={{
                        validate: value =>
                            value !== undefined ||
                            !!getValues(COMPANY_DOMAIN) ||
                            !!getValues(COMPANY_PHONE_NUMBER),
                    }}
                    render={({ field }) => (
                        <CompanyNameDropdown
                            {...field}
                            onChange={newValue => {
                                field.onChange(newValue);
                                trigger([COMPANY_NAME, COMPANY_DOMAIN]);
                            }}
                        />
                    )}
                />
                {errors.companyName && <ErrorMessage>Please type a company name</ErrorMessage>}
            </LabeledInput>
            <LabeledInput>
                <label htmlFor={COMPANY_DOMAIN}>Company Domain</label>
                <StyledInput
                    id={COMPANY_DOMAIN}
                    {...register(COMPANY_DOMAIN, {
                        validate: {
                            required: validateCompanyRequiredFields,
                            isValidUrl: (value: string) =>
                                !value || validateCompanyDomain(value)
                                    ? true
                                    : INVALID_DOMAIN_ERROR_MESSAGE,
                        },
                        onBlur: () => {
                            trigger([COMPANY_NAME, COMPANY_PHONE_NUMBER]);
                        },
                    })}
                    error={!!errors.companyDomain}
                />
                {errors.companyDomain && (
                    <ErrorMessage>{errors.companyDomain.message}</ErrorMessage>
                )}
            </LabeledInput>
            {companyPhoneNumberSection && (
                <LabeledInput>
                    <label htmlFor={COMPANY_PHONE_NUMBER}>Phone Number</label>
                    <StyledInput
                        id={COMPANY_PHONE_NUMBER}
                        {...register(COMPANY_PHONE_NUMBER, {
                            validate: {
                                required: validateCompanyRequiredFields,
                            },
                            onBlur: () => {
                                trigger([COMPANY_NAME, COMPANY_DOMAIN]);
                            },
                        })}
                    />
                </LabeledInput>
            )}
            {errors.companyPhoneNumber && (
                <ErrorMessage>{errors.companyPhoneNumber.message}</ErrorMessage>
            )}
            <StyledButton>Add Lead</StyledButton>
        </StyledFormContainer>
    );
};
