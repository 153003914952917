import styled from 'styled-components';

export const StyledDropReasonColumn = styled.div`
    .ellipse {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 26px;
        width: 137px;
        border-radius: 60px;
        border: 1px solid;
        margin: 0 5px;
        color: rgba(223, 0, 0, 0.5);
        text-align: center;
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .ellipse-small {
        width: 37px;
        height: 26px;
    }
`;
