import { AccessTokenContext } from '@/providers/AccessTokenProvider';
import { StaticColumns } from '@/types/Types';
import axios from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';

import { SERVER_URL } from '../constants';

const fetcher = ([url, token]: string[]) =>
    axios
        .get<StaticColumns>(`${SERVER_URL}${url}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => res.data);

export default function useStaticColumns(): {
    data: StaticColumns;
    isLoading: boolean;
    isError: any;
} {
    const { accessToken } = useContext(AccessTokenContext);

    const { data, isLoading, error } = useSWR(
        accessToken ? ['/columns', accessToken] : null,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateIfStale: false,
        },
    );
    return {
        data: data!,
        isLoading,
        isError: error,
    };
}
