import { THEME } from '@/theme';
import styled from 'styled-components';

export const LeadsTablePaginationContainer = styled.div`
    float: right;
    margin-right: 15px;

    display: flex;
    align-items: center;
    margin: 1rem 0;
`;

export const LeadsTablePaginationButton = styled.button`
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover:enabled {
        background-color: #f7f7ff;
    }

    :hover:active:enabled {
        color: ${THEME.COLORS.PURPLE.DEFAULT};
    }

    :disabled {
        cursor: default;
    }
`;
