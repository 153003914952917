import { Button } from '@darrow-ai/darrow-design';
import styled from 'styled-components';

//TODO: change all Button to use this core one
export const StyledButton = styled(Button)`
    background-color: #6e80f6;
    border-radius: 10px;
    padding: 10px;
    color: white;

    :disabled {
        background-color: #cccccc;
        border: none;
    }
`;
