import { Auth0Provider } from '@auth0/auth0-react';
// <--- first import
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';

import Layout from './components/Layout';
import { LeadsManager } from './components/LeadsManager/LeadsManager';
import { SearchManager } from './components/SearchManager/SearchManager';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || '';
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const SCOPE = process.env.REACT_APP_AUTH0_SCOPE;

const Routes = (
    <Route path="/" element={<Layout />}>
        <Route index element={<LeadsManager />} />
        <Route path="search" element={<SearchManager />} />
    </Route>
);
const routes = createRoutesFromElements(Routes);
const router = createBrowserRouter(routes);

root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={DOMAIN}
            clientId={CLIENT_ID}
            redirectUri={window.location.origin}
            audience={AUDIENCE}
            scope={SCOPE}
        >
            <RouterProvider router={router} />
        </Auth0Provider>
    </React.StrictMode>,
);
