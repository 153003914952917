import { THEME } from '@/theme';
import { Button } from '@darrow-ai/darrow-design';
import styled, { css } from 'styled-components';

export const StyledBrowseFile = styled.div`
    text-decoration-line: underline;
    cursor: pointer;
    font-size: small;
`;

export const StyledDragAndDrop = styled.div`
    background: ${THEME.COLORS.GRAY.LIGHT};
    border-radius: 10px;
    text-align: center;
`;

interface StyledDragAndDropBodyProps {
    isError: boolean;
}

export const StyledDragAndDropBody = styled.div<StyledDragAndDropBodyProps>`
    padding: 20px;
    ${props =>
        props.isError &&
        css`
            border: 1px solid red;
            border-radius: 10px;
        `};
`;

export const StyledAddLeadsButton = styled(Button)`
    background-color: #6e80f6;
    border-radius: 10px;
    padding: 10px;
    color: white;

    :disabled {
        background-color: #cccccc;
        border: none;
    }
`;

export const StyledButton = styled(Button)`
    border-radius: 10px;
    padding: 10px;
`;

const StyledCSVMessage = styled.div`
    width: fit-content;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
`;

export const StyledCSVErrorMessage = styled(StyledCSVMessage)`
    background: linear-gradient(
        240.52deg,
        rgba(218, 76, 76, 0.1) 17.46%,
        rgba(223, 0, 0, 0.1) 106.53%
    );
    color: #da4c4c;
    text-align: center;
`;

export const StyledCSVWarningMessage = styled(StyledCSVMessage)`
    background-color: #ffebca;
    color: #af5f14;
    text-align: center;
`;

export const StyledCSVDuplicatesMessage = styled(StyledCSVMessage)`
    color: ${THEME.COLORS.PURPLE.DARK};
    background-color: ${THEME.COLORS.PURPLE.EXTRA_LIGHT};
`;

export const StyledCSVEmpty = styled.div`
    color: red;
    text-align: center;
    margin-top: 15px;
`;
