import { getCOASectionColumns } from '@/columns/columns';
import useCoaSectionsMetaData from '@/hooks/useCoaSectionsMetaData';
import { AccessTokenContext } from '@/providers/AccessTokenProvider';
import { ColumnType } from '@/types/enums';
import axios from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';

import { SERVER_URL } from '../constants';
import useCoas from './useCoas';

const fetcher = ([url, token]: string[]) =>
    axios
        .get(`${SERVER_URL}${url}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => res.data);

export default function useSelectOptions(): {
    data: {
        [key: string]: string[];
    };
    isLoading: boolean;
    isError: any;
} {
    const { accessToken: token } = useContext(AccessTokenContext);

    const { data, error, isLoading } = useSWR(
        token ? ['/staticData/selectOptions', token] : null,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateIfStale: false,
        },
    );

    const { data: coas } = useCoas();
    const { data: coaSectionsMetaData } = useCoaSectionsMetaData();
    const coaSectionsColumns = getCOASectionColumns(coas, coaSectionsMetaData);
    const selectOptions = {
        ...data,
        ...coaSectionsColumns
            ?.filter(({ type }) => type === ColumnType.SINGLE_SELECT)
            .reduce<{ [key: string]: string[] }>((acc, { accessor, options }) => {
                acc[accessor] = options;
                return acc;
            }, {}),
    };
    return {
        data: selectOptions,
        isLoading,
        isError: error,
    };
}
