import { Icon, IconButton, Popover } from '@darrow-ai/darrow-design';
import { useContext, useEffect, useState } from 'react';
import { Column } from 'react-table';

import { ColumnsContext, ColumnsContextType } from '../../providers/ColumnsProvider';
import { FilterContext } from '../../providers/FilterProvider';
import { EnrichedLead } from '../../types/Types';
import { ColumnType } from '../../types/enums';
import { Listbox } from '../BasicComponents/Listbox/Listbox';
import { ItemData } from '../BasicComponents/Listbox/ListboxItem';
import PopoverContent from '../BasicComponents/Popover/PopoverContent';
import PopoverHeader from '../BasicComponents/Popover/PopoverHeader';

export const AddFilterItem = () => {
    const { columns } = useContext(ColumnsContext) as ColumnsContextType;
    const { filterCategories, setFilterCategories } = useContext(FilterContext);

    const [items, setItems] = useState<ItemData[]>([]);

    const onChange = (item: ItemData) => {
        const getColumn = (columns: Column<EnrichedLead>[], item: ItemData) => {
            const column = columns.filter(column => column.Header === item.label)[0];
            return {
                displayName: column.Header?.toString() || '',
                name: column.accessor?.toString() || '',
                type: item.type,
            };
        };

        const { displayName, name: columnName, type: columnType } = getColumn(columns, item);

        // If column exists remove it
        if (filterCategories.some(filterCategory => filterCategory.name === columnName))
            setFilterCategories(
                filterCategories.filter(filterCategory => filterCategory.name !== columnName),
            );
        // If not then add it
        else
            setFilterCategories([
                ...filterCategories,
                {
                    name: columnName,
                    displayName,
                    columnType: columnType ?? ColumnType.TEXT,
                },
            ]);
    };

    useEffect(() => {
        setItems(
            columns.map<ItemData>(column => ({
                value: `${column.Header}`,
                label: `${column.Header}`,
                type: column.type,
                selected: filterCategories.some(
                    filterCategory => filterCategory.name === column.accessor,
                ),
            })),
        );
    }, [columns, filterCategories]);

    return (
        <Popover.Root>
            <Popover.Trigger>
                <div className={'add-filter-selector'}>
                    Add Filters
                    <Icon name="Plus" style={{ width: '24', height: '24' }} />
                </div>
            </Popover.Trigger>
            <Popover.Portal>
                <PopoverContent>
                    <Popover.Close asChild>
                        <IconButton name="ClosePopup" style={{ width: 16, height: 16 }} />
                    </Popover.Close>
                    <PopoverHeader text="Add Filter" iconName="Plus" />
                    <div style={{ maxHeight: '25rem', paddingTop: '1rem' }}>
                        <Listbox searchable={true} items={items} onItemSelected={onChange} />
                    </div>
                </PopoverContent>
            </Popover.Portal>
        </Popover.Root>
    );
};
