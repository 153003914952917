import useCoas from '@/hooks/useCoas';
import { useEffect, useMemo, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { Coa, EnrichedLead, UpdateLeadCallback } from '../../types/Types';
import DropDownEditor from './DropDownEditor';
import { useIsEditable } from './hooks/useIsEditable';

const CoaEditor = ({
    row,
    column,
    previewMode,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    previewMode: boolean;
    updateLead: UpdateLeadCallback;
}) => {
    const isEditable = useIsEditable(row, column);
    const { data: coas } = useCoas();
    const coasOptions = useMemo(
        () =>
            coas?.map((coa: Partial<Coa>) => ({
                label: coa.name,
                value: coa.id,
            })) || [],
        [coas],
    );
    const [coa, setCoa] = useState(() =>
        coasOptions.find(option => option.value === row.original.dcp.coa?.id),
    );
    const { id: field } = column;

    const updateCoA = (item?: { value: string; label: string }) => {
        if (!!field)
            updateLead(
                row.original.id,
                'dcp.coa',
                { id: item?.value, name: item?.label },
                { id: row.original.dcp.coa?.id, name: row.original.dcp.coa?.name },
            );
    };

    useEffect(() => {
        setCoa(coasOptions.find(option => option.value === row.original.dcp.coa?.id));
    }, [coasOptions, row.original]);

    return (
        <DropDownEditor
            value={coa}
            options={coasOptions}
            previewMode={previewMode}
            updateValue={item => {
                updateCoA(item);
                setCoa(coasOptions.find(coa => coa.value === item.value));
            }}
            disabled={!isEditable}
            isClearable={false}
        />
    );
};

export default CoaEditor;
