import { Input } from '@darrow-ai/darrow-design';
import styled from 'styled-components';

export const StyledInput = styled(Input)<{ error?: boolean }>`
    border-color: ${props =>
        props.error
            ? `border-color: linear-gradient(
            240.52deg,
            rgba(218, 76, 76, 0.8) 17.46%,
            rgba(200, 21, 74, 0.8) 106.53%
        )`
            : '#cccccc'};

    border-radius: 5px;
    width: 100%;
`;
