import useStaticColumns from '@/hooks/useStaticColumns';
import { BooleanValues } from '@/types/enums';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { D_MANAGER_SOURCE } from '../../constants';
import { EnrichedLead, ReferencedValue, UpdateLeadCallback } from '../../types/Types';
import { getUpdatedValue, isReferencedValueKey } from '../../utils';
import { SourceTooltip } from '../SourceTooltip';
import DropDownEditor from './DropDownEditor';
import { FlexEditorContainer } from './Editor.styles';
import { useIsEditable } from './hooks/useIsEditable';

const options: { label: string; value: boolean }[] = [
    {
        label: BooleanValues.TRUE,
        value: true,
    },
    {
        label: BooleanValues.FALSE,
        value: false,
    },
];

const BooleanEditor = ({
    row,
    column,
    value: initialValue,
    previewMode,
    disabled,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    value: boolean | ReferencedValue<boolean>;
    previewMode?: boolean;
    disabled?: boolean;
    updateLead: UpdateLeadCallback;
}) => {
    const isEditable = useIsEditable(row, column);
    const {
        original: { id: leadId },
    } = row;
    const { id: field } = column;
    const { data: staticColumns } = useStaticColumns();
    const [value, setValue] = useState(
        options.find(option => {
            if (isReferencedValueKey(staticColumns, field)) {
                // @ts-ignore
                return option.value === initialValue?.value;
            }
            return option.value === initialValue;
        }),
    );

    const updateOption = (newOption?: { label: string; value: boolean }) => {
        if (!!field && newOption?.value !== initialValue) {
            const updatedValue = getUpdatedValue(
                isReferencedValueKey(staticColumns, field)
                    ? { value: newOption?.value }
                    : newOption?.value,
                field,
                row.original,
            );
            updateLead(leadId, field, updatedValue, initialValue);
        }
        setValue(newOption);
    };

    useEffect(() => {
        setValue(
            options.find(option => {
                if (isReferencedValueKey(staticColumns, field)) {
                    // @ts-ignore
                    return option.value === initialValue?.value;
                }
                return option.value === initialValue;
            }),
        );
    }, [field, initialValue, row.original.id, staticColumns]);

    return (
        <FlexEditorContainer>
            <div style={{ width: '90%' }}>
                <DropDownEditor
                    disabled={!isEditable || disabled}
                    value={value}
                    options={options}
                    previewMode={previewMode}
                    isClearable
                    updateValue={newValue => {
                        updateOption(newValue);
                    }}
                />
            </div>
            {!_.isEmpty(initialValue) &&
                isReferencedValueKey(staticColumns, field) &&
                // @ts-ignore
                initialValue.source &&
                // @ts-ignore
                initialValue.source !== D_MANAGER_SOURCE && <SourceTooltip value={initialValue} />}
        </FlexEditorContainer>
    );
};

export default BooleanEditor;
