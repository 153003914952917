import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _ from 'lodash';
import Papa from 'papaparse';
import { MutableRefObject, useEffect, useRef } from 'react';

import {
    COA_SECTION_PREFIX,
    COMPANY_DOMAIN_REGEX_EXPRESSION,
    DIALOG_DEFAULT_WIDTH,
    D_MANAGER_SOURCE,
    INVALID_DOMAIN_ERROR_MESSAGE,
    MANUAL_DCP,
    PREVIEW_MODE_HEIGHT_LARGE,
    PREVIEW_MODE_HEIGHT_SMALL,
    PREVIEW_MODE_WIDTH_LARGE,
} from './constants';
import {
    Coa,
    Dcp,
    DropReason,
    EnrichedLead,
    RecursivePartial,
    StaticColumns,
    UpdateLeadInputFields,
} from './types/Types';
import {
    ColumnType,
    DialogWindowSize,
    LeadDecision,
    countriesDictionary,
    statesDictionary,
} from './types/enums';

dayjs.extend(customParseFormat);

export function useDebouncedCallback<A extends any[]>(
    callback: (...args: A) => void,
    wait: number,
) {
    // track args & timeout handle between calls
    const argsRef = useRef<A>();
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    function cleanup() {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }

    // make sure our timeout gets cleared if
    // our consuming component gets unmounted
    useEffect(() => cleanup, []);

    return function debouncedCallback(...args: A) {
        // capture latest args
        argsRef.current = args;

        // clear debounce timer
        cleanup();

        // start waiting again
        timeout.current = setTimeout(() => {
            if (argsRef.current) {
                callback(...argsRef.current);
            }
        }, wait);
    };
}

export function setValue(path: string, value: any, obj: { [key: string]: any }) {
    var schema = obj; // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
        var elem = pList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pList[len - 1]] = value;
}

export const getUpdatedValue = (input: any, field: string, lead?: EnrichedLead) => {
    const value = input ?? null;
    let updatedValue = value;
    if (lead) {
        if (field.includes('organization')) {
            const key: string = field.split('.').at(-1) || '';
            if (field.includes('incorporationPlace.country')) {
                updatedValue = {
                    id: lead.organization.id,
                    incorporationPlace: {
                        state: lead.organization.incorporationPlace?.value?.state,
                        [key]: value,
                    },
                };
            } else if (field.includes('incorporationPlace.state')) {
                updatedValue = {
                    id: lead.organization.id,
                    incorporationPlace: {
                        country: lead.organization.incorporationPlace?.value?.country,
                        [key]: value,
                    },
                };
            } else {
                updatedValue = {
                    id: lead.organization.id,
                    [key]: value,
                };
            }
        } else if (field.includes('service')) {
            const key: string = field.split('.').at(-1) || '';
            updatedValue = {
                service: {
                    id: lead.service?.id,
                    [key]: value,
                },
                organization: {
                    id: lead.organization.id,
                },
            };
        }

        if (field.includes('coaSection')) {
            const sectionId: string = field.split('.').at(-1) || '';
            updatedValue = {
                answer: value,
                dcpId: lead.dcp.id,
                id: +sectionId,
                coaId: lead.dcp.coa?.id,
            };
        }
    }

    if (field.includes('dropReasons')) {
        updatedValue = value.map(
            (reason: { label: string; value: string; __isNew__?: boolean }) => {
                if (reason.__isNew__) {
                    return {
                        title: reason.value,
                    };
                }

                return { title: reason.label, _id: reason.value };
            },
        );
    }

    if (field.includes('resolution') || field.includes('damage')) {
        const type = field.split('.')[1];

        return {
            [type]: updatedValue,
        };
    }

    if (field.includes('relevantCA') && field.includes('dockets')) {
        return {
            dockets: [updatedValue],
        };
    }

    return updatedValue;
};

export const downloadFile = (content: string, fileName: string, type: string = 'text/plain') => {
    const element = document.createElement('a');
    const file = new Blob([content], { type });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const findGenericProp = (obj: any, prop: string, defval: any = undefined) => {
    if (typeof defval == 'undefined') defval = null;
    const nestedProp = prop.split('.');
    for (var i = 0; i < nestedProp.length; i++) {
        if (typeof obj[nestedProp[i]] == 'undefined') return defval;
        obj = obj[nestedProp[i]];
    }
    return obj;
};

export const findProp = (lead: Partial<EnrichedLead>, prop: string, defval: any = undefined) => {
    if (prop.startsWith(COA_SECTION_PREFIX)) {
        const [, coaSectionId] = prop.split('.');
        const coaSection = lead.dcp?.coa?.coaSections.find(section => section.id === +coaSectionId);
        return coaSection?.answer;
    }

    return findGenericProp(lead, prop, defval);
};

export const remToPx = (rem: number): number =>
    rem * +getComputedStyle(window.document.body).fontSize.slice(0, -2);

export const setDialogWindowSize = (
    resolvedRef: MutableRefObject<HTMLDivElement>,
    size: DialogWindowSize,
) => {
    let maxWidth, height;
    const element = document.getElementById(resolvedRef?.current?.id);
    switch (size) {
        case DialogWindowSize.LARGE:
            maxWidth = PREVIEW_MODE_WIDTH_LARGE;
            height = PREVIEW_MODE_HEIGHT_LARGE;
            break;

        case DialogWindowSize.SMALL:
            maxWidth = DIALOG_DEFAULT_WIDTH;
            height = PREVIEW_MODE_HEIGHT_SMALL;
            break;
    }

    maxWidth && element && (element.style.maxWidth = maxWidth);
    height && element && (element.style.height = height);
};

export const updateKey = <Key extends keyof Object, Object>(
    key: Key,
    value: Object[Key],
    obj: Object,
) => {
    if (typeof value === 'object' && typeof obj[key] === 'object') {
        _.merge(obj[key], value);
    } else {
        obj[key] = value;
    }
};

export const updateObject = <Object>(
    fields: {
        [key: string]: any;
    },
    object: Object,
) => {
    Object.entries(fields).forEach(([key, value]) => updateKey(key as keyof Object, value, object));
};

export const getObjectFromString = (str: string, newValue: any = '') =>
    str.split('.').reduceRight(
        (obj, next) => ({
            [next]: obj,
        }),
        newValue,
    );

export const exportRowsToCSV = (rows: any[]) => Papa.unparse(rows);

export const getTodayFormat = (inputDate: Date) => {
    const date = new Date(inputDate);
    const yyyy = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dd = day < 10 ? `0${day}` : `${day}`;
    const mm = month < 10 ? `0${month}` : `${month}`;
    const today = `${dd}/${mm}/${yyyy}`;
    return today;
};

const nullishLabelByColumn: { [key: string]: string } = {
    assignee: 'Unassigned',
};

export const getFilterValueLabel = (value: any, category: string): string => {
    if (typeof value === 'number') return `${value}`;
    if (typeof value === 'boolean') return !!value ? 'Yes' : 'No';
    if (!value) return nullishLabelByColumn[category] ?? 'Unset';

    if (countriesDictionary[value]) return countriesDictionary[value];
    if (statesDictionary[value]) return statesDictionary[value];

    if (typeof value === 'string') return value;

    if (Array.isArray(value)) {
        if (value.length === 0) return nullishLabelByColumn[category] ?? 'Unset';

        return value
            .map((currentValue: any) => getFilterValueLabel(currentValue, category))
            .join(', ');
    }

    if (typeof value === 'object') {
        // DropReason
        if ('title' in value) return value.title;

        // ReferencedValue
        if ('value' in value) return `${value.value}`;

        // Date picker range
        if ('startDate' in value)
            return `${dayjs(value.startDate).format('DD/MM/YYYY')} - ${dayjs(value.endDate).format(
                'DD/MM/YYYY',
            )}`;

        // Number Range
        if ('minValue' in value && 'maxValue' in value) {
            return `${value.minValue?.toLocaleString()} - ${value.maxValue?.toLocaleString()}`;
        }
        if ('maxValue' in value) {
            return `Lower Than ${value.maxValue?.toLocaleString()}`;
        }
        if ('minValue' in value) {
            return `Higher Than ${value.minValue?.toLocaleString()}`;
        }
    }

    return `${value}`;
};

export const isNullUndefinedOrEmpty = (value: any) =>
    value === null || value === undefined || (Array.isArray(value) && value.length === 0);

export const pluralize = (count: number, noun: string, suffix = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const sortLeads = (
    data: Partial<EnrichedLead>[],
    staticColumns: StaticColumns,
    accessor?: string,
    desc?: boolean,
) => {
    if (!accessor) return data;
    const sortedLeads = _.sortBy(data, (lead: Partial<EnrichedLead>) => {
        if (accessor.startsWith(COA_SECTION_PREFIX)) {
            const [, id] = accessor.split('.');
            const coaSection = lead.dcp?.coa?.coaSections.find(section => section.id === +id);

            if (coaSection?.answer == null) {
                return null;
            }

            if (isNaN(+coaSection.answer)) {
                return coaSection.answer;
            }

            return +coaSection.answer;
        }

        const value = findProp(lead, accessor);

        if (isReferencedValueKey(staticColumns, accessor)) {
            return value?.value || Number.NEGATIVE_INFINITY;
        }

        switch (accessor) {
            // drop reason is array, need to sort it alphabetically first.
            case staticColumns.DROP_REASONS.ACCESSOR:
                const copy = _.cloneDeep(value) as DropReason[];
                copy?.sort((reason1, reason2) => {
                    if (reason1.title.toLowerCase() < reason2.title.toLowerCase()) {
                        return -1;
                    }
                    return 1;
                });

                return (copy && copy[0]?.title.toLowerCase()) || '';

            case staticColumns.ASSIGNEE.ACCESSOR:
                return value?.email;
            default:
                return value;
        }
    });

    if (desc) {
        return _.reverse(sortedLeads);
    }

    return sortedLeads;
};

// this function returns empty string if validation has passed, error message in case of a failure
export const validateEditorValue = (value: any, type?: ColumnType) => {
    const validObject = {
        isValid: true,
    };
    if (value === '' || isNullUndefinedOrEmpty(value)) {
        return validObject;
    }

    switch (type) {
        case ColumnType.DOMAIN:
            return {
                isValid: validateCompanyDomain(value),
                errorMessage: INVALID_DOMAIN_ERROR_MESSAGE,
            };

        default:
            return validObject;
    }
};

const COMPANY_DOMAIN_REGEX = new RegExp(COMPANY_DOMAIN_REGEX_EXPRESSION);
export const validateCompanyDomain = (domain: string) => COMPANY_DOMAIN_REGEX.test(domain);

export const isReferencedValueKey = (columns: StaticColumns, key: string) =>
    Object.values(columns).find(column => column.ACCESSOR === key)?.IS_REFERENCED_VALUE;

export const findManualDcp = (dcps: RecursivePartial<Dcp>[], coaName?: string) =>
    dcps.find(dcp => dcp.name === `${coaName} ${MANUAL_DCP}`);

export const findTCPAPhoneNumberCoaSection = (coa?: Partial<Coa>) =>
    coa?.coaSections?.find(
        section => section.id === +(process.env?.REACT_APP_TCPA_PHONE_NUMBER_ID || ''),
    );

export function mapUpdatedValueToFields(field: string, value: any): Partial<UpdateLeadInputFields> {
    if (field.includes('organization.')) {
        return { organization: value };
    }

    if (field.includes('service.')) {
        return { service: value.service, organization: value.organization };
    }

    if (field.includes('coaSection')) {
        return { coaSections: [value] };
    }

    if (field.includes('legalMeritsValues')) {
        return { legalMeritsValues: value };
    }

    if (field.includes('dropReasons')) {
        return { lead: { decision: LeadDecision.DROP, dropReasons: value } };
    }

    if (field.includes('resolution') || field.includes('damage')) {
        const key = field.split('.')[0];
        return { lead: { [key]: value } };
    }

    if (field.includes('relevantCA')) {
        return { relevantCA: value };
    }

    if (field.includes('dcp.coa')) {
        return { lead: { dcp: { coa: { id: value.id, name: value.name } } } };
    }

    return { lead: { [field]: value } };
}

export const containsAny = (str: string, choices: string[]) =>
    choices.some(choice => str.includes(choice));

export const includesAny = <T>(array: T[], choices: T[]) =>
    choices.some(choice => array.includes(choice));

export const getKeysByRoute = (key: string[], route: string, excludeKeys?: string[]) =>
    key &&
    typeof key === 'object' &&
    typeof key[0] === 'string' &&
    key[0].startsWith(route) &&
    (!excludeKeys?.some(excludeKey => key[0].startsWith(excludeKey)) ?? true);

export const isLowerThan = (minValue: any, maxValue: any) =>
    _.isNumber(maxValue) && !_.isNumber(minValue);
export const isHigherThan = (minValue: any, maxValue: any) =>
    _.isNumber(minValue) && !_.isNumber(maxValue);
export const isBetween = (minValue: any, maxValue: any) =>
    _.isNumber(minValue) && _.isNumber(maxValue);

export const mapToReferencedValue = (
    userEmail: string,
    value: any,
    source?: string,
    citation?: string,
    comment?: string,
) => ({
    author: [userEmail],
    citation: citation ?? '',
    comment: comment ?? '',
    source: source ?? D_MANAGER_SOURCE,
    value,
});
