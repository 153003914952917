import { Button, IconButton, Popover } from '@darrow-ai/darrow-design';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';

import { Coa } from '../../../types/Types';
import PopoverContent from '../../BasicComponents/Popover/PopoverContent';
import PopoverHeader from '../../BasicComponents/Popover/PopoverHeader';
import { SingleSelect } from '../../BasicComponents/SingleSelect/SingleSelect';

type AddColumnContentProps = {
    open: boolean;
    coas: Partial<Coa>[];
    onSubmitCallback: (name: string, coaId?: string) => void;
};

const DEFAULT_ERRORS_VALUES = {
    coa: '',
    name: '',
};

const COA_REQUIRED_ERROR_MESSAGE = 'COA is Required';
const COLUMN_NAME_REQUIRED_ERROR_MESSAGE = 'Column Name is Required';
const COLUMN_NAME_IN_USED_ERROR_MESSAGE = 'Column Name is Already Used';

const validate = (coas: Partial<Coa>[], coaName?: string, columnName?: string) => {
    let error = { ...DEFAULT_ERRORS_VALUES };
    if (!coaName) {
        error.coa = COA_REQUIRED_ERROR_MESSAGE;
    }

    if (!columnName) {
        error.name = COLUMN_NAME_REQUIRED_ERROR_MESSAGE;
    } else {
        // check if coa section name all ready in used
        const found = coas.find(currentCoa =>
            currentCoa.coaSections?.find(section => section.name === columnName),
        );

        if (found) {
            error.name = COLUMN_NAME_IN_USED_ERROR_MESSAGE;
        }
    }

    return error;
};

export const AddColumnContent = forwardRef<HTMLDivElement, AddColumnContentProps>(
    ({ open, coas, onSubmitCallback }, ref) => {
        const [name, setName] = useState('');
        const [selectedCoa, setSelectedCoa] = useState<{
            label: string;
            value: string;
        } | null>();
        const [error, setError] = useState({ ...DEFAULT_ERRORS_VALUES });

        const clearValues = () => {
            setName('');
            setSelectedCoa(null);
            setError({ ...DEFAULT_ERRORS_VALUES });
        };

        useEffect(() => {
            if (!open) {
                clearValues();
            }
        }, [open]);

        const onClick = () => {
            // add colum action
            const validationValues = validate(coas, selectedCoa?.value, name);
            if (validationValues.coa || validationValues.name) {
                setError(validationValues);
                return;
            }

            onSubmitCallback(name, selectedCoa?.value);
            clearValues();
        };

        const onNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
            const {
                target: { value },
            } = e;
            setName(value);
            setError(prevState => ({
                ...prevState,
                name: value ? '' : COLUMN_NAME_REQUIRED_ERROR_MESSAGE,
            }));
        };

        const onCOAChanged = (newValue: { label: string; value: string }) => {
            setSelectedCoa(newValue);
            setError(prevState => ({
                ...prevState,
                coa: newValue?.value ? '' : COA_REQUIRED_ERROR_MESSAGE,
            }));
        };

        const options = coas?.map(coa => ({
            value: coa.id!,
            label: coa.name!,
        }));

        return (
            <PopoverContent ref={ref}>
                <Popover.Close asChild>
                    <IconButton name="ClosePopup" style={{ width: 16, height: 16 }} />
                </Popover.Close>
                <PopoverHeader text="Add Column" iconName="Plus" />
                <div>
                    <div
                        style={{
                            marginBottom: 10,
                        }}
                    >
                        Column Name
                    </div>
                    <input
                        style={{
                            width: '90%',
                            height: 30,
                            padding: '0 0.625rem',
                            fontSize: 16,
                        }}
                        value={name}
                        onChange={onNameChanged}
                    />
                </div>
                <div style={{ marginTop: 20 }}>
                    <div style={{ marginBottom: 10 }}>COA</div>
                    <SingleSelect value={selectedCoa} options={options} onChange={onCOAChanged} />
                </div>
                <div style={{ marginTop: 10 }}>
                    {<div style={{ color: 'red' }}>{error.name}</div>}
                    {<div style={{ color: 'red' }}>{error.coa}</div>}
                    <div
                        style={{
                            marginTop: 30,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            style={{
                                backgroundColor: '#6E80F6',
                                borderRadius: 10,
                                color: 'white',
                            }}
                            onClick={onClick}
                        >
                            Add Column
                        </Button>
                    </div>
                </div>
            </PopoverContent>
        );
    },
);
