import { StyledLink } from '@/components/BasicComponents/StyledLink';
import Duplicates from '@/components/SVG/Duplicates';
import { SERVER_URL } from '@/constants';
import { allLeadsPagesMatcher } from '@/hooks/useLeads/useFetchLeads';
import { EnrichedLead, PreviewLead } from '@/types/Types';
import { DialogWindowSize } from '@/types/enums';
import { downloadFile, exportRowsToCSV, setDialogWindowSize } from '@/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { Icon } from '@darrow-ai/darrow-design';
import axios from 'axios';
import _, { isEmpty } from 'lodash';
import {
    MutableRefObject,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { Column } from 'react-table';
import { mutate } from 'swr';

import { PreviewLeadsTable } from '@components/Dialog/AddLeadsDialog.tsx/ImportLeads/PreviewLeadsTable';

import { AccessTokenContext } from '@providers/AccessTokenProvider';

import { DiscardContent } from './DiscardContent';
import {
    StyledAddLeadsButton,
    StyledButton,
    StyledCSVDuplicatesMessage,
    StyledCSVErrorMessage,
    StyledCSVWarningMessage,
} from './StyledImportLeads';
import { validateRequiredFields } from './importLeadsUtils';

const ERROR_MESSAGE =
    'Mandatory fields (CoA, DCP, Lead Source, Company Name/Company Website/Phone Number) are missing. Please fix the file and re-upload it.';
const WARNING_MESSAGE =
    "Some of the leads are not valid and can't be updated. Continuing with import will ignore the invalid leads.";

type ImportLeadsPreviewProps = {
    previewLeads: PreviewLead[];
    duplicateLeadsRowData: Record<string, string>[];
    columns: Column<EnrichedLead>[];
    closeDialog: () => void;
};

const errorCell = {
    id: 'errorIcon',
    Header: () => <div />,
    Cell: ({ row }: any) => {
        const { original }: { original: EnrichedLead } = row;
        if (original.invalid && !isEmpty(original.invalid)) {
            return <Icon style={{ height: 12, width: 12 }} name="Error" />;
        }
        return null;
    },
};

export const ImportLeadsPreview = forwardRef<HTMLDivElement, ImportLeadsPreviewProps>(
    ({ previewLeads, duplicateLeadsRowData, columns, closeDialog }, dialogContentRef) => {
        const [showDiscardMessage, setShowDiscardMessage] = useState(false);
        const [isValid, setIsValid] = useState(true);
        const { accessToken } = useContext(AccessTokenContext);
        const { user } = useAuth0();
        const resolvedDialogContentRef = dialogContentRef as MutableRefObject<HTMLDivElement>;

        useLayoutEffect(() => {
            setDialogWindowSize(resolvedDialogContentRef, DialogWindowSize.LARGE);
        }, [resolvedDialogContentRef]);

        useEffect(() => {
            const requiredFieldsValidation = validateRequiredFields(previewLeads);
            setIsValid(Object.values(requiredFieldsValidation).every(value => value));
        }, [previewLeads]);

        const updateLeads = async () => {
            closeDialog();
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            const filteredImportedLeads = previewLeads.filter(
                lead => !lead.invalid || isEmpty(lead.invalid),
            );

            const body = {
                importedLeads: filteredImportedLeads.map(lead => _.omit(lead, 'columns')),
                userEmail: user?.email,
            };

            await axios.post(`${SERVER_URL}/leads/bulk`, body, config);

            await mutate(allLeadsPagesMatcher);
        };

        const downloadDuplicateLeads = useCallback(() => {
            const csvData = exportRowsToCSV(duplicateLeadsRowData);
            downloadFile(csvData, 'duplicate_leads.csv');
        }, [duplicateLeadsRowData]);

        if (showDiscardMessage) {
            setDialogWindowSize(resolvedDialogContentRef, DialogWindowSize.SMALL);
            return (
                <DiscardContent
                    cancel={() => {
                        setDialogWindowSize(resolvedDialogContentRef, DialogWindowSize.LARGE);
                        setShowDiscardMessage(false);
                    }}
                    discard={() => closeDialog()}
                />
            );
        }

        const validLeads = previewLeads.filter(lead => isEmpty(lead.invalid));
        const isWarning = validLeads.length !== previewLeads.length;

        return (
            <>
                <div
                    style={{
                        maxHeight: '40rem',
                        overflowY: 'scroll',
                        opacity: isValid ? 1 : 0.5,
                    }}
                >
                    <PreviewLeadsTable
                        columns={[errorCell, ...(columns as Column<Partial<EnrichedLead>>[])]}
                        data={previewLeads}
                    />
                </div>
                {!isValid && <StyledCSVErrorMessage>{ERROR_MESSAGE}</StyledCSVErrorMessage>}
                {isValid && isWarning && (
                    <StyledCSVWarningMessage>{WARNING_MESSAGE}</StyledCSVWarningMessage>
                )}
                {duplicateLeadsRowData.length > 0 && (
                    <StyledCSVDuplicatesMessage>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                gap: '1rem',
                            }}
                        >
                            <Duplicates />
                            <span>
                                We have detected duplicates leads, these leads will not be uploaded.
                                <br />
                                You can find the list of duplicate leads here:
                            </span>
                        </div>
                        <StyledLink
                            style={{
                                fontWeight: 600,
                                marginTop: '0.25rem',
                                marginLeft: 'auto',
                            }}
                            onClick={downloadDuplicateLeads}
                        >
                            Download Duplicate Leads CSV File
                        </StyledLink>
                    </StyledCSVDuplicatesMessage>
                )}
                <div
                    style={{
                        display: 'flex',
                        position: 'fixed',
                        bottom: 15,
                        right: 15,
                    }}
                >
                    <StyledButton
                        style={{ marginRight: 20 }}
                        onClick={() => setShowDiscardMessage(true)}
                    >
                        Discard
                    </StyledButton>

                    <StyledAddLeadsButton onClick={() => updateLeads()} isDisabled={!isValid}>
                        Add {validLeads.length} lead{validLeads.length !== 1 ? 's' : ''}
                    </StyledAddLeadsButton>
                </div>
            </>
        );
    },
);
