import { Typography } from '@/components/BasicComponents/Typography';
import { ProfileOptions, TableProfileContext } from '@/providers/TableProfileProvider';
import { useContext } from 'react';

import DisappointedTiki from '../SVG/DisappointedTiki';
import EmptyTableSVG from './EmptyTableSVG';
import { StyledTbody } from './LeadsTableBody.styles';

const EmptyTableContent = () => {
    const { tableProfile } = useContext(TableProfileContext);
    if (tableProfile === ProfileOptions.SEARCH) {
        return (
            <>
                <DisappointedTiki />
                <Typography variant="p" size="large">
                    No Results Found
                </Typography>
                <Typography variant="span">
                    <b>Oops!</b>
                    <div>We Couldn't Find Results</div>
                    <div>In Company Name Or Company Domain</div>
                </Typography>
            </>
        );
    }
    return (
        <>
            <EmptyTableSVG />
            <Typography variant="p" size="large">
                No Results Found
            </Typography>
            <Typography variant="span">
                <div>Oh… I guess there is nothing to see here</div>
                <div>Maybe change your filters or load more leads</div>
            </Typography>
        </>
    );
};

const EmptyTableBody = () => {
    return (
        <StyledTbody
            style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
            }}
        >
            <tr>
                <td>
                    <EmptyTableContent />
                </td>
            </tr>
        </StyledTbody>
    );
};

export default EmptyTableBody;
