import { Icon } from '@darrow-ai/darrow-design';
import React, { ChangeEvent, RefObject } from 'react';
import styled from 'styled-components';

export interface ListboxSearchProps {
    searchTerm: string;
    searchPlaceholder?: string;
    onSearchChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const StyledSearchContainer = styled.div`
    display: flex;
    border-bottom: solid 1px rgba(151, 151, 151, 0.09);
    margin: 0 0 1rem 0;
`;

const StyledSearchInput = styled.input`
    width: 100%;
    border: none;
    outline: none;
    padding-bottom: 6px;
`;

export const ListboxSearch = ({
    searchTerm,
    searchPlaceholder,
    onSearchChange,
}: ListboxSearchProps) => {
    const search = React.useRef<HTMLInputElement>() as RefObject<HTMLInputElement>;

    const handleMagnifierClick = () => {
        search.current?.focus();
    };

    const handleOnSearchChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if (onSearchChange) {
            onSearchChange(evt);
        }
    };

    return (
        <StyledSearchContainer>
            <StyledSearchInput
                ref={search}
                type="search"
                placeholder={searchPlaceholder}
                onChange={handleOnSearchChange}
                value={searchTerm}
            />
            <span onClick={handleMagnifierClick} style={{ height: '1.5rem' }}>
                <Icon
                    name="Search"
                    style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        cursor: 'pointer',
                    }}
                />
            </span>
        </StyledSearchContainer>
    );
};
