import { Typography } from '@/components/BasicComponents/Typography';
import { Tooltip } from '@darrow-ai/darrow-design';
import { ReactNode, useLayoutEffect, useRef, useState } from 'react';

import { StyledBadge } from './StyledBadge';

type BadgeProps = {
    children: ReactNode;

    isMulti?: boolean;
};

const Badge = ({ children, isMulti }: BadgeProps) => {
    const badgeTextRef = useRef<HTMLDivElement>(null);

    // State for tracking if ellipsis is active
    const [isEllipsisActive, setIsEllipsisActive] = useState(false);

    useLayoutEffect(() => {
        if (
            badgeTextRef &&
            badgeTextRef.current &&
            badgeTextRef.current?.offsetWidth < badgeTextRef.current?.scrollWidth
        ) {
            setIsEllipsisActive(true);
        }
    }, []);

    if (!isEllipsisActive) {
        return (
            <StyledBadge ref={badgeTextRef} isMulti={isMulti}>
                {children}
            </StyledBadge>
        );
    }

    return (
        <Tooltip delayDuration={400}>
            <Tooltip.Trigger asChild>
                <StyledBadge ref={badgeTextRef} isMulti={isMulti}>
                    {children}
                </StyledBadge>
            </Tooltip.Trigger>

            <Tooltip.Content side="top" sideOffset={5} align="end">
                <Typography style={{ color: 'white' }}>{children}</Typography>

                <Tooltip.Arrow offset={10} />
            </Tooltip.Content>
        </Tooltip>
    );
};
export default Badge;
