import {
    AsyncCreatableSingleSelectProps as AsyncCreatableSingleSelectPropsType,
    AsyncCreatableSingleSelect as DsAsyncCreatableSingleSelect,
} from '@darrow-ai/darrow-design';
import React from 'react';

export type AsyncSingleSelectProps = Partial<AsyncCreatableSingleSelectPropsType> & {
    disabled?: boolean;
    border?: boolean;
};

export const AsyncSingleSelect = React.forwardRef(
    (
        {
            value,
            defaultOptions,
            disabled,
            onChange,
            loadOptions,
            border,
            ...props
        }: AsyncSingleSelectProps,
        ref,
    ) => {
        return (
            <DsAsyncCreatableSingleSelect
                ref={ref}
                isDisabled={disabled}
                options={[]}
                value={value}
                defaultOptions={defaultOptions}
                loadOptions={loadOptions}
                onChange={onChange}
                classNamePrefix="react-select"
                border={border}
                {...props}
            >
                <DsAsyncCreatableSingleSelect.Control />
                <DsAsyncCreatableSingleSelect.MenuList />
                <DsAsyncCreatableSingleSelect.Option />
                <DsAsyncCreatableSingleSelect.DropdownIndicator />
            </DsAsyncCreatableSingleSelect>
        );
    },
);
