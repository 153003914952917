import { EnrichedLead } from '@/types/Types';
import { LeadStatus } from '@/types/enums';
import { useAuth0 } from '@auth0/auth0-react';
import { ColumnInstance, Row } from 'react-table';

import useStaticColumns from '@hooks/useStaticColumns';

export const useIsEditable = (
    { original: { assignee, status } }: Row<EnrichedLead>,
    { id: field }: ColumnInstance<EnrichedLead>,
): boolean => {
    const { user } = useAuth0();
    const isAssignedToCurrentUser = !!assignee && assignee.userId === user?.sub;
    const isStatusInProgress = status === LeadStatus.IN_PROGRESS;

    const { data: staticColumns } = useStaticColumns();

    if (field === staticColumns.ASSIGNEE.ACCESSOR) return true;
    if (field === staticColumns.STATUS.ACCESSOR) return isAssignedToCurrentUser;

    return isAssignedToCurrentUser && isStatusInProgress;
};
