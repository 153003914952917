import { THEME } from '@/theme';
import * as React from 'react';

const DisappointedTiki = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={220} height={158} fill="none" {...props}>
        <path
            fill="#111651"
            fillRule="evenodd"
            d="M75.728 27.697c0-23.908.02-24.156 2.039-25.57 3.5-2.451 3.512-2.445 16.153 8.871l4.89 4.379h21.997l8.185-7.338c4.503-4.037 8.827-7.34 9.612-7.34.784 0 2.438 1.014 3.677 2.252l2.252 2.252V55.51c0 42.38-.223 51.168-1.416 55.773-2.358 9.102-7.729 15.768-16.473 20.446l-4.587 2.453-39.77.459c-26.757.308-41.166.134-44.036-.53C16.576 129.088.5 105.91.5 79.676c0-6.912.17-7.549 2.836-10.584 6.115-6.965 16.462-5.832 20.524 2.246.325.646.686 2.045 1.003 3.766a56.295 56.295 0 0 1 .71 5.41c1.036 12.728 5.21 21.209 13.023 26.463l4.564 3.068.472-15.453c.458-14.992.558-15.639 3.388-21.639 4.858-10.305 15.553-19.008 25.267-20.562l3.44-.551V27.697Zm27.128 10.188-2.803.283A6.882 6.882 0 1 1 86.825 39.5l-3.889.39c-.022.862.024 1.848.123 3.01.43 5.073 4.52 8.256 10.604 8.256 4.99 0 9.587-4.838 9.587-10.091 0-1.311-.097-2.295-.394-3.18Zm23.788 13.271c6.553 0 10.707-5.44 10.379-10.843l-4.622-.465a6.881 6.881 0 1 1-12.882-1.297l-2.699-.272c-.515 1.815-.517 3.85.186 5.983 1.469 4.449 4.886 6.894 9.638 6.894Z"
            clipRule="evenodd"
        />
        <path
            fill={THEME.COLORS.GRAY.LIGHT}
            d="M107.994 50.24a1.835 1.835 0 0 1 0-3.67h3.67a1.835 1.835 0 0 1 0 3.67l-.001 2.505c.078.062.153.13.223.207l1.208 1.31c.717.778.717 2.038 0 2.815a1.734 1.734 0 0 1-2.594 0l-.773-.838-.647.702a1.735 1.735 0 0 1-2.595 0c-.716-.778-.716-2.038 0-2.815l1.082-1.174c.13-.14.274-.256.427-.345V50.24Z"
        />
        <ellipse cx={186.702} cy={128.392} fill="#5B5D78" rx={23.485} ry={7.693} />
        <path
            fill="#111651"
            fillRule="evenodd"
            d="M186.703 136.083c12.97 0 23.484-3.444 23.484-7.693 0-.27-.042-.538-.125-.801l7.745 10.292c1.098 1.089 1.693 2.254 1.693 3.465 0 6.038-14.775 10.933-33 10.933s-33-4.895-33-10.933c0-1.284.668-2.516 1.895-3.66l7.951-10.104a2.639 2.639 0 0 0-.128.808c0 4.249 10.514 7.693 23.485 7.693Z"
            clipRule="evenodd"
        />
        <path
            fill="#BBBCC2"
            fillRule="evenodd"
            d="M166.438 132.28c3.83-2.545 11.828-4.296 21.075-4.296 8.638 0 16.187 1.528 20.266 3.804-3.83 2.545-11.829 4.295-21.075 4.295-8.639 0-16.187-1.528-20.266-3.803Z"
            clipRule="evenodd"
        />
        <ellipse cx={136.5} cy={149.199} fill="#1E2574" rx={2} ry={1.5} />
        <ellipse
            cx={144.983}
            cy={155.002}
            fill="#1E2574"
            rx={2}
            ry={1.5}
            transform="rotate(-30.174 144.983 155.002)"
        />
        <ellipse
            cx={152.949}
            cy={149.908}
            fill="#1E2574"
            rx={2}
            ry={1.5}
            transform="rotate(25.22 152.949 149.908)"
        />
    </svg>
);
export default DisappointedTiki;
