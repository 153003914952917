import TableLoader from '@/components/LeadsTable/TableLoader';
import { FilterContext } from '@/providers/FilterProvider';
import { SortContext } from '@/providers/SortProvider';
import { UPDATE_TRIGGERS } from '@/types/enums';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    Column,
    TableInstance,
    TableState,
    UseFiltersOptions,
    UsePaginationInstanceProps,
    UsePaginationOptions,
    UsePaginationState,
    UseRowSelectInstanceProps,
    UseRowSelectState,
    UseSortByOptions,
    usePagination,
    useRowSelect,
    useTable,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import { LeadsTableBody, LeadsTableHeader, LeadsTablePagination } from '.';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { PageContext } from '../../providers/PageProvider';
import { DropReason, EnrichedLead, LeadsTableOptions } from '../../types/Types';
import { remToPx } from '../../utils';
import {
    ScrollArea,
    ScrollAreaScrollbar,
    ScrollAreaThumb,
    ScrollAreaViewport,
} from '../BasicComponents/ScrollArea';
import DropReasonsDialog from '../Dialog/DropReasonsDialog';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import { StyledContainer, StyledTable, StyledWrapper } from './LeadsTable.styles';
import SelectionActions from './SelectionActions/SelectionActions';
import { useUpdateLead } from './hooks/useUpdateLead';

export interface LeadsTableProps {
    data: Partial<EnrichedLead>[];
    columns: Column<Partial<EnrichedLead>>[];
    activeUserId: string;
    totalLeadsCount: number;
    isLoading: boolean;
    style?: React.CSSProperties;
}

export const LeadsTable = ({
    activeUserId,
    data,
    columns = [],
    totalLeadsCount,
    isLoading,
    style = {},
}: LeadsTableProps) => {
    const { updateLead } = useUpdateLead();
    const [dropReasonDialog, setDropReasonDialog] = useState<{
        open: boolean;
        updateDropReasons: (dropReasons: Partial<DropReason>[]) => any;
    }>({
        open: false,
        updateDropReasons: () => null,
    });

    /* 
    since we handle pagination on our own, with custom hook (usePagination),
    react-table doesn't know if all the leads are being selected, only a single page.
    */
    const [isAllLeadsSelected, setIsALlLeadsSelected] = useState(false);

    const { sortedBy } = useContext(SortContext);
    const { selectedFilters } = useContext(FilterContext);
    const { canNextPage, canPreviousPage, gotoPage, nextPage, pageCount, pageIndex, previousPage } =
        useContext(PageContext);

    const setIsDropReasonsDialogOpen = (state: boolean) => {
        setDropReasonDialog(prev => ({
            open: state,
            updateDropReasons: prev.updateDropReasons,
        }));
    };

    const updateLeadInsideTable = (id: number, field: string, value: any, oldValue: any) => {
        updateLead(id, field, value, oldValue, UPDATE_TRIGGERS.TABLE);
    };

    const options: LeadsTableOptions &
        UsePaginationOptions<Partial<EnrichedLead>> &
        UseFiltersOptions<EnrichedLead> &
        UseSortByOptions<EnrichedLead> = {
        columns,
        activeUserId,
        data,
        previewMode: false,
        updateLead: updateLeadInsideTable,
        setDropReasonDialog,
        initialState: {
            pageIndex: 0,
            pageSize: DEFAULT_PAGE_SIZE,
            sortBy: sortedBy.accessor ? [{ ...sortedBy, id: sortedBy.accessor }] : [],
            filters:
                selectedFilters.map(selectedFilter => ({
                    id: selectedFilter.accessor,
                    value: selectedFilter.value,
                })) || [],
        },
        pageCount: Math.ceil(totalLeadsCount! / DEFAULT_PAGE_SIZE),
        manualPagination: true,
        manualFilters: true,
        manualSortBy: true,
        useControlledState: state => {
            return useMemo(
                () => ({
                    ...state,
                    pageIndex,
                }),
                [state],
            );
        },
    };

    const instance = useTable<Partial<EnrichedLead>>(
        options,
        usePagination,
        useRowSelect,
        useSticky,
        hooks => {
            hooks.visibleColumns.push(columns => {
                return [
                    {
                        id: 'selection',
                        Cell: ({ row }: any) => {
                            return (
                                <IndeterminateCheckbox
                                    id={row.id}
                                    {...row.getToggleRowSelectedProps()}
                                />
                            );
                        },
                        width: remToPx(1.4),
                        sticky: 'left',
                    },
                    ...columns.map(col => {
                        //@ts-ignore
                        col.sticky = col.sticky ? 'left' : undefined;
                        return col;
                    }),
                ];
            });
        },
    );

    const {
        headerGroups,
        getTableProps,
        getTableBodyProps,
        prepareRow,
        selectedFlatRows,
        getToggleAllRowsSelectedProps,
        state: tableState,
        toggleAllRowsSelected,
        page,
    } = instance as TableInstance<EnrichedLead> &
        UsePaginationInstanceProps<EnrichedLead> &
        UseRowSelectInstanceProps<EnrichedLead>;

    const { selectedRowIds, pageSize } = tableState as TableState<EnrichedLead> &
        UsePaginationState<EnrichedLead> &
        UseRowSelectState<EnrichedLead>;

    const tableProps = getTableProps();
    const tableBodyProps = getTableBodyProps();
    const toggleAllRowsSelectionProps = getToggleAllRowsSelectedProps();

    const selectAllLeads = useCallback(() => {
        toggleAllRowsSelected(true);
        setIsALlLeadsSelected(true);
    }, [toggleAllRowsSelected]);

    useEffect(() => {
        if (isAllLeadsSelected && !toggleAllRowsSelectionProps.checked) {
            setIsALlLeadsSelected(false);
        }
    }, [toggleAllRowsSelectionProps.checked, isAllLeadsSelected]);

    return (
        <StyledWrapper style={style}>
            <div>
                <LeadsTablePagination
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    totalLeads={totalLeadsCount}
                    pageSize={pageSize}
                    canNextPage={canNextPage()}
                    canPreviousPage={canPreviousPage()}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    gotoPage={gotoPage}
                />
            </div>
            {!!Object.keys(selectedRowIds).length && (
                <SelectionActions
                    selectedRowIds={selectedRowIds}
                    selectedFlatRows={selectedFlatRows}
                    getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps}
                    isAllLeadsSelected={isAllLeadsSelected}
                    setDropReasonDialog={setDropReasonDialog}
                    selectAllLeads={selectAllLeads}
                />
            )}

            <DropReasonsDialog
                open={dropReasonDialog.open}
                onOpenChange={setIsDropReasonsDialogOpen}
                updateDropReasons={dropReasonDialog.updateDropReasons}
            />

            <StyledContainer selectedRowIds={selectedRowIds}>
                <ScrollArea style={{ height: '100%' }}>
                    <ScrollAreaViewport>
                        <StyledTable {...tableProps}>
                            <LeadsTableHeader previewMode={false} headerGroups={headerGroups} />
                            {isLoading ? (
                                <TableLoader />
                            ) : (
                                <LeadsTableBody
                                    rows={page}
                                    tableBodyProps={tableBodyProps}
                                    prepareRow={prepareRow}
                                    previewMode={false}
                                    selectedRowIds={selectedRowIds}
                                />
                            )}
                        </StyledTable>
                    </ScrollAreaViewport>
                    <ScrollAreaScrollbar orientation="vertical">
                        <ScrollAreaThumb />
                    </ScrollAreaScrollbar>
                    {data.length > 0 && (
                        <ScrollAreaScrollbar orientation="horizontal">
                            <ScrollAreaThumb />
                        </ScrollAreaScrollbar>
                    )}
                </ScrollArea>
            </StyledContainer>
        </StyledWrapper>
    );
};
