export enum LeadStatus {
    TODO = 'To Do',
    PENDING_ENRICHMENT = 'Pending Enrichment',
    ENRICHMENT_DONE = 'Enrichment Done',
    IN_PROGRESS = 'In Progress',
    ON_HOLD = 'On Hold',
    DUPLICATE = 'Duplicate',
    DONE = 'Done',
}

export enum LeadDecision {
    DROP = 'Drop',
    TAKE = 'Take',
}

export enum OrganizationType {
    PRIVATE = 'Private',
    PUBLIC = 'Public',
    NON_PROFIT = 'Non Profit',
    GOVERNMENT_AGENCY = 'Government Agency',
    DOMESTIC_NON_PROFIT = 'Domestic Non Profit',
}

export enum ServiceType {
    APP = 'App',
    WEBSITE = 'Website',
    APP_WEBSITE = 'App & Website',
}

export enum ENTITIES {
    lead = 'lead',
    organization = 'organization',
    service = 'service',
    caseTierScores = 'caseTierScores',
    glvScores = 'glvScores',
}

export enum Arbitrator {
    AAA = 'AAA',
    JAMS = 'JAMS',
}

export enum DialogWindowSize {
    SMALL = 'small',
    LARGE = 'large',
}

export enum BooleanValues {
    TRUE = 'Yes',
    FALSE = 'No',
}

export enum ColumnType {
    SINGLE_SELECT = 'single-select',
    DATE = 'date',
    TEXT = 'text',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    MULTI_SELECT = 'multi-select',
    DOMAIN = 'domain',
    PLACE = 'place',
    COMBOBOX = 'combobox',
    OTHER = 'other',
}

export enum PanelSections {
    COMPANY_DATA = 'Company Data',
    PROCEDURAL_LAW_DATA = 'Procedural Law Data',
    DAMAGES_DATA = 'Damages Data',
    RELEVANT_CA = 'Relevant CA',
    COA_SPECIFIC = 'CoA and Legal Merits Data',
}

export enum INVESTIGATION_FIELD_GROUPS {
    ARBITRATION = 'Arbitration',
    JURISDICTION = 'Jurisdiction',
    CLASS_WAIVER = 'Class Waiver',
    CHOICE_OF_LAW = 'Choice of Law',
    COA_SECTIONS = 'Coa Sections Data',
}

export enum UPDATE_TRIGGERS {
    TABLE = 'table',
    PANEL = 'investigation-panel',
}

export enum DOCKET_CONCLUSION {
    IDENTICAL_DOCKET = 'Identical Docket',
    DOES_NOT_BAR_US = 'Does Not Bar Us',
}

export enum USState {
    AL = 'Alabama',
    AK = 'Alaska',
    AS = 'American Samoa',
    AZ = 'Arizona',
    AR = 'Arkansas',
    CA = 'California',
    CO = 'Colorado',
    CT = 'Connecticut',
    DE = 'Delaware',
    DC = 'District Of Columbia',
    FM = 'Federated Names Of Micronesia',
    FL = 'Florida',
    GA = 'Georgia',
    GU = 'Guam',
    HI = 'Hawaii',
    ID = 'Idaho',
    IL = 'Illinois',
    IN = 'Indiana',
    IA = 'Iowa',
    KS = 'Kansas',
    KY = 'Kentucky',
    LA = 'Louisiana',
    ME = 'Maine',
    MH = 'Marshall Islands',
    MD = 'Maryland',
    MA = 'Massachusetts',
    MI = 'Michigan',
    MN = 'Minnesota',
    MS = 'Mississippi',
    MO = 'Missouri',
    MT = 'Montana',
    NE = 'Nebraska',
    NV = 'Nevada',
    NH = 'New Hampshire',
    NJ = 'New Jersey',
    NM = 'New Mexico',
    NY = 'New York',
    NC = 'North Carolina',
    ND = 'North Dakota',
    MP = 'Northern Mariana Islands',
    OH = 'Ohio',
    OK = 'Oklahoma',
    OR = 'Oregon',
    PW = 'Palau',
    PA = 'Pennsylvania',
    PR = 'Puerto Rico',
    RI = 'Rhode Island',
    SC = 'South Carolina',
    SD = 'South Dakota',
    TN = 'Tennessee',
    TX = 'Texas',
    UT = 'Utah',
    VT = 'Vermont',
    VI = 'Virgin Islands',
    VA = 'Virginia',
    WA = 'Washington',
    WV = 'West Virginia',
    WI = 'Wisconsin',
    WY = 'Wyoming',
}

export const statesDictionary = Object.fromEntries(Object.entries(USState));

export enum Country {
    afghanistan = 'Afghanistan',
    ålandIslands = 'Åland Islands',
    albania = 'Albania',
    algeria = 'Algeria',
    americanSamoa = 'American Samoa',
    andorra = 'Andorra',
    angola = 'Angola',
    anguilla = 'Anguilla',
    antarctica = 'Antarctica',
    antiguaAndBarbuda = 'Antigua and Barbuda',
    argentina = 'Argentina',
    armenia = 'Armenia',
    aruba = 'Aruba',
    australia = 'Australia',
    austria = 'Austria',
    azerbaijan = 'Azerbaijan',
    bahamas = 'Bahamas',
    bahrain = 'Bahrain',
    bangladesh = 'Bangladesh',
    barbados = 'Barbados',
    belarus = 'Belarus',
    belgium = 'Belgium',
    belize = 'Belize',
    benin = 'Benin',
    bermuda = 'Bermuda',
    bhutan = 'Bhutan',
    bolivia = 'Bolivia',
    bosniaAndHerzegovina = 'Bosnia and Herzegovina',
    botswana = 'Botswana',
    bouvetIsland = 'Bouvet Island',
    brazil = 'Brazil',
    britishIndianOceanTerritory = 'British Indian Ocean Territory',
    bruneiDarussalam = 'Brunei Darussalam',
    bulgaria = 'Bulgaria',
    burkinaFaso = 'Burkina Faso',
    burundi = 'Burundi',
    cambodia = 'Cambodia',
    cameroon = 'Cameroon',
    canada = 'Canada',
    capeVerde = 'Cape Verde',
    caymanIslands = 'Cayman Islands',
    centralAfricanRepublic = 'Central African Republic',
    chad = 'Chad',
    chile = 'Chile',
    china = 'China',
    christmasIsland = 'Christmas Island',
    cocosKeelingIslands = 'Cocos (Keeling) Islands',
    colombia = 'Colombia',
    comoros = 'Comoros',
    congo = 'Congo',
    congoTheDemocraticRepublicOfThe = 'Congo, The Democratic Republic of the',
    cookIslands = 'Cook Islands',
    costaRica = 'Costa Rica',
    coteDIvoire = "Cote D'Ivoire",
    croatia = 'Croatia',
    cuba = 'Cuba',
    cyprus = 'Cyprus',
    czechRepublic = 'Czech Republic',
    denmark = 'Denmark',
    djibouti = 'Djibouti',
    dominica = 'Dominica',
    dominicanRepublic = 'Dominican Republic',
    ecuador = 'Ecuador',
    egypt = 'Egypt',
    elSalvador = 'El Salvador',
    equatorialGuinea = 'Equatorial Guinea',
    eritrea = 'Eritrea',
    estonia = 'Estonia',
    ethiopia = 'Ethiopia',
    falklandIslandsMalvinas = 'Falkland Islands (Malvinas)',
    faroeIslands = 'Faroe Islands',
    fiji = 'Fiji',
    finland = 'Finland',
    france = 'France',
    frenchGuiana = 'French Guiana',
    frenchPolynesia = 'French Polynesia',
    frenchSouthernTerritories = 'French Southern Territories',
    gabon = 'Gabon',
    gambia = 'Gambia',
    georgia = 'Georgia',
    germany = 'Germany',
    ghana = 'Ghana',
    gibraltar = 'Gibraltar',
    greece = 'Greece',
    greenland = 'Greenland',
    grenada = 'Grenada',
    guadeloupe = 'Guadeloupe',
    guam = 'Guam',
    guatemala = 'Guatemala',
    guernsey = 'Guernsey',
    guinea = 'Guinea',
    guineaBissau = 'Guinea-Bissau',
    guyana = 'Guyana',
    haiti = 'Haiti',
    heardIslandAndMcdonaldIslands = 'Heard Island and Mcdonald Islands',
    holySeeVaticanCityState = 'Holy See (Vatican City State)',
    honduras = 'Honduras',
    hongKong = 'Hong Kong',
    hungary = 'Hungary',
    iceland = 'Iceland',
    india = 'India',
    indonesia = 'Indonesia',
    iranIslamicRepublicOf = 'Iran, Islamic Republic Of',
    iraq = 'Iraq',
    ireland = 'Ireland',
    isleOfMan = 'Isle of Man',
    israel = 'Israel',
    italy = 'Italy',
    jamaica = 'Jamaica',
    japan = 'Japan',
    jersey = 'Jersey',
    jordan = 'Jordan',
    kazakhstan = 'Kazakhstan',
    kenya = 'Kenya',
    kiribati = 'Kiribati',
    koreaDemocraticPeopleSRepublicOf = "Korea, Democratic People's Republic of",
    koreaRepublicOf = 'Korea, Republic of',
    kuwait = 'Kuwait',
    kyrgyzstan = 'Kyrgyzstan',
    laoPeopleSDemocraticRepublic = "Lao People's Democratic Republic",
    latvia = 'Latvia',
    lebanon = 'Lebanon',
    lesotho = 'Lesotho',
    liberia = 'Liberia',
    libyanArabJamahiriya = 'Libyan Arab Jamahiriya',
    liechtenstein = 'Liechtenstein',
    lithuania = 'Lithuania',
    luxembourg = 'Luxembourg',
    macao = 'Macao',
    macedoniaTheFormerYugoslavRepublicOf = 'Macedonia, The Former Yugoslav Republic of',
    madagascar = 'Madagascar',
    malawi = 'Malawi',
    malaysia = 'Malaysia',
    maldives = 'Maldives',
    mali = 'Mali',
    malta = 'Malta',
    marshallIslands = 'Marshall Islands',
    martinique = 'Martinique',
    mauritania = 'Mauritania',
    mauritius = 'Mauritius',
    mayotte = 'Mayotte',
    mexico = 'Mexico',
    micronesiaFederatedStatesOf = 'Micronesia, Federated States of',
    moldovaRepublicOf = 'Moldova, Republic of',
    monaco = 'Monaco',
    mongolia = 'Mongolia',
    montserrat = 'Montserrat',
    morocco = 'Morocco',
    mozambique = 'Mozambique',
    myanmar = 'Myanmar',
    namibia = 'Namibia',
    nauru = 'Nauru',
    nepal = 'Nepal',
    netherlands = 'Netherlands',
    netherlandsAntilles = 'Netherlands Antilles',
    newCaledonia = 'New Caledonia',
    newZealand = 'New Zealand',
    nicaragua = 'Nicaragua',
    niger = 'Niger',
    nigeria = 'Nigeria',
    niue = 'Niue',
    norfolkIsland = 'Norfolk Island',
    northernMarianaIslands = 'Northern Mariana Islands',
    norway = 'Norway',
    oman = 'Oman',
    pakistan = 'Pakistan',
    palau = 'Palau',
    palestinianTerritoryOccupied = 'Palestinian Territory, Occupied',
    panama = 'Panama',
    papuaNewGuinea = 'Papua New Guinea',
    paraguay = 'Paraguay',
    peru = 'Peru',
    philippines = 'Philippines',
    pitcairn = 'Pitcairn',
    poland = 'Poland',
    portugal = 'Portugal',
    puertoRico = 'Puerto Rico',
    qatar = 'Qatar',
    reunion = 'Reunion',
    romania = 'Romania',
    russianFederation = 'Russian Federation',
    rwanda = 'RWANDA',
    saintHelena = 'Saint Helena',
    saintKittsAndNevis = 'Saint Kitts and Nevis',
    saintLucia = 'Saint Lucia',
    saintPierreAndMiquelon = 'Saint Pierre and Miquelon',
    saintVincentAndTheGrenadines = 'Saint Vincent and the Grenadines',
    samoa = 'Samoa',
    sanMarino = 'San Marino',
    saoTomeAndPrincipe = 'Sao Tome and Principe',
    saudiArabia = 'Saudi Arabia',
    senegal = 'Senegal',
    serbiaAndMontenegro = 'Serbia and Montenegro',
    seychelles = 'Seychelles',
    sierraLeone = 'Sierra Leone',
    singapore = 'Singapore',
    slovakia = 'Slovakia',
    slovenia = 'Slovenia',
    solomonIslands = 'Solomon Islands',
    somalia = 'Somalia',
    southAfrica = 'South Africa',
    southGeorgiaAndTheSouthSandwichIslands = 'South Georgia and the South Sandwich Islands',
    spain = 'Spain',
    sriLanka = 'Sri Lanka',
    sudan = 'Sudan',
    suriname = 'Suriname',
    svalbardAndJanMayen = 'Svalbard and Jan Mayen',
    swaziland = 'Swaziland',
    sweden = 'Sweden',
    switzerland = 'Switzerland',
    syrianArabRepublic = 'Syrian Arab Republic',
    taiwanProvinceOfChina = 'Taiwan, Province of China',
    tajikistan = 'Tajikistan',
    tanzaniaUnitedRepublicOf = 'Tanzania, United Republic of',
    thailand = 'Thailand',
    timorLeste = 'Timor-Leste',
    togo = 'Togo',
    tokelau = 'Tokelau',
    tonga = 'Tonga',
    trinidadAndTobago = 'Trinidad and Tobago',
    tunisia = 'Tunisia',
    turkey = 'Turkey',
    turkmenistan = 'Turkmenistan',
    turksAndCaicosIslands = 'Turks and Caicos Islands',
    tuvalu = 'Tuvalu',
    uganda = 'Uganda',
    ukraine = 'Ukraine',
    unitedArabEmirates = 'United Arab Emirates',
    unitedKingdom = 'United Kingdom',
    unitedStates = 'United States',
    unitedStatesMinorOutlyingIslands = 'United States Minor Outlying Islands',
    uruguay = 'Uruguay',
    uzbekistan = 'Uzbekistan',
    vanuatu = 'Vanuatu',
    venezuela = 'Venezuela',
    vietNam = 'Viet Nam',
    virginIslandsBritish = 'Virgin Islands, British',
    virginIslandsUS = 'Virgin Islands, U.S.',
    wallisAndFutuna = 'Wallis and Futuna',
    westernSahara = 'Western Sahara',
    yemen = 'Yemen',
    zambia = 'Zambia',
    zimbabwe = 'Zimbabwe',
}

export const countriesDictionary = Object.fromEntries(Object.entries(Country));
