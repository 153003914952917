import useStaticColumns from '@/hooks/useStaticColumns';
import { useEffect, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { D_MANAGER_SOURCE } from '../../constants';
import { EnrichedLead, ReferencedValue, UpdateLeadCallback } from '../../types/Types';
import { getUpdatedValue, isReferencedValueKey } from '../../utils';
import { SourceTooltip } from '../SourceTooltip';
import { StyledInputEditor } from './StyledInputEditor';
import { useIsEditable } from './hooks/useIsEditable';

const NumberEditor = ({
    row,
    column,
    value: initialValue,
    previewMode,
    disabled,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    activeUserId?: string;
    value?: number | ReferencedValue<number>;
    previewMode?: boolean;
    disabled?: boolean;
    updateLead: UpdateLeadCallback;
}) => {
    const enabled = useIsEditable(row, column);
    const [value, setValue] = useState<number | undefined | ReferencedValue<number>>(
        initialValue === null || initialValue === undefined ? undefined : initialValue,
    );
    const {
        original: { id: rowId },
    } = row;
    const { id: field } = column;

    const { data: staticColumns } = useStaticColumns();

    const onBlur = () => {
        //TODO: support keyword ENTER
        if (!!field && value !== initialValue) {
            const updatedValue = getUpdatedValue(
                isReferencedValueKey(staticColumns, field) ? { value } : value,
                field,
                row.original,
            );
            updateLead(rowId, field, updatedValue, initialValue);
        }
    };

    useEffect(() => {
        setValue(initialValue === null || initialValue === undefined ? undefined : initialValue);
    }, [initialValue, row.original.id]);

    const displayNumber =
        typeof value === 'number'
            ? value !== undefined
                ? Number(value).toLocaleString()
                : ''
            : value?.value !== undefined
            ? Number(value.value).toLocaleString()
            : '';

    const style = previewMode ? { color: 'black', fontSize: 'inherit' } : {};

    return (
        <div style={{ display: 'flex', alignItems: 'end' }}>
            <StyledInputEditor
                style={{ ...style, backgroundColor: 'transparent' }}
                previewMode={previewMode}
                disabled={!enabled || disabled}
                value={displayNumber}
                onBlur={onBlur}
                onChange={e => {
                    const newValue = parseInt(e.target.value.replace(/,/g, ''));
                    setValue(newValue || undefined);
                }}
            />

            {value &&
            typeof value !== 'number' &&
            value.value &&
            value.source &&
            value.source !== D_MANAGER_SOURCE ? (
                <SourceTooltip value={value} />
            ) : null}
        </div>
    );
};

export default NumberEditor;
