import useDropReasons from '../../../hooks/useDropReasons';
import CreatableMultiSelect, { CreatableMultiSelectProps } from './CreatableMultiSelect';

type DropReasonsMultiSelectProps = Partial<CreatableMultiSelectProps> & { border?: boolean };

const DropReasonsMultiSelect = ({
    value,
    onChange,
    border,
    ...props
}: DropReasonsMultiSelectProps) => {
    const { data: dropReasons } = useDropReasons();

    const formattedOptions: { label: string; value: string }[] = [];

    dropReasons?.forEach(reason =>
        formattedOptions.push({
            label: reason.title,
            value: reason._id,
        }),
    );

    return (
        <CreatableMultiSelect
            options={formattedOptions}
            value={value}
            onChange={onChange}
            withCheckbox
            border={border}
            menuPlacement="auto"
            {...props}
        />
    );
};
export default DropReasonsMultiSelect;
