import {
    SingleSelect as DsSingleSelect,
    SingleSelectProps as SingleSelectPropsType,
} from '@darrow-ai/darrow-design';

type SingleSelectProps = Partial<SingleSelectPropsType> & {
    value: any;
    options: { label: string; value: any }[];
    disabled?: boolean;
    // onChange: (newValue: { label: string; value: any }) => void;
    onChange: any;
    border?: boolean;
};

export const SingleSelect = ({
    value,
    options,
    disabled,
    onChange,
    border,
    ...props
}: SingleSelectProps) => {
    return (
        <DsSingleSelect
            isDisabled={disabled}
            value={value}
            options={options}
            onChange={onChange}
            classNamePrefix="react-select"
            border={border}
            {...props}
        >
            <DsSingleSelect.Control />
            <DsSingleSelect.MenuList />
            <DsSingleSelect.Option />
            <DsSingleSelect.DropdownIndicator />
        </DsSingleSelect>
    );
};
