import {
    CreatableMultiSelectProps as CreatableMultiSelectPropsType,
    CreatableMultiSelect as DsCreatableMultiSelect,
} from '@darrow-ai/darrow-design';

export type CreatableMultiSelectProps = Partial<CreatableMultiSelectPropsType> & {
    value: any;
    options: { label: string; value: any }[];
    disabled?: boolean;
    // onChange: (newValue: { label: string; value: any }) => void;
    onChange: any;
    border?: boolean;
};

const CreatableMultiSelect = ({
    value,
    options,
    disabled,
    onChange,
    border,
    ...props
}: CreatableMultiSelectProps) => {
    return (
        <DsCreatableMultiSelect
            isDisabled={disabled}
            value={value}
            options={options}
            onChange={onChange}
            classNamePrefix="react-select"
            border={border}
            {...props}
        >
            <DsCreatableMultiSelect.Control />

            <DsCreatableMultiSelect.MultiValueContainer />
            <DsCreatableMultiSelect.MultiValueLabel />
            <DsCreatableMultiSelect.MultiValueRemove />

            <DsCreatableMultiSelect.MenuList />

            <DsCreatableMultiSelect.Option />

            <DsCreatableMultiSelect.DropdownIndicator />
        </DsCreatableMultiSelect>
    );
};

export default CreatableMultiSelect;
