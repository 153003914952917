import _ from 'lodash';
import { useContext } from 'react';

import { FilterContext } from '../../providers/FilterProvider';
import { PageContext } from '../../providers/PageProvider';
import { getFilterValueLabel, isNullUndefinedOrEmpty } from '../../utils';
import { Listbox } from '../BasicComponents/Listbox/Listbox';
import { ItemData } from '../BasicComponents/Listbox/ListboxItem';

interface FilterDropDownProps {
    name: string;
    displayName: string;
    checkedValues: Set<string | number>;
    values: any[];
    isLoading: boolean;
}

export const FilterDropDown = ({
    name,
    displayName,
    values = [],
    checkedValues,
    isLoading,
}: FilterDropDownProps) => {
    const { saveFilterValue, removeFilterValue } = useContext(FilterContext);

    const { setPageIndex } = useContext(PageContext);

    const onChange = (item: ItemData) => {
        const value = item.value;

        if (checkedValues.has(value)) {
            removeFilterValue({ accessor: name, displayName, value });
        } else {
            saveFilterValue({ accessor: name, displayName, value });
        }

        setPageIndex(0);
    };

    const options = values.map<ItemData>(value => ({
        value,
        label: getFilterValueLabel(value, name),
        selected: checkedValues.has(value),
    }));

    const sortedOptions = [
        ...options.filter(({ value }) => isNullUndefinedOrEmpty(value)),
        ..._.orderBy(
            options.filter(({ value }) => !isNullUndefinedOrEmpty(value)),
            'value',
            'asc',
        ),
    ];

    return (
        <Listbox
            searchable={true}
            items={sortedOptions}
            onItemSelected={onChange}
            isLoading={isLoading}
        />
    );
};
