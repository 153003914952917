import { THEME } from '@/theme';
import { IconButton } from '@darrow-ai/darrow-design';
import styled, { css } from 'styled-components';

import { CELL_WIDTH } from '../../constants';

export const StyledThead = styled.thead`
    tr th:first-child {
        width: 1rem;
        padding: 0;
        border: none;
    }
`;

export const frozenHeaderStyle = css`
    width: ${CELL_WIDTH}rem;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledTh = styled.th<{ frozen?: boolean; sorted?: boolean }>`
    border-right: 1px solid #fdfdfd;
    color: ${props => (props.sorted ? '#6e80f6' : '#31353e')};
    text-align: left;
    padding: 1.25rem 0.75rem 1.25rem;
    position: sticky;
    top: 0px;
    background-color: ${THEME.COLORS.GRAY.LIGHT};
    // react-table-sticky add z-index:3 to the element.style, and it collides with the header z-index
    // so the !important is necessary
    z-index: ${props => (props.frozen ? '4 !important' : '1')};

    &:nth-child(n + 3) {
        min-width: ${CELL_WIDTH}rem;
    }

    ${props => props.frozen && frozenHeaderStyle}

    .sort-icon path {
        fill: #6e80f6 !important;
    }
`;

export const StyledActionTh = styled(StyledTh)`
    border-right: 0;
    left: 0;
    padding: 0.75rem 1.5rem;
    z-index: 3;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: inherit;
    overflow: inherit;
    text-overflow: inherit;

    & > div {
        overflow: inherit;
        text-overflow: inherit;
    }
`;

export const PinIconButton = styled(IconButton)`
    height: 0.75rem;
    width: 0.75rem;
    flex: 0 0 auto;

    &[name='Pin'] {
        color: #282a34;
    }

    &[name='Unpin'] {
        color: #7a808d;
    }
`;

export const RotateDiv = styled.div<{ deg: number }>`
    transform: rotate(${props => props.deg}deg);

    svg path {
        fill: #282a34;
    }
`;
