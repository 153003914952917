import { useSavedValue } from '@/hooks/useSavedValue';
import { ReactNode, createContext } from 'react';

interface SortContextProps {
    children: ReactNode;
}

type SavedSortItem = {
    accessor: string;
    isSortedDesc?: boolean;
};

interface SortContextType {
    sortedBy: SavedSortItem;
    handleSort: (accessor: string, rotate?: boolean) => void;
}

const defaultState: SortContextType = {
    sortedBy: { accessor: '' },
    handleSort: () => {},
};

export const SortContext = createContext(defaultState);
SortContext.displayName = 'SortContext';

export const SortProvider = ({ children }: SortContextProps) => {
    const [sortedBy, setSortedBy] = useSavedValue<SavedSortItem>('sort-by', {
        accessor: '',
    });

    const isSortedDesc = (accessor: string) => {
        if (sortedBy.accessor !== accessor) {
            return true;
        } else if (sortedBy.isSortedDesc === true) {
            return false;
        } else if (sortedBy.isSortedDesc === false) {
            return undefined;
        }

        return true;
    };

    const handleSort = (accessor: string, rotate: boolean = true) => {
        const desc = rotate ? isSortedDesc(accessor) : sortedBy.isSortedDesc;
        const updatedValue = {
            accessor: desc === undefined ? '' : accessor,
            isSortedDesc: desc,
        };
        setSortedBy(updatedValue);
    };

    const value = { sortedBy, handleSort };

    return <SortContext.Provider value={value}>{children}</SortContext.Provider>;
};
