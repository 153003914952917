import { useEffect, useMemo, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import useAssignees from '../../hooks/useAssignees';
import { Assignee, EnrichedLead, UpdateLeadCallback } from '../../types/Types';
import DropDownEditor from './DropDownEditor';
import { useIsEditable } from './hooks/useIsEditable';

const AssigneeEditor = ({
    row,
    column,
    previewMode,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    previewMode: boolean;
    updateLead: UpdateLeadCallback;
}) => {
    const isEditable = useIsEditable(row, column);
    const { data: assignees } = useAssignees();
    const assigneesOptions = useMemo(
        () =>
            assignees?.map((user: Assignee) => ({
                label: user.fullName,
                value: user.userId,
            })) || [],
        [assignees],
    );
    const [assignee, setAssignee] = useState(() =>
        assigneesOptions.find(option => option.value === row.original.assignee?.userId),
    );
    const { id: field } = column;

    const updateAssignee = (user?: { value: string; label: string }) => {
        if (!!field)
            updateLead(
                row.original.id,
                'assigneeId',
                user?.value ?? null,
                row.original.assignee?.userId,
            );
    };

    useEffect(() => {
        setAssignee(
            assigneesOptions.find(option => option.value === row.original.assignee?.userId),
        );
    }, [assigneesOptions, row.original]);

    return (
        <DropDownEditor
            value={assignee}
            options={assigneesOptions}
            previewMode={previewMode}
            updateValue={userId => {
                updateAssignee(userId);
                setAssignee(assigneesOptions.find(user => user.value === userId));
            }}
            disabled={!isEditable}
            isClearable
        />
    );
};

export default AssigneeEditor;
