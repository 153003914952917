import { TableProfileContext } from '@/providers/TableProfileProvider';
import { useContext } from 'react';
import { useLocalStorage } from 'react-use';

export const useSavedValue = <T>(
    key: string,
    defaultValue: T,
): [T, React.Dispatch<React.SetStateAction<T | undefined>>] => {
    const { tableProfile } = useContext(TableProfileContext);
    const [value, setValue] = useLocalStorage<T>(`${tableProfile}-${key}`, defaultValue);

    const savedValue = value ?? defaultValue;
    const setSavedValue = setValue;

    return [savedValue, setSavedValue];
};
