import { useEffect, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { EnrichedLead, UpdateLeadCallback } from '../../types/Types';
import { getUpdatedValue } from '../../utils';
import { StyledDatePicker } from '../BasicComponents/DatePicker/StyledDatePicker';
import { useIsEditable } from './hooks/useIsEditable';

const DateEditor = ({
    row,
    column,
    value: initialValue,
    previewMode,
    disabled,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    value?: Date;
    previewMode?: boolean;
    disabled?: boolean;
    updateLead: UpdateLeadCallback;
}) => {
    const isEditable = useIsEditable(row, column);
    const [value, setValue] = useState<Date | null | undefined>(
        initialValue && new Date(initialValue),
    );

    const {
        original: { id: leadId },
    } = row;
    const { id: field } = column;

    const onChange = (date: Date | null) => {
        if (!!field) {
            const updatedValue = getUpdatedValue(date, field, row.original);
            updateLead(leadId, field, updatedValue, initialValue && new Date(initialValue));
            setValue(date);
        }
    };

    const placeHolderText = isEditable && !previewMode ? 'Select Date' : '';

    useEffect(() => {
        setValue(initialValue && new Date(initialValue));
    }, [initialValue, row.original.id]);

    return (
        <StyledDatePicker
            previewMode={previewMode}
            placeholderText={placeHolderText}
            disabled={!isEditable || disabled}
            selected={value}
            dateFormat={'dd/MM/yyyy'}
            onChange={onChange}
        />
    );
};

export default DateEditor;
