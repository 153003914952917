import Badge from '../components/BasicComponents/Badge/Badge';
import { StyledDropReasonColumn } from './StyledDropReasonColumn';

interface DropReasonsProps {
    value?: { title: string; _id: string }[];
}

export const DropReasonColumn = ({ value: dropReasons }: DropReasonsProps) => {
    return (
        <StyledDropReasonColumn>
            <div style={{ display: 'flex' }}>
                {Array.isArray(dropReasons) &&
                    dropReasons.map((reason, i) => {
                        if (reason) {
                            return (
                                <Badge key={i} isMulti>
                                    {reason.title}
                                </Badge>
                            );
                        }
                        return null;
                    })}
            </div>
        </StyledDropReasonColumn>
    );
};
