import { SortContext } from '@/providers/SortProvider';
import { Icon, Tooltip, Typography } from '@darrow-ai/darrow-design';
import { CaretDown, CaretUp } from '@styled-icons/fluentui-system-filled';
import { ArrowDropDown } from '@styled-icons/material-rounded';
import { useContext, useRef } from 'react';
import { HeaderGroup } from 'react-table';

import { ColumnsContext, ColumnsContextType } from '../../providers/ColumnsProvider';
import { EnrichedLead, LeadsTableColumn } from '../../types/Types';
import HeaderActions from '../HeaderActions/HeaderActions';
import {
    HeaderContainer,
    PinIconButton,
    StyledActionTh,
    StyledTh,
    StyledThead,
} from './LeadsTableHeader.styles';

export interface HeaderProps {
    headerGroups: HeaderGroup<EnrichedLead>[];
    previewMode: boolean;
}

export const LeadsTableHeader = ({ headerGroups, previewMode }: HeaderProps) => {
    const { reorderColumns, setColumnSticky } = useContext(ColumnsContext) as ColumnsContextType;
    const { sortedBy, handleSort } = useContext(SortContext);

    const dragged = useRef<string>();

    const onDragStart = (e: any, Header?: any) => {
        e.stopPropagation();
        dragged.current = Header;
    };

    const onDragOver = (e: any) => {
        e.preventDefault();
    };

    const onDrop = (e: any, Header?: any) => {
        e.preventDefault();
        reorderColumns(dragged.current, Header);
        dragged.current = undefined;
    };

    return (
        <StyledThead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => {
                        const headerProps = column.getHeaderProps();

                        if (previewMode) {
                            return (
                                <StyledTh {...headerProps} key={index}>
                                    <HeaderContainer>
                                        <div>{column.render('Header')}</div>
                                    </HeaderContainer>
                                </StyledTh>
                            );
                        }

                        if (column.id === 'selection') {
                            return (
                                <StyledTh
                                    {...headerProps}
                                    key={index}
                                    style={{
                                        zIndex: 4,
                                        position: 'sticky',
                                        left: 0,
                                    }}
                                >
                                    {column.render('Header')}
                                </StyledTh>
                            );
                        }

                        if (column.id === 'numbers') {
                            return (
                                <StyledActionTh
                                    colSpan={2}
                                    key={index}
                                    style={{ zIndex: 4, left: '1.4rem' }}
                                >
                                    <HeaderActions />
                                </StyledActionTh>
                            );
                        }

                        if (column.id === 'spacer') {
                            return null;
                        }

                        const HeaderContent = (
                            <HeaderContainer>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: 5 }}>{column.render('Header')}</div>

                                    {column.id === sortedBy.accessor ? (
                                        sortedBy.isSortedDesc ? (
                                            <CaretDown size={16} className="sort-icon" />
                                        ) : (
                                            <CaretUp size={16} className="sort-icon" />
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <PinIconButton
                                    //@ts-ignore
                                    name={column.sticky ? 'Pin' : 'Unpin'}
                                    onClick={e => {
                                        e.stopPropagation();
                                        return setColumnSticky(
                                            column,
                                            //@ts-ignore
                                            !column.sticky,
                                        );
                                    }}
                                />
                            </HeaderContainer>
                        );

                        const properlyTypedColumn = column as unknown as LeadsTableColumn;

                        return (
                            <StyledTh
                                {...headerProps}
                                onClick={() => handleSort && handleSort(column.id)}
                                key={index}
                                draggable
                                onDragStart={e => onDragStart(e, column.Header)}
                                onDragOver={onDragOver}
                                onDrop={e => onDrop(e, column.Header)}
                                //@ts-ignore
                                frozen={column.sticky}
                                sorted={column.id === sortedBy.accessor}
                            >
                                {properlyTypedColumn.isCoaSection &&
                                properlyTypedColumn.coas.length > 1 ? (
                                    <Tooltip>
                                        <Tooltip.Trigger asChild>{HeaderContent}</Tooltip.Trigger>
                                        <Tooltip.Content side="top" sideOffset={5}>
                                            <Typography
                                                style={{ color: 'white', fontSize: '1rem' }}
                                            >
                                                {properlyTypedColumn.coas
                                                    .map(({ name }) => name)
                                                    .join(', ')}
                                            </Typography>

                                            <Tooltip.Arrow />
                                        </Tooltip.Content>
                                    </Tooltip>
                                ) : (
                                    HeaderContent
                                )}
                            </StyledTh>
                        );
                    })}
                </tr>
            ))}
        </StyledThead>
    );
};
