import { THEME } from '@/theme';
import { Icon, IconNameType } from '@darrow-ai/darrow-design';
import styled from 'styled-components';

interface PopoverHeaderProps {
    iconName: IconNameType;
    text: string;
}

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 0.4rem;
    border-bottom: 1px solid ${THEME.COLORS.GRAY.LIGHT};

    path {
        fill: #373b68;
    }

    & > * {
        margin-right: 0.25rem;
    }

    & :last-child {
        margin-right: 0;
    }
`;

const PopoverHeader = ({ iconName, text }: PopoverHeaderProps) => {
    return (
        <StyledContainer>
            <Icon
                name={iconName}
                style={
                    iconName === 'Plus'
                        ? { color: '#373b68', height: '2rem', width: '2rem' }
                        : { color: '#373b68', height: '1rem', width: '1rem' }
                }
            />
            <span
                style={{
                    fontSize: '1rem',
                    color: '#373B68',
                    fontWeight: 400,
                }}
            >
                {text}
            </span>
        </StyledContainer>
    );
};

export default PopoverHeader;
