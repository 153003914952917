import { useUpdateLead } from '@/components/LeadsTable/hooks/useUpdateLead';
import useStaticColumns from '@/hooks/useStaticColumns';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useContext, useState } from 'react';

import TooltipIconButton from '@components/BasicComponents/TooltipIconButton';

import { SERVER_URL } from '../../../constants';
import useCoaSectionEnrichment from '../../../hooks/useSelfServiceEnrichmentOptions';
import { AccessTokenContext } from '../../../providers/AccessTokenProvider';
import { SelfServiceEnrichmentOption } from '../../../types/Types';
import { LeadStatus } from '../../../types/enums';
import RippleLoader from '../../BasicComponents/Loaders/RippleLoader';
import Error from '../Error';
import { Dialog, DialogContent, DialogTrigger, StyledDialogTitle } from '../StyledDialog';
import { Body } from './Body';
import { ConfirmationContent } from './ConfirmationContent';

type BulkSelfServiceEnrichmentDialogProps = {
    filtersQuery: string;
};

export const BulkSelfServiceEnrichmentDialog = ({
    filtersQuery,
}: BulkSelfServiceEnrichmentDialogProps) => {
    const { user } = useAuth0();
    const { accessToken } = useContext(AccessTokenContext);
    const { data, isLoading } = useCoaSectionEnrichment();
    const { data: columns } = useStaticColumns();
    const { bulkUpdateLeads } = useUpdateLead();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<SelfServiceEnrichmentOption | undefined>();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isRequestLoading, setIsRequestLoading] = useState(false);

    const onOpenChange = (open: boolean) => {
        setSelectedOption(undefined);
        setIsSubmitted(false);
        setIsOpen(open);
        setIsError(false);
    };

    const onChange = (newOption: { label: string; value: SelfServiceEnrichmentOption }) =>
        setSelectedOption(newOption.value);

    const onClick = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const body = {
            userEmail: user?.email,
            selectedEnrichment: selectedOption?.name,
        };

        setIsRequestLoading(true);
        try {
            const { status } = await axios.post(
                `${SERVER_URL}/enrichmentRequest?${filtersQuery}`,
                body,
                config,
            );

            await bulkUpdateLeads(
                filtersQuery,
                columns.STATUS.ACCESSOR,
                LeadStatus.PENDING_ENRICHMENT,
            );

            setIsSubmitted(true);

            if (status !== 201) {
                setIsError(true);
            }
        } catch (err) {
            console.error(err);

            setIsSubmitted(true);
            setIsError(true);
        }
        setIsRequestLoading(false);
    };

    const Content = isSubmitted ? (
        isError ? (
            <Error />
        ) : (
            <ConfirmationContent />
        )
    ) : isLoading || isRequestLoading ? (
        <RippleLoader />
    ) : (
        <Body selectedOption={selectedOption} data={data} onClick={onClick} onChange={onChange} />
    );

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogTrigger asChild>
                <TooltipIconButton
                    style={{
                        width: 16,
                        height: 16,
                        marginRight: 12,
                        marginLeft: 12,
                    }}
                    iconName="Enrichment"
                    content="Enrich Leads"
                />
            </DialogTrigger>
            <DialogContent>
                <StyledDialogTitle title="Enrich Leads" withCloseButton />
                {Content}
            </DialogContent>
        </Dialog>
    );
};
