import { THEME } from '@/theme';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

type LoaderProps = HTMLAttributes<HTMLDivElement> & {
    withText?: boolean;
};

const Loader = styled.div`
    display: inline-block;
    position: relative;
    width: ${props => props.style?.width ?? '80px'};
    height: ${props => props.style?.height ?? '80px'};

    & div {
        position: absolute;
        border: ${props => (props.style?.height ? `calc(0.1*${props.style?.height})` : '4px')} solid
            ${props => props.style?.color ?? THEME.COLORS.PURPLE.DEFAULT};
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    & div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 45%;
            left: 45%;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 90%;
            height: 90%;
            opacity: 0;
        }
    }
`;

const RippleLoader = ({ withText = true, ...props }: LoaderProps) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Loader {...props}>
                <div></div>
                <div></div>
            </Loader>

            {withText ? (
                <span style={{ fontWeight: 'bold', fontSize: 24 }}>Loading...</span>
            ) : (
                <></>
            )}
        </div>
    );
};

export default RippleLoader;
