import { useState } from 'react';
import { Row, TableBodyProps } from 'react-table';

import { InvestigationPanelContext } from '../../providers/InvestigationPanelProvider';
import { EnrichedLead } from '../../types/Types';
import EmptyTableBody from './EmptyTableBody';
import { StyledTbody } from './LeadsTableBody.styles';
import TableRow from './TableRow';

export type BodyProps = {
    rows: Row<EnrichedLead>[];
    tableBodyProps: TableBodyProps;
    previewMode?: boolean;
    prepareRow: (row: Row<any>) => void;
    selectedRowIds?: any;
};

export const LeadsTableBody = ({
    rows,
    tableBodyProps,
    previewMode,
    prepareRow,
    selectedRowIds,
}: BodyProps) => {
    const [selectedLeadId, setSelectedLeadId] = useState<number>();

    return rows.length > 0 ? (
        <InvestigationPanelContext.Provider value={{ selectedLeadId, setSelectedLeadId }}>
            <StyledTbody {...tableBodyProps}>
                {rows.map(row => (
                    <TableRow
                        previewMode={previewMode}
                        key={row.id}
                        row={row}
                        prepareRow={prepareRow}
                        selectedRowIds={selectedRowIds}
                    />
                ))}
            </StyledTbody>
        </InvestigationPanelContext.Provider>
    ) : (
        <EmptyTableBody />
    );
};
