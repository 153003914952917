import { useRef, useState } from 'react';

import { PlusIcon } from '../../LeadsManager/StyledLeadManager';
import Error from '../Error';
import { Dialog, DialogContent, DialogHR, StyledDialogTitle } from '../StyledDialog';
import { AddSingleLead } from './AddSingleLead';
import { DownloadCSVTemplate } from './DownloadCSVTemplate';
import { ImportLeads } from './ImportLeads/ImportLeads';
import { ImportLeadsLink } from './ImportLeads/ImportLeadsLink';
import { StyledAddLeadsDialogTrigger } from './StyledAddLeadsDialog';

export const AddLeadsDialog = () => {
    const [error, setError] = useState(false);
    const [opened, setOpened] = useState(false);
    const [isImportLeadsDisplayed, setIsImportLeadsDisplayed] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const Content = isImportLeadsDisplayed ? (
        <>
            <StyledDialogTitle
                title="Add Multiple Leads File"
                withCloseButton
                handleClose={() => setError(false)}
                style={{ marginBottom: 10 }}
            />

            <ImportLeads
                ref={ref}
                closeDialog={() => {
                    setOpened(false);
                    setIsImportLeadsDisplayed(false);
                }}
            />
        </>
    ) : (
        <>
            <StyledDialogTitle
                title="Add Leads"
                withCloseButton
                handleClose={() => setError(false)}
            />

            <AddSingleLead
                onError={() => setError(true)}
                close={() => {
                    setOpened(false);
                    setIsImportLeadsDisplayed(false);
                }}
            />

            <DialogHR style={{ margin: '26px auto', width: '65%' }} />

            <ImportLeadsLink displayImportLeads={() => setIsImportLeadsDisplayed(true)} />

            <DownloadCSVTemplate />
        </>
    );

    return (
        <Dialog
            open={opened}
            onOpenChange={open => {
                setOpened(open);
                setIsImportLeadsDisplayed(false);
            }}
        >
            <StyledAddLeadsDialogTrigger>
                <PlusIcon name="Plus" />
            </StyledAddLeadsDialogTrigger>
            <DialogContent
                ref={ref}
                style={{
                    overflowY: 'auto',
                }}
            >
                <>{error ? <Error /> : Content}</>
            </DialogContent>
        </Dialog>
    );
};
