import { HTMLAttributes, ReactNode } from 'react';

import { ChipContainer } from './StyledChip';

// TODO: change this any type!!!!!!
interface ChipProps extends HTMLAttributes<any> {
    value?: string;
    children?: ReactNode;
}

//TODO: maybe this Chip component needs to be as part of the the Design System?
export const Chip = ({ value, children, ...props }: ChipProps) => {
    const content = children || value;

    return <ChipContainer {...props}>{content}</ChipContainer>;
};
