import { THEME } from '@/theme';
import { Combobox } from '@darrow-ai/comp-lib';
import styled from 'styled-components';

export const StyledComboboxInput = styled(Combobox.Input)`
    &::placeholder {
        font-weight: 550;
        color: ${THEME.COLORS.PURPLE.DEFAULT};
    }
`;

export const StyledComboboxInputContainer = styled(Combobox.InputContainer)<{ isOpen: boolean }>`
    border: 1px solid transparent;
    background-color: ${THEME.COLORS.PURPLE.MEDIUM};
    border-radius: 50px;
    cursor: ${props => (props.isOpen ? '' : 'pointer')};
    &:hover {
        border: 1px solid ${THEME.COLORS.PURPLE.DEFAULT};
    }
`;

export const StyledComboboxMenu = styled(Combobox.Menu)<{}>`
    background: linear-gradient(
            338.2deg,
            rgba(251, 249, 255, 0.7) -28.12%,
            rgba(255, 255, 255, 0.238) 83.1%
        ),
        linear-gradient(0deg, #ffffff, #ffffff);
    text-transform: capitalize;
`;
