import { LeadsTableBody, LeadsTableHeader, LeadsTablePagination } from '@/components/LeadsTable';
import {
    StyledContainer,
    StyledTable,
    StyledWrapper,
} from '@/components/LeadsTable/LeadsTable.styles';
import { DEFAULT_PAGE_SIZE } from '@/constants';
import { EnrichedLead, PreviewLeadsTableOptions } from '@/types/Types';
import {
    ScrollArea,
    ScrollAreaScrollbar,
    ScrollAreaThumb,
    ScrollAreaViewport,
} from '@radix-ui/react-scroll-area';
import {
    Column,
    TableInstance,
    TableState,
    UsePaginationInstanceProps,
    UsePaginationOptions,
    UsePaginationState,
    usePagination,
    useTable,
} from 'react-table';

export interface PreviewLeadsTableProps {
    data: Partial<EnrichedLead>[];
    columns: Column<Partial<EnrichedLead>>[];
}

export const PreviewLeadsTable = ({ data, columns = [] }: PreviewLeadsTableProps) => {
    const options: PreviewLeadsTableOptions & UsePaginationOptions<Partial<EnrichedLead>> = {
        columns,
        data,
        previewMode: true,
        initialState: { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE },
    };

    const instance = useTable<Partial<EnrichedLead>>(options, usePagination);

    const {
        headerGroups,
        getTableProps,
        getTableBodyProps,
        prepareRow,
        state: tableState,
        page,
        canNextPage,
        canPreviousPage,
        gotoPage,
        nextPage,
        pageCount,
        previousPage,
    } = instance as TableInstance<EnrichedLead> & UsePaginationInstanceProps<EnrichedLead>;

    const { pageSize, pageIndex } = tableState as TableState<EnrichedLead> &
        UsePaginationState<EnrichedLead>;

    const tableProps = getTableProps();
    const tableBodyProps = getTableBodyProps();

    return (
        <StyledWrapper>
            <div>
                <LeadsTablePagination
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    totalLeads={data.length}
                    pageSize={pageSize}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    gotoPage={gotoPage}
                />
            </div>

            <StyledContainer>
                <ScrollArea style={{ height: '100%' }}>
                    <ScrollAreaViewport>
                        <StyledTable {...tableProps}>
                            <LeadsTableHeader previewMode headerGroups={headerGroups} />
                            <LeadsTableBody
                                rows={page}
                                tableBodyProps={tableBodyProps}
                                prepareRow={prepareRow}
                                previewMode
                            />
                        </StyledTable>
                    </ScrollAreaViewport>
                    <ScrollAreaScrollbar orientation="vertical">
                        <ScrollAreaThumb />
                    </ScrollAreaScrollbar>
                    {data.length > 0 && (
                        <ScrollAreaScrollbar orientation="horizontal">
                            <ScrollAreaThumb />
                        </ScrollAreaScrollbar>
                    )}
                </ScrollArea>
            </StyledContainer>
        </StyledWrapper>
    );
};
