import { ProviderWrapper } from '@/providers/ProviderWrapper';
import { NavLink } from '@/types/Types';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@darrow-ai/darrow-design';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import LoginButton from '@components/LoginButton';
import SideNav from '@components/SideNav/SideNav';

import './App.css';
import RippleLoader from './BasicComponents/Loaders/RippleLoader';
import { Main } from './StyledApp';

const navigation: NavLink[] = [
    {
        name: 'D-Manager',
        id: 'D-Manager',
        href: '/',
        icon: 'FindOutline',
    },
];

function Layout() {
    const [isSideNavFull, setIsSideNavFull] = useState(false);

    const { isAuthenticated, isLoading, logout } = useAuth0();

    const secondaryNavigation: NavLink[] = [
        {
            name: 'Logout',
            id: 'Logout',
            href: `/`,
            icon: 'Logout',
            outsideLink: true,
            onClick: () => logout({ returnTo: window.location.origin }),
        },
    ];

    if (isLoading) {
        return <RippleLoader />;
    }

    return (
        <ProviderWrapper>
            <div>
                {!isAuthenticated ? (
                    <LoginButton />
                ) : (
                    <div style={{ display: 'flex' }}>
                        <SideNav
                            navLinks={navigation}
                            secondaryNavLinks={secondaryNavigation}
                            isSideNavFull={isSideNavFull}
                            setIsSideNavFull={setIsSideNavFull}
                        />

                        <Main isSideNavFull={isSideNavFull}>
                            <Typography
                                variant="span"
                                style={{
                                    textAlign: 'left',
                                    width: '90%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: '2.166em',
                                    color: '#282A34',
                                    fontWeight: 700,
                                    marginTop: '1rem',
                                    marginLeft: '4.333rem',
                                }}
                            >
                                <img
                                    src="/img/d-manager-icon.svg"
                                    alt="D-Manager icon"
                                    style={{
                                        marginRight: 10,
                                        height: 30,
                                        width: 30,
                                    }}
                                />
                                D-Manager
                            </Typography>
                            <Outlet />
                        </Main>
                    </div>
                )}
            </div>
        </ProviderWrapper>
    );
}

export default Layout;
