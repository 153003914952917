import { Popover } from '@darrow-ai/darrow-design';
import { FC, useState } from 'react';

import TooltipIconButton from '@components/BasicComponents/TooltipIconButton';

import SelectColumnContent from './SelectColumnContent';

const SelectColumnPopover: FC = () => {
    const [open, setOpen] = useState(false);

    return (
        <Popover.Root open={open} onOpenChange={setOpen}>
            <Popover.Trigger
                asChild
                style={{
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderRadius: 1,
                    display: 'flex',
                }}
            >
                <TooltipIconButton
                    iconName="Columns"
                    content="Select Columns"
                    style={{
                        width: '1rem',
                        height: '1rem',
                        color: '#373b68',
                    }}
                />
            </Popover.Trigger>
            <Popover.Portal>
                <SelectColumnContent open={open} />
            </Popover.Portal>
        </Popover.Root>
    );
};

export default SelectColumnPopover;
