import { SEARCH_TERM_KEY } from '@/constants';
import { EnrichedOrganization } from '@/types/Types';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { useOrganizations } from './useOrganizations';

export const useSearchedOrganizations = () => {
    const [searchParams] = useSearchParams();
    const urlState = searchParams.get(SEARCH_TERM_KEY) ?? '';

    const [searchedOrganizations, setSearchedOrganizations] = useState<EnrichedOrganization[]>([]);
    const [searchTerm, setSearchTerm] = useState(urlState);
    const [isLoading, setIsLoading] = useState(false);

    const { findOrganizationsWithLead } = useOrganizations();

    useDebounce(
        async () => {
            if (!searchTerm) {
                return;
            }

            const organizations = await findOrganizationsWithLead(searchTerm);
            setIsLoading(false);
            setSearchedOrganizations(organizations);
        },
        500,
        [searchTerm],
    );

    useEffect(() => {
        if (!searchTerm) {
            setSearchedOrganizations([]);
        } else {
            setIsLoading(true);
        }
    }, [searchTerm]);

    return { searchTerm, setSearchTerm, searchedOrganizations, isLoading };
};
