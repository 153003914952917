import useCountLeads from '@/hooks/useLeads/useCountLeads';
import useFetchLeads from '@/hooks/useLeads/useFetchLeads';
import { EnrichedLead } from '@/types/Types';

export default function useLeads(): {
    leads?: EnrichedLead[];
    isLeadsError: any;
    isLeadsLoading: boolean;
    currentLeadsKey: string;
    totalCount?: number;
    isTotalCountError: any;
    isTotalCountLoading: boolean;
    currentTotalCountKey: string;
} {
    const {
        data: leads,
        isError: isLeadsError,
        isLoading: isLeadsLoading,
        currentKey: currentLeadsKey,
    } = useFetchLeads();
    const {
        data: totalCount,
        isError: isTotalCountError,
        isLoading: isTotalCountLoading,
        currentKey: currentTotalCountKey,
    } = useCountLeads();

    return {
        leads,
        isLeadsError,
        isLeadsLoading,
        currentLeadsKey,
        totalCount,
        isTotalCountError,
        isTotalCountLoading,
        currentTotalCountKey,
    };
}
