import { StyledLink } from '@/components/BasicComponents/StyledLink';
import { Typography } from '@/components/BasicComponents/Typography';
import useLeads from '@/hooks/useLeads/useLeads';
import useLeadsQueryParams from '@/hooks/useLeads/useLeadsQueryParams';
import useStaticColumns from '@/hooks/useStaticColumns';
import { THEME } from '@/theme';
import { DropReason, EnrichedLead } from '@/types/Types';
import { pluralize } from '@/utils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Row } from 'react-table';
import styled from 'styled-components';

import BulkEditDialog from '../../Dialog/BulkEditDialog/BulkEditDialog';
import { BulkSelfServiceEnrichmentDialog } from '../../Dialog/BulkSelfServiceEnrichmentDialog/BulkSelfServiceEnrichmentDialog';
import IndeterminateCheckbox from '../IndeterminateCheckbox';
import BulkDropButton from './BulkDropButton';
import ExportButton from './ExportButton';

const StyledSelectionActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: fit-content;
    padding-top: 0.4rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    border-radius: 10px 10px 0 0;
    background-color: ${THEME.COLORS.GRAY.LIGHT};
    align-self: flex-end;
`;

type SelectionActionsProps = {
    selectedRowIds: Record<string, boolean>;
    selectedFlatRows: Row<EnrichedLead>[];
    getToggleAllRowsSelectedProps: any; // Type issues with 'IndeterminateCheckbox' component
    isAllLeadsSelected: boolean;
    setDropReasonDialog: Dispatch<
        SetStateAction<{
            open: boolean;
            updateDropReasons: (dropReasons: Partial<DropReason>[]) => any;
        }>
    >;
    selectAllLeads: () => void;
};

const SelectionActions = ({
    selectedRowIds,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
    isAllLeadsSelected,
    setDropReasonDialog,
    selectAllLeads,
}: SelectionActionsProps) => {
    const { data: columns } = useStaticColumns();
    const { totalCount } = useLeads();
    const { stringifyQueryParams, getCustomFiltersQuery } = useLeadsQueryParams();
    const [customFilterQuery, setCustomFilterQuery] = useState('');
    const [selectedRowsLength, setSelectedRowsLength] = useState(0);

    const filtersQuery = stringifyQueryParams({ filter: true });

    useEffect(() => {
        setCustomFilterQuery(
            isAllLeadsSelected && filtersQuery !== ''
                ? filtersQuery
                : getCustomFiltersQuery(selectedFlatRows, columns.LEAD_ID.ACCESSOR),
        );
    }, [
        filtersQuery,
        isAllLeadsSelected,
        getCustomFiltersQuery,
        selectedFlatRows,
        columns.LEAD_ID.ACCESSOR,
    ]);

    useEffect(() => {
        setSelectedRowsLength(
            isAllLeadsSelected ? totalCount ?? 0 : Object.keys(selectedRowIds).length,
        );
    }, [totalCount, isAllLeadsSelected, selectedRowIds]);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledSelectionActions>
                <IndeterminateCheckbox id="select-all-rows" {...getToggleAllRowsSelectedProps()} />

                <div
                    style={{
                        borderLeft: '1px solid #cccccc',
                        marginLeft: 8,
                    }}
                >
                    <div
                        style={{
                            marginLeft: 8,
                        }}
                    >
                        <BulkEditDialog filtersQuery={customFilterQuery} />

                        <ExportButton filtersQuery={customFilterQuery} />

                        <BulkDropButton
                            filtersQuery={customFilterQuery}
                            setDropReasonDialog={setDropReasonDialog}
                        />
                        <BulkSelfServiceEnrichmentDialog filtersQuery={customFilterQuery} />
                    </div>
                </div>
            </StyledSelectionActions>

            <Typography style={{ marginLeft: 15 }}>
                {pluralize(selectedRowsLength, 'Lead')} Selected
            </Typography>
            {!isAllLeadsSelected && (
                <StyledLink onClick={() => selectAllLeads()} style={{ marginLeft: 25 }}>
                    Select All {totalCount} Leads
                </StyledLink>
            )}
        </div>
    );
};

export default SelectionActions;
