import { AccessTokenContext } from '@/providers/AccessTokenProvider';
import axios from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';

import { SERVER_URL } from '../constants';
import { LeadStatusType } from '../types/Types';

const fetcher = ([url, token]: string[]) =>
    axios
        .get(`${SERVER_URL}${url}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => res.data);

export default function useLeadStatuses(): {
    data: LeadStatusType;
    isLoading: boolean;
    isError: any;
} {
    const { accessToken: token } = useContext(AccessTokenContext);

    const { data, error, isLoading } = useSWR(
        token ? ['/staticData/leadStatuses', token] : null,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateIfStale: false,
        },
    );

    return {
        data,
        isLoading,
        isError: error,
    };
}
