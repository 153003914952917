import { useEffect, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { D_MANAGER_SOURCE } from '../../constants';
import { EnrichedLead, ReferencedValue, UpdateLeadCallback } from '../../types/Types';
import { USState } from '../../types/enums';
import { findProp, getUpdatedValue } from '../../utils';
import { SourceTooltip } from '../SourceTooltip';
import DropDownEditor from './DropDownEditor';
import { FlexEditorContainer } from './Editor.styles';
import { useIsEditable } from './hooks/useIsEditable';

const options: { label: string; value: string }[] = [];

for (const [, value] of Object.entries(USState)) {
    options.push({
        label: value,
        value: value,
    });
}

const StateDropdownEditor = ({
    row,
    column,
    value: initialValue,
    previewMode,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    activeUserId: string;
    value: string;
    previewMode?: boolean;
    updateLead: UpdateLeadCallback;
}) => {
    const isEditable = useIsEditable(row, column);
    const [value, setValue] = useState<
        | {
              label: string;
              value: string;
          }
        | undefined
        | ReferencedValue<string>
    >(options.find(option => option.value === initialValue));
    const {
        original: { id: rowId },
    } = row;
    const { id: field } = column;
    const [placeReferencedValuePath] = field.split('.value');
    const placeReferencedValue: ReferencedValue<{ country: USState }> | null = findProp(
        row.original,
        placeReferencedValuePath,
    );

    const updateUsState = (newUsState?: { label: USState; value: string }) => {
        if (!!field && newUsState?.value !== initialValue) {
            const updatedValue = getUpdatedValue(newUsState?.value, field, row.original);
            updateLead(rowId, field, updatedValue, initialValue);
        }
        setValue(newUsState);
    };

    useEffect(() => {
        setValue(options.find(option => option.value === initialValue));
    }, [initialValue, row.original.id]);

    return (
        <FlexEditorContainer>
            <div style={{ width: '90%' }}>
                <DropDownEditor
                    disabled={!isEditable}
                    value={value}
                    options={options}
                    previewMode={previewMode}
                    isClearable
                    updateValue={newValue => updateUsState(newValue)}
                />
            </div>
            {placeReferencedValue && placeReferencedValue.source !== D_MANAGER_SOURCE && (
                <SourceTooltip value={placeReferencedValue} />
            )}
        </FlexEditorContainer>
    );
};

export default StateDropdownEditor;
