import { isNullUndefinedOrEmpty } from '@/utils';

type ComputedFieldProps = {
    sum: number;
    ratio: number;
    avg: number;
};

const rateSum = (sum: number) => {
    if (sum >= 500) return 25;
    else if (500 > sum && sum >= 350) return 20;
    else if (350 > sum && sum >= 250) return 15;
    else if (250 > sum && sum >= 150) return 10;
    else if (150 > sum && sum >= 35) return 5;
    else return 0;
};
const rateRatio = (ratio: number) => {
    if (ratio >= 0.8) return 25;
    else if (0.8 > ratio && ratio >= 0.65) return 20;
    else if (0.65 > ratio && ratio >= 0.55) return 15;
    else if (0.55 > ratio && ratio >= 0.4) return 10;
    else if (0.4 > ratio && ratio >= 0.3) return 5;
    else return 0;
};
const rateAvg = (avg: number) => {
    if (avg >= 90) return 50;
    else if (90 > avg && avg >= 80) return 45;
    else if (80 > avg && avg >= 70) return 40;
    else if (70 > avg && avg >= 60) return 35;
    else if (60 > avg && avg >= 50) return 30;
    else if (50 > avg && avg >= 40) return 25;
    else if (40 > avg && avg >= 30) return 20;
    else if (30 > avg && avg >= 20) return 15;
    else if (20 > avg && avg >= 10) return 10;
    else return 0;
};

const computeValue = (sum: number, ratio: number, avg: number) => {
    if (
        isNullUndefinedOrEmpty(sum) ||
        isNullUndefinedOrEmpty(ratio) ||
        isNullUndefinedOrEmpty(avg)
    ) {
        return null;
    }

    const sumRate = rateSum(sum);
    const ratioRate = rateRatio(ratio);
    const avgRate = rateAvg(avg);

    return sumRate + ratioRate + avgRate;
};

const RateComputedField = ({ sum, ratio, avg }: ComputedFieldProps) => {
    const value = computeValue(sum, ratio, avg);

    return <div>{value ?? 'N/A'}</div>;
};
export default RateComputedField;
