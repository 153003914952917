import { useEffect, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { EnrichedLead, UpdateLeadCallback } from '../../types/Types';
import { OrganizationType } from '../../types/enums';
import { getUpdatedValue } from '../../utils';
import DropDownEditor from './DropDownEditor';
import { useIsEditable } from './hooks/useIsEditable';

const values = Object.values(OrganizationType).map(value => ({
    value: value,
    label: value,
}));

const OrganizationTypeEditor = ({
    row,
    column,
    value: initialValue,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    activeUserId: string;
    value: string;
    updateLead: UpdateLeadCallback;
}) => {
    const isEditable = useIsEditable(row, column);
    const [value, setValue] = useState<
        { value: OrganizationType; label: OrganizationType } | undefined
    >(values.find(option => option.value === initialValue));
    const {
        original: { id: rowId },
    } = row;
    const { id: field } = column;

    const updateOrganizationType = (newType: {
        value: OrganizationType;
        label: OrganizationType;
    }) => {
        if (!!field) {
            const updatedValue = getUpdatedValue(newType?.value, field, row.original);
            updateLead(rowId, field, updatedValue, initialValue);
            setValue(newType);
        }
    };

    useEffect(() => {
        setValue(values.find(option => option.value === initialValue));
    }, [initialValue, row.original.id]);

    return (
        <DropDownEditor
            disabled={!isEditable}
            value={value}
            options={values}
            isClearable
            updateValue={type => updateOrganizationType(type)}
        />
    );
};

export default OrganizationTypeEditor;
