import { THEME } from '@/theme';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

const Loader = styled.div`
    display: inline-block;
    position: relative;
    width: ${props => props.style?.width ?? '80px'};
    height: ${props => props.style?.height ?? '80px'};

    & div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 80%;
        height: 80%;
        margin: 10%;
        border: ${props => (props.style?.height ? `calc(0.1*${props.style?.height})` : '8px')} solid
            ${props => props.style?.color ?? THEME.COLORS.PURPLE.DEFAULT};
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${props => props.style?.color ?? THEME.COLORS.PURPLE.DEFAULT} transparent
            transparent transparent;
    }
    & div:nth-child(1) {
        animation-delay: -0.45s;
    }
    & div:nth-child(2) {
        animation-delay: -0.3s;
    }
    & div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

function CircleLoader(props: HTMLAttributes<HTMLDivElement>) {
    return (
        <Loader {...props}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </Loader>
    );
}

export default CircleLoader;
