import { ColumnType } from '@/types/enums';
import { FormEventHandler, useContext, useState } from 'react';
import { Column } from 'react-table';

import TooltipIconButton from '@components/BasicComponents/TooltipIconButton';

import { ColumnsContext, ColumnsContextType } from '../../../providers/ColumnsProvider';
import { EditorValidation, EnrichedLead } from '../../../types/Types';
import { validateEditorValue } from '../../../utils';
import { SingleSelect } from '../../BasicComponents/SingleSelect/SingleSelect';
import { useUpdateLead } from '../../LeadsTable/hooks/useUpdateLead';
import { Dialog, DialogContent, DialogTrigger, StyledDialogTitle } from '../StyledDialog';
import TypeInput from './TypeInput';

type Option = {
    label: string;
    value: Column<EnrichedLead>;
};

type BulkEditDialogProps = {
    filtersQuery: string;
};

const BulkEditDialog = ({ filtersQuery }: BulkEditDialogProps) => {
    const { selectedColumns } = useContext(ColumnsContext) as ColumnsContextType;

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // State and setter for the input value
    const [value, setValue] = useState<any>('');
    const [validation, setValidation] = useState<EditorValidation>({ isValid: true });

    const [column, setColumn] = useState<Option | Option[] | null>();

    const { bulkUpdateLeads } = useUpdateLead();

    const options = selectedColumns.reduce<Option[]>((accumulator, current) => {
        // @ts-ignore
        if (current.type && current.Cell) {
            accumulator.push({
                label: current.Header?.toString()!,
                value: current,
            });
        }

        return accumulator;
    }, []);

    const handleColumnChange = (selected?: Option | Option[] | null) => {
        setValue(() => {
            setColumn(selected);

            return '';
        });
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
        e.preventDefault();
        setIsSubmitting(true);
        // @ts-ignore
        let field: string = column?.value.accessor;

        let formattedValue;

        if (value.label && value.value) {
            // Check if assignee
            if (value.value.userId) {
                formattedValue = value.value.userId;
            } else if (field === 'dcp.coa.name') {
                formattedValue = { id: value.value, name: value.label };
            } else if (typeof value.value === 'boolean') {
                formattedValue = value.value;
            } else {
                formattedValue = value.label;
            }
        } else {
            formattedValue = value;
        }

        await bulkUpdateLeads(filtersQuery, field, formattedValue);
        setIsSubmitting(false);
        setIsDialogOpen(false);
    };

    const onValueChange = (newValue: any) => {
        // @ts-ignore
        const type: ColumnType = column?.value.type;
        setValidation(validateEditorValue(newValue, type));
        setValue(newValue);
    };

    const { isValid, errorMessage } = validation;

    const disabled = !value || !isValid || isSubmitting;

    return (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
                <TooltipIconButton
                    content="Edit Value"
                    iconName="Edit"
                    style={{
                        width: 16,
                        height: 16,
                        marginRight: 12,
                    }}
                />
            </DialogTrigger>

            <DialogContent>
                <StyledDialogTitle title="Edit Value" withCloseButton />

                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginTop: 10,
                            gap: 25,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 10,
                            }}
                        >
                            <label htmlFor="column-name-selection">Column Name</label>
                            <SingleSelect
                                id="column-name-selection"
                                options={options}
                                value={column}
                                onChange={handleColumnChange}
                            />
                        </div>

                        {/* @ts-ignore */}
                        {column?.value.editableInBulk && column?.value.Cell && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 5,
                                }}
                            >
                                <label htmlFor="add-value-input">Add value</label>
                                <div>
                                    <TypeInput
                                        id="add-value-input"
                                        value={value}
                                        setValue={onValueChange}
                                        // @ts-ignore
                                        type={column?.value.type}
                                        // @ts-ignore
                                        options={column?.value.options}
                                        isValid={isValid}
                                        // @ts-ignore
                                        accessor={column?.value.accessor}
                                    />
                                    {!isValid && <div style={{ color: 'red' }}>{errorMessage}</div>}
                                </div>
                            </div>
                        )}
                    </div>

                    <button
                        type="submit"
                        disabled={disabled}
                        style={{
                            backgroundColor: '#6E80F6',
                            opacity: disabled ? '0.5' : '1',
                            borderRadius: 5,
                            padding: '6px 32px',
                            margin: '30px auto 10px',
                            color: 'white',
                        }}
                    >
                        Submit
                    </button>
                </form>
            </DialogContent>
        </Dialog>
    );
};
export default BulkEditDialog;
