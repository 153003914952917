import { FilterContext } from '@/providers/FilterProvider';
import { PageContext } from '@/providers/PageProvider';
import { NumberRange } from '@/types/Types';
import { isBetween, isHigherThan, isLowerThan } from '@/utils';
import { isNumber } from 'lodash';
import { ChangeEvent, useContext, useState } from 'react';
import styled from 'styled-components';

import { StyledButton } from '../BasicComponents/Buttons/Button.styles';
import { StyledInput } from '../BasicComponents/StyledInput';
import FilterExplaination from './FilterExplaination';

const RangeInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const Separator = styled.div`
    width: 20.5px;
    height: 0px;

    border: 1px solid #170d44;
`;

type InputType = 'min' | 'max';

type FilterNumberRangeProps = {
    name: string;
    displayName: string;
};

const FilterNumberRange = ({ name, displayName }: FilterNumberRangeProps) => {
    const { saveFilterValue } = useContext(FilterContext);
    const { setPageIndex } = useContext(PageContext);

    const [minValue, setMinValue] = useState<number | ''>('');
    const [maxValue, setMaxValue] = useState<number | ''>('');
    const [isExcluded, setIsExcluded] = useState(false);

    const handleFilterChange = () => {
        let value: NumberRange = {};
        if (isLowerThan(minValue, maxValue)) {
            value = { maxValue: maxValue as number };
        }
        if (isHigherThan(minValue, maxValue)) {
            value = { minValue: minValue as number };
        }
        if (isBetween(minValue, maxValue)) {
            value = { minValue: minValue as number, maxValue: maxValue as number, isExcluded };
        }
        if (value) {
            saveFilterValue({
                accessor: name,
                displayName: displayName,
                value,
            });
            setPageIndex(0);
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>, inputType: InputType) => {
        const value = event.target.valueAsNumber;
        const isLowerThanMaxValue =
            !isNumber(maxValue) ||
            (value !== undefined && value < (maxValue as number)) ||
            !isNumber(value);
        const isHigherThanMinValue =
            !isNumber(minValue) ||
            (value !== undefined && value > (minValue as number)) ||
            !isNumber(value);
        if (isNaN(value)) {
            if (inputType === 'min') setMinValue('');
            else setMaxValue('');
        } else {
            if (inputType === 'min') {
                setMinValue(isLowerThanMaxValue ? value : maxValue);
            } else {
                setMaxValue(isHigherThanMinValue ? value : minValue);
            }
        }
    };

    const handleMinChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleInputChange(event, 'min');
    };

    const handleMaxChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleInputChange(event, 'max');
    };

    const handleExcludeChange = (isExcluded: boolean) => setIsExcluded(!isExcluded);

    return (
        <Container>
            <RangeInputContainer>
                <StyledInput
                    type="number"
                    aria-label="Min Input"
                    value={minValue}
                    placeholder={'min'}
                    onChange={handleMinChange}
                    style={{ width: '8rem' }}
                />
                <Separator />
                <StyledInput
                    type="number"
                    aria-label="Max Input"
                    value={maxValue}
                    placeholder={'max'}
                    onChange={handleMaxChange}
                    style={{ width: '8rem' }}
                />
            </RangeInputContainer>
            <FilterExplaination
                minValue={minValue}
                maxValue={maxValue}
                isExcluded={isExcluded}
                handleExcludeChange={handleExcludeChange}
            />
            <div style={{ alignSelf: 'flex-end' }}>
                <StyledButton
                    style={{ width: '8rem', fontSize: '1.5rem' }}
                    onClick={handleFilterChange}
                >
                    Filter
                </StyledButton>
            </div>
        </Container>
    );
};

export default FilterNumberRange;
