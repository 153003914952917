import styled from 'styled-components';

const StyledScrollbarsContainer = styled.div`
    div::-webkit-scrollbar {
        background-color: #fff0;
        width: 0.5rem;
        height: 0.5rem;
    }

    /* background of the scrollbar except button or resizer */
    div::-webkit-scrollbar-track {
        background-color: #fff0;
    }
    div::-webkit-scrollbar-track:hover {
        background: #eeeeee;
        transition: background 160ms ease-out;
    }

    /* scrollbar itself */
    div::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border-radius: 16px;
        border: 5px solid #fff0;
    }

    /* set button(top and bottom of the scrollbar) */
    div::-webkit-scrollbar-button {
        display: none;
    }
`;

export default StyledScrollbarsContainer;
