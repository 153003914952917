import { SERVER_URL } from '@/constants';
import { AccessTokenContext } from '@/providers/AccessTokenProvider';
import type { Coa, PreviewLead } from '@/types/Types';
import axios from 'axios';
import _ from 'lodash';
import { useContext, useState } from 'react';

const DUPLICATE_LEADS_COAS = new Set<Coa['id']>(
    process.env.REACT_APP_DUPLICATE_LEADS_COAS?.split(',').map(Number),
);

export default function useDuplicateLeads(): {
    getLeadsToCheckIfDuplicate: (
        previewLeads: PreviewLead[],
    ) => [leadsToCheckIfDuplicate: PreviewLead[], rest: PreviewLead[]];
    searchDuplicateLeads: (
        leadsToCheck: PreviewLead[],
    ) => Promise<{ duplicateUids: Set<string>; nonDuplicateLeads: PreviewLead[] }>;
    isLoading: boolean;
} {
    const { accessToken } = useContext(AccessTokenContext);
    const [isLoading, setIsLoading] = useState(false);

    const getLeadsToCheckIfDuplicate = (previewLeads: PreviewLead[]) => {
        return _.partition(
            previewLeads,
            lead =>
                !lead.id &&
                (lead.organization?.website || lead.sourceUrl) &&
                lead.dcp?.coa &&
                DUPLICATE_LEADS_COAS.has(lead.dcp.coa.id),
        );
    };

    const searchDuplicateLeads = async (leadsToCheck: PreviewLead[]) => {
        let duplicateUids = new Set<string>();

        const searchedLeads = leadsToCheck.map(lead => ({
            uid: lead.uid,
            sourceUrl: lead.sourceUrl,
            companyDomain: lead.organization?.website,
            coaId: lead.dcp?.coa?.id,
        }));

        setIsLoading(true);

        try {
            const { data } = await axios.post<{ duplicates: string[] }>(
                `${SERVER_URL}/leads/search-duplicates`,
                { leads: searchedLeads },
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
            );

            duplicateUids = new Set(data.duplicates);
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);

        const nonDuplicateLeads = _.filter(leadsToCheck, lead => !duplicateUids.has(lead.uid));

        return { duplicateUids, nonDuplicateLeads };
    };

    return { getLeadsToCheckIfDuplicate, searchDuplicateLeads, isLoading };
}
