import { SERVER_URL } from '@/constants';
import { COUNT_ROUTE_PREFIX } from '@/hooks/useLeads/useCountLeads';
import { allLeadsPagesMatcher } from '@/hooks/useLeads/useFetchLeads';
import useLeads from '@/hooks/useLeads/useLeads';
import { EnrichedLead, UpdateLeadInputFields } from '@/types/Types';
import { UPDATE_TRIGGERS } from '@/types/enums';
import { getKeysByRoute, mapUpdatedValueToFields } from '@/utils';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import _ from 'lodash';
import { useCallback, useContext } from 'react';
import { useSWRConfig } from 'swr';
import { useAnalytics } from 'use-analytics';

import { AccessTokenContext } from '@providers/AccessTokenProvider';

export const useUpdateLead = () => {
    const { accessToken } = useContext(AccessTokenContext);
    const { user } = useAuth0();
    const { mutate } = useSWRConfig();
    const { currentLeadsKey: currentKey } = useLeads();

    const { track } = useAnalytics();

    const sendRequestToUpdate = useCallback(
        async (fields: UpdateLeadInputFields, id: number) => {
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };
            const { data: updatedLead } = await axios.patch<EnrichedLead>(
                `${SERVER_URL}/leads/${id}`,
                { fields, userEmail: user?.email },
                config,
            );

            await mutate(
                [currentKey, accessToken],
                (leads: EnrichedLead[] | undefined) => {
                    if (!leads) return;

                    const index = leads.findIndex(lead => lead.id === updatedLead.id);
                    const cloneLeads = _.cloneDeep(leads);

                    if (index > -1) {
                        cloneLeads.splice(index, 1, updatedLead);
                    }
                    return cloneLeads;
                },
                false,
            );

            await mutate(
                (key: string[]) => getKeysByRoute(key, '/leads', [currentKey, COUNT_ROUTE_PREFIX]),
                undefined,
                { revalidate: true },
            );
        },
        [user?.email, mutate, accessToken, currentKey],
    );

    const updateLead = useCallback(
        async (
            id: number,
            field: string,
            value: any,
            oldValue: any,
            triggeredBy: UPDATE_TRIGGERS,
        ) => {
            if (oldValue === value) {
                return;
            }
            const fields = mapUpdatedValueToFields(field, value);

            await sendRequestToUpdate(fields, id);

            await mutate((key: string[]) => getKeysByRoute(key, '/filters'));

            track('dManager:cell_update', {
                id,
                name: field,
                newValue: value,
                oldValue: oldValue?.value ?? oldValue,
                oldSource: oldValue?.source,
                triggeredBy,
            });
        },
        [sendRequestToUpdate, track, mutate],
    );

    const sendRequestToBulkUpdate = useCallback(
        async (filtersQuery: string, field: string, value: any) => {
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            const { data }: { data: { id: number }[] } = await axios.patch(
                `${SERVER_URL}/leads/bulk?${filtersQuery}`,
                { accessor: field, value: value, userEmail: user?.email },
                config,
            );
            await mutate(allLeadsPagesMatcher);
            return data;
        },
        [mutate, accessToken, user?.email],
    );

    const bulkUpdateLeads = useCallback(
        async (filtersQuery: string, field: string, value: any) => {
            const updatedValue = await sendRequestToBulkUpdate(filtersQuery, field, value);

            updatedValue.forEach(({ id }) => {
                track('dManager:cell_update', {
                    id,
                    name: field,
                    newValue: value,
                    inBulk: true,
                    triggeredBy: UPDATE_TRIGGERS.TABLE,
                });
            });
        },
        [sendRequestToBulkUpdate, track],
    );

    return { updateLead, bulkUpdateLeads };
};
