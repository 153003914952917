import styled, { css } from 'styled-components';

interface MainProps {
    isSideNavFull: boolean;
}

export const Main = styled.main<MainProps>`
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;

    height: 100vh;
    min-height: 100vh;

    ${props =>
        props.isSideNavFull
            ? css`
                  margin-left: 16rem;
                  width: calc(100vw - 16rem);

                  @media (min-width: 1536px) {
                      margin-left: 20rem;
                      width: calc(100vw - 20rem);
                  }
              `
            : css`
                  margin-left: 5rem;
                  width: calc(100vw - 5rem);
              `}

    @media (prefers-reduced-motion: reduce) {
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }
`;
