import {
    IconButton,
    IconButtonProps,
    IconNameType,
    Tooltip,
    Typography,
} from '@darrow-ai/darrow-design';
import { forwardRef } from 'react';

type TooltipIconButtonProps = Partial<IconButtonProps> & {
    content: string;
    iconName: IconNameType;
};

const TooltipIconButton = forwardRef<HTMLButtonElement, TooltipIconButtonProps>(
    ({ content, iconName, ...props }, ref) => {
        return (
            <Tooltip>
                <Tooltip.Trigger asChild>
                    <IconButton ref={ref} name={iconName} {...props} />
                </Tooltip.Trigger>
                <Tooltip.Content side="top" sideOffset={5}>
                    <Typography style={{ color: 'white', fontSize: '1rem' }}>{content}</Typography>

                    <Tooltip.Arrow />
                </Tooltip.Content>
            </Tooltip>
        );
    },
);
export default TooltipIconButton;
