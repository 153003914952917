import useLeadStatuses from '@/hooks/useLeadStatuses';
import { useEffect, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { EnrichedLead, UpdateLeadCallback } from '../../types/Types';
import { LeadStatus } from '../../types/enums';
import { getUpdatedValue } from '../../utils';
import DropDownEditor from './DropDownEditor';
import { ColorCircle } from './StatusEditor.styles';
import { useIsEditable } from './hooks/useIsEditable';

const values = Object.values(LeadStatus).map(value => ({
    value: value,
    label: value,
}));

const StatusEditor = ({
    row,
    column,
    value: initialValue,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    value: string;
    updateLead: UpdateLeadCallback;
}) => {
    const isEditable = useIsEditable(row, column);
    const [value, setValue] = useState<{ value: LeadStatus; label: LeadStatus } | undefined>(
        values.find(option => option.value === initialValue),
    );
    const {
        original: { id: rowId },
    } = row;
    const { id: field } = column;

    const { data: statuses } = useLeadStatuses();

    const updateStatus = (status: { value: LeadStatus; label: LeadStatus }) => {
        if (!!field) {
            const updatedValue = getUpdatedValue(status.value, field, row.original);
            updateLead(rowId, field, updatedValue, initialValue);
            setValue(status);
        }
    };

    useEffect(() => {
        setValue(values.find(option => option.value === initialValue));
    }, [initialValue, row.original.id]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {value?.value && statuses && <ColorCircle color={statuses[value.value].color} />}

            <div style={{ flex: 1 }}>
                <DropDownEditor
                    disabled={!isEditable}
                    value={value}
                    options={values}
                    updateValue={status => updateStatus(status)}
                />
            </div>
        </div>
    );
};

export default StatusEditor;
