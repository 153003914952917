import { SEARCH_TERM_KEY } from '@/constants';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Typography } from '../BasicComponents/Typography';
import { SearchCombobox } from '../SearchCombobox';
import { Navigation } from './Navigation';

export const Header = () => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [, setSearchParams] = useSearchParams();

    return (
        <>
            <Navigation />
            <div style={{ width: '50%' }}>
                <Typography variant="p" size="large">
                    Search Results For:
                </Typography>
                <div style={{ width: '100%' }}>
                    <SearchCombobox
                        isOpen={isSearchOpen}
                        setIsOpen={setIsSearchOpen}
                        onValueChange={(value: string) =>
                            value && setSearchParams({ [SEARCH_TERM_KEY]: value })
                        }
                    />
                </div>
            </div>
        </>
    );
};
