import { THEME } from '@/theme';

const EmptyTableSVG = () => {
    return (
        <svg
            width="242"
            height="165"
            viewBox="0 0 242 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M238.665 28.9102L224.5 32.7473L75.3518 17.569L87.8271 11.8984L238.665 28.9102Z"
                fill={THEME.COLORS.GRAY.LIGHT}
                stroke="#373B68"
                strokeLinejoin="bevel"
            />
            <path
                d="M182.526 93.5562L24.8835 68.6056L75.9188 17.5703L224 32.7485L182.526 93.5562Z"
                fill={THEME.COLORS.GRAY.LIGHT}
                stroke="#373B68"
                strokeLinejoin="bevel"
            />
            <circle cx="140.683" cy="40.4665" r="6.38055" fill="#373B68" stroke="#373B68" />
            <circle cx="172.792" cy="41.3845" r="6.38055" fill="#373B68" stroke="#373B68" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M124.833 1.987C122.814 3.40072 122.793 3.64934 122.793 27.557V51.6986L119.353 52.2499C109.639 53.8049 98.9444 62.5074 94.0858 72.8127C91.2565 78.8125 91.1556 79.4584 90.6978 94.4506L90.2254 109.904L85.6622 106.836C77.8486 101.582 73.6753 93.1011 72.6396 80.373C72.3322 76.5887 71.5607 72.4595 70.9259 71.1971C66.8636 63.1184 56.5171 61.9863 50.4016 68.9504C47.7366 71.9861 47.5659 72.6228 47.5659 79.5354C47.5659 105.769 63.6407 128.948 85.3172 133.971C88.1869 134.635 102.596 134.808 129.353 134.5L169.122 134.041L173.709 131.587C182.454 126.909 187.825 120.244 190.182 111.143C191.376 106.537 191.599 97.7496 191.599 55.3682V5.06214L189.347 2.81083C188.108 1.57233 186.454 0.558594 185.67 0.558594C184.885 0.558594 180.561 3.86126 176.058 7.89785L167.873 15.2371H156.875H145.876L140.986 10.8583C128.345 -0.459731 128.334 -0.465234 124.833 1.987ZM147.196 33.9522C149.753 36.509 150.316 37.7659 150.316 40.9245C150.316 46.1785 145.719 51.016 140.729 51.016C134.644 51.016 130.555 47.8326 130.124 42.7593C129.703 37.8007 130.242 36.0549 132.982 33.4944C135.251 31.3752 136.67 30.833 139.954 30.833C143.42 30.833 144.574 31.3303 147.196 33.9522ZM180.488 33.1816C187.772 39.3108 183.323 51.016 173.709 51.016C168.958 51.016 165.541 48.5711 164.072 44.1208C160.833 34.3054 172.546 26.4992 180.488 33.1816Z"
                fill="#373B68"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M129.128 42.8439C128.916 40.3474 128.927 38.5058 129.412 36.9328C129.91 35.3189 130.87 34.0993 132.299 32.7638C133.475 31.6658 134.508 30.9017 135.731 30.4348C136.948 29.97 138.267 29.833 139.954 29.833C141.718 29.833 143.043 29.955 144.287 30.491C145.523 31.023 146.579 31.921 147.903 33.2451C149.2 34.5415 150.089 35.6014 150.628 36.8043C151.173 38.0234 151.316 39.2935 151.316 40.9245C151.316 43.8406 150.044 46.6025 148.119 48.6293C146.195 50.6544 143.548 52.016 140.729 52.016C137.518 52.016 134.743 51.1757 132.692 49.5792C130.627 47.9712 129.366 45.6473 129.128 42.8439ZM181.132 32.4164C189.17 39.18 184.203 52.016 173.709 52.016C171.159 52.016 168.905 51.3578 167.078 50.0505C165.25 48.7427 163.914 46.8312 163.122 44.4342C159.556 33.6282 172.446 25.1079 181.132 32.4164ZM150.316 40.9245C150.316 37.7659 149.753 36.509 147.196 33.9522C144.574 31.3303 143.42 30.833 139.954 30.833C136.67 30.833 135.251 31.3752 132.982 33.4944C130.242 36.0549 129.703 37.8007 130.124 42.7593C130.555 47.8326 134.644 51.016 140.729 51.016C145.719 51.016 150.316 46.1785 150.316 40.9245ZM173.709 51.016C183.323 51.016 187.772 39.3108 180.488 33.1816C172.546 26.4992 160.833 34.3054 164.072 44.1208C165.541 48.5711 168.958 51.016 173.709 51.016Z"
                fill="#373B68"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M155.06 50.0993C154.046 50.0991 153.225 49.2777 153.225 48.2645C153.225 47.2512 154.047 46.4297 155.06 46.4297H158.73C159.743 46.4297 160.564 47.2512 160.564 48.2645C160.564 49.2778 159.743 50.0993 158.73 50.0993H158.729V52.6049C158.807 52.6662 158.882 52.735 158.952 52.8112L160.16 54.1218C160.877 54.8991 160.877 56.1594 160.16 56.9367C159.444 57.714 158.282 57.714 157.565 56.9367L156.793 56.0984L156.146 56.8C155.429 57.5773 154.268 57.5773 153.551 56.8C152.835 56.0227 152.835 54.7624 153.551 53.9851L154.633 52.8112C154.763 52.6709 154.906 52.5558 155.06 52.4661V50.0993Z"
                fill={THEME.COLORS.GRAY.LIGHT}
            />
            <path
                d="M181.959 163.873V92.9903L26.5847 67.4727V136.654L181.959 163.873Z"
                fill={THEME.COLORS.GRAY.LIGHT}
                stroke="#373B68"
            />
            <path
                d="M227.891 88.4546C214.47 110.381 186.609 156.275 182.526 164.44V94.1252L225.055 32.8828L227.891 88.4546Z"
                fill={THEME.COLORS.GRAY.LIGHT}
            />
            <path
                d="M182.526 164.44H182.026L182.973 164.664L182.526 164.44ZM227.891 88.4546L228.317 88.7156L228.398 88.5836L228.39 88.4291L227.891 88.4546ZM225.055 32.8828L225.555 32.8573L224.645 32.5976L225.055 32.8828ZM182.526 94.1252L182.115 93.84L182.026 93.9686V94.1252H182.526ZM182.973 164.664C187.043 156.523 214.887 110.657 228.317 88.7156L227.464 88.1936C214.053 110.104 186.174 156.026 182.079 164.217L182.973 164.664ZM228.39 88.4291L225.555 32.8573L224.556 32.9083L227.391 88.4801L228.39 88.4291ZM224.645 32.5976L182.115 93.84L182.937 94.4104L225.466 33.168L224.645 32.5976ZM182.026 94.1252V164.44H183.026V94.1252H182.026Z"
                fill="#373B68"
            />
            <path d="M75.9188 17.5703V78.8127" stroke="#373B68" />
            <path
                d="M182.526 92.4232L24.8836 67.4727L1.63416 86.7527L155.874 113.971L182.526 92.4232Z"
                fill={THEME.COLORS.GRAY.LIGHT}
                stroke="#373B68"
            />
            <path
                d="M54.9377 20.972C61.1753 20.0269 73.9908 18.1367 75.3518 18.1367L26.0176 66.9038L0.5 74.2755L54.9377 20.972Z"
                stroke="#373B68"
            />
            <path
                d="M198.385 113.25L182.5 92.9683L224.481 33.25L241.5 47.8979L198.385 113.25Z"
                fill={THEME.COLORS.GRAY.LIGHT}
                stroke="#373B68"
                strokeLinejoin="bevel"
            />
        </svg>
    );
};

export default EmptyTableSVG;
