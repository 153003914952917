import { Input } from '@darrow-ai/darrow-design';
import styled from 'styled-components';

interface StyledInputEditorProps {
    previewMode?: boolean;
}

export const StyledInputEditor = styled(Input)<StyledInputEditorProps>`
    border: none;
    background-color: transparent;

    &:focus {
        border: 1px solid #6e80f6;
    }

    input {
        color: #1c1c1c !important;
    }
`;

interface InlineEditorContainerProps {
    errorBorder: boolean;
}

export const InlineEditorContainer = styled.div<InlineEditorContainerProps>`
    display: flex;
    border-radius: 5px;
    border: ${props => (props.errorBorder ? '1px solid red' : '')};
`;
