import { isBetween, isHigherThan, isLowerThan } from '@/utils';
import { Typography } from '@darrow-ai/darrow-design';
import styled from 'styled-components';

import { Checkbox } from '../BasicComponents/Checkbox';

const Label = styled(Typography)`
    font-size: 1rem;
`;
const FilterExplainationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
`;

type FilterExplainationProps = {
    minValue: number | '';
    maxValue: number | '';
    isExcluded: boolean;
    handleExcludeChange: (isExcluded: boolean) => void;
};

const FilterExplaination = ({
    minValue,
    maxValue,
    isExcluded,
    handleExcludeChange,
}: FilterExplainationProps) => {
    if (isHigherThan(minValue, maxValue)) {
        return (
            <Label>
                Higher Than <b>{minValue?.toLocaleString()}</b>
            </Label>
        );
    } else {
        if (isLowerThan(minValue, maxValue)) {
            return (
                <Label>
                    Lower Than <b>{maxValue?.toLocaleString()}</b>
                </Label>
            );
        } else {
            if (isBetween(minValue, maxValue)) {
                return (
                    <FilterExplainationContainer>
                        <Checkbox
                            id={'is-excluded'}
                            checked={isExcluded}
                            onChange={() => handleExcludeChange(isExcluded)}
                        />
                        <Label>Exclude leads from this range</Label>
                    </FilterExplainationContainer>
                );
            }
        }
    }
    return <></>;
};

export default FilterExplaination;
