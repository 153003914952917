import RippleLoader from '@/components/BasicComponents/Loaders/RippleLoader';
import { SEARCH_QUERY_PREFIX, SEARCH_TERM_KEY } from '@/constants';
import useCoaSectionsMetaData from '@/hooks/useCoaSectionsMetaData';
import useLeadStatuses from '@/hooks/useLeadStatuses';
import useLeads from '@/hooks/useLeads/useLeads';
import useSelectOptions from '@/hooks/useSelectOptions';
import { ProfileOptions, TableProfileContext } from '@/providers/TableProfileProvider';
import { EnrichedLead } from '@/types/Types';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { AddLeadsDialog } from '@components/Dialog/AddLeadsDialog.tsx/AddLeadsDialog';
import { Filter } from '@components/Filter/Filter';
import { SelectedFilters } from '@components/Filter/SelectedFilters';
import { LeadsTable } from '@components/LeadsTable';

import { getRowNumberColumns } from '@columns/columns';

import useAssignees from '@hooks/useAssignees';

import { ColumnsContext, ColumnsContextType } from '@providers/ColumnsProvider';
import { PageContext } from '@providers/PageProvider';

import { SearchCombobox } from '../SearchCombobox';
import ErrorFallback from './ErrorFallback';
import {
    FlexContainer,
    LeadsManagerContainer,
    LeadsManagerSearchContainer,
} from './StyledLeadManager';

dayjs.extend(isBetween);

export const LeadsManager = () => {
    const { setTableProfile } = useContext(TableProfileContext);

    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const navigate = useNavigate();

    const findOrganizationByTerm = (value: string) => {
        if (value) {
            setTableProfile(ProfileOptions.SEARCH);
            const params = { [SEARCH_TERM_KEY]: value };
            const searchParams = createSearchParams(params);
            navigate({
                pathname: `/${SEARCH_QUERY_PREFIX}`,
                search: `${searchParams}`,
            });
        }
    };
    const { selectedColumns } = useContext(ColumnsContext) as ColumnsContextType;

    const { setTotalDataCount } = useContext(PageContext);
    const { user } = useAuth0();

    const { leads, isLeadsLoading, isLeadsError, totalCount, isTotalCountLoading } = useLeads();

    const { isLoading: isAssigneesLoading, isError: isAssigneesError } = useAssignees();

    const { isLoading: isStatusesLoading, isError: isStatusesError } = useLeadStatuses();
    const { isLoading: isSelectOptionsLoading, isError: isSelectOptionsError } = useSelectOptions();

    const { isLoading: isCoaSectionsMetaDataLoading, isError: isCoaSectionsMetaDataError } =
        useCoaSectionsMetaData();

    const columns = useMemo(
        () => [...getRowNumberColumns(), ...selectedColumns],
        [selectedColumns],
    );

    const data = useMemo(() => leads, [leads]);

    useEffect(() => {
        !isTotalCountLoading && setTotalDataCount(totalCount!);
    }, [isTotalCountLoading, setTotalDataCount, totalCount]);

    if (
        isAssigneesError ||
        isLeadsError ||
        isStatusesError ||
        isCoaSectionsMetaDataError ||
        isSelectOptionsError
    ) {
        console.log(
            isAssigneesError,
            isLeadsError,
            isStatusesError,
            isCoaSectionsMetaDataError,
            isSelectOptionsError,
        );

        return null;
    }

    if (
        isAssigneesLoading ||
        isStatusesLoading ||
        isCoaSectionsMetaDataLoading ||
        isSelectOptionsLoading
    ) {
        return <RippleLoader />;
    }

    return (
        <>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                    window.location.reload();
                }}
            >
                <LeadsManagerContainer>
                    <div style={{ marginRight: '2rem' }}>
                        <LeadsManagerSearchContainer isSearchOpen={isSearchOpen}>
                            <SearchCombobox
                                isOpen={isSearchOpen}
                                isClearable
                                setIsOpen={setIsSearchOpen}
                                onValueChange={findOrganizationByTerm}
                            />
                        </LeadsManagerSearchContainer>
                    </div>
                    <FlexContainer style={{ opacity: isSearchOpen ? 0.5 : 1 }}>
                        <div>
                            <Filter />
                            <SelectedFilters />
                        </div>
                        <AddLeadsDialog />
                    </FlexContainer>

                    <LeadsTable
                        data={data || []}
                        columns={columns as Column<Partial<EnrichedLead>>[]}
                        activeUserId={user?.sub!}
                        totalLeadsCount={totalCount!}
                        isLoading={isLeadsLoading}
                        style={{ opacity: isSearchOpen ? 0.5 : 1 }}
                    />
                </LeadsManagerContainer>
            </ErrorBoundary>
        </>
    );
};
