import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { DropReason, EnrichedLead, UpdateLeadCallback } from '../../types/Types';
import { LeadDecision } from '../../types/enums';
import { getUpdatedValue } from '../../utils';
import DropDownEditor from './DropDownEditor';
import { useIsEditable } from './hooks/useIsEditable';

const values = Object.values(LeadDecision).map(value => ({
    value: value,
    label: value,
}));

const DecisionEditor = ({
    row,
    column,
    activeUserId,
    value: initialValue,
    updateLead,
    setDropReasonDialog,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    activeUserId: string;
    value: string;
    updateLead: UpdateLeadCallback;
    setDropReasonDialog: Dispatch<
        SetStateAction<{
            open: boolean;
            updateDropReasons: (dropReasons: Partial<DropReason>[]) => any;
        }>
    >;
}) => {
    const isEditable = useIsEditable(row, column);
    const [value, setValue] = useState<{ value: LeadDecision; label: LeadDecision } | undefined>(
        values.find(option => option.value === initialValue),
    );
    const {
        original: { id: leadId },
    } = row;
    const { id: field } = column;

    const updateDropReasons = (dropReasons: Partial<DropReason>[]) => {
        updateLead(leadId, 'dropReasons', dropReasons, row.original.dropReasons);
    };

    const updateDecision = (decision?: { value: LeadDecision; label: LeadDecision }) => {
        if (!!field) {
            const updatedValue = getUpdatedValue(decision?.value, field, row.original);
            updateLead(leadId, field, updatedValue, initialValue);
            setValue(decision);

            if (decision?.value === LeadDecision.DROP) {
                setDropReasonDialog({ open: true, updateDropReasons });
            }
        }
    };

    useEffect(() => {
        setValue(values.find(option => option.value === initialValue));
    }, [initialValue, row.original.id]);

    return (
        <DropDownEditor
            disabled={!isEditable}
            value={value}
            options={values}
            isClearable
            updateValue={decision => updateDecision(decision)}
        />
    );
};

export default DecisionEditor;
