import { DRAWER_Z_INDEX } from '@/constants';
import { useSearchedOrganizations } from '@/hooks/useSearchedOrganizations';
import { THEME } from '@/theme';
import { Combobox } from '@darrow-ai/comp-lib';
import { Search as SearchIcon } from '@styled-icons/bootstrap/Search';
import { Clear as ClearIcon } from '@styled-icons/material/Clear';
import { uniqBy } from 'lodash';

import {
    StyledComboboxInput,
    StyledComboboxInputContainer,
    StyledComboboxMenu,
} from './SearchCombobox.styles';

export const SearchCombobox = ({
    isOpen,
    isClearable,
    setIsOpen,
    onValueChange,
}: {
    isOpen: boolean;
    isClearable?: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onValueChange: (value: any) => void;
}) => {
    const { searchTerm, setSearchTerm, searchedOrganizations, isLoading } =
        useSearchedOrganizations();
    const placeholder = isOpen ? '' : 'Search All Leads';

    // Create a formatted array of organization names
    const distinctOrganizations = uniqBy(searchedOrganizations, ({ name }) =>
        name.trim().toLowerCase(),
    );

    const organizationsToDisplay = distinctOrganizations.map(({ name }) => ({
        value: name,
        label: name,
    }));

    return (
        <Combobox.Root
            isOpen={isOpen}
            onIsOpenChange={({ isOpen }) => setIsOpen(!!isOpen)}
            items={organizationsToDisplay}
            inputValue={searchTerm}
            setInputValue={setSearchTerm}
            onValueChange={newValue => onValueChange(newValue?.value)}
            asyncSearchLabel="Type Company Name Or Company Domain"
            emptyLabel={isLoading ? 'Loading...' : 'No results'}
        >
            <StyledComboboxInputContainer isOpen={isOpen}>
                <StyledComboboxInput
                    style={{ width: '80%' }}
                    placeholder={placeholder}
                    onKeyUp={({ key }) => {
                        if (key === 'Enter') {
                            onValueChange(searchTerm);
                        }
                    }}
                />
                {isClearable && searchTerm ? (
                    <ClearIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSearchTerm('')}
                        size="18"
                    />
                ) : (
                    <SearchIcon
                        style={{ color: THEME.COLORS.PURPLE.DEFAULT, cursor: 'pointer' }}
                        onClick={() => {
                            isOpen ? onValueChange(searchTerm) : setIsOpen(true);
                        }}
                        size="18"
                    />
                )}
            </StyledComboboxInputContainer>
            <StyledComboboxMenu style={{ zIndex: DRAWER_Z_INDEX + 10 }}>
                {({ filteredItems }) =>
                    filteredItems.map((item, index) => (
                        <Combobox.Item key={`${item.label}${index}`} item={item} index={index}>
                            {item.label}
                        </Combobox.Item>
                    ))
                }
            </StyledComboboxMenu>
        </Combobox.Root>
    );
};
