import { Icon, Tooltip } from '@darrow-ai/darrow-design';

interface EditorErrorTooltipProps {
    message?: string;
}

export const EditorErrorTooltip = ({ message }: EditorErrorTooltipProps) => (
    <Tooltip>
        <Tooltip.Trigger>
            <div style={{ height: 35, width: 16 }}>
                <Icon name="Error" />
            </div>
        </Tooltip.Trigger>

        <Tooltip.Content>{message}</Tooltip.Content>
    </Tooltip>
);
