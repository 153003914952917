import useStaticColumns from '@/hooks/useStaticColumns';
import { ChangeEvent, useEffect, useState } from 'react';
import { ColumnInstance, Row } from 'react-table';

import {
    EditorValidation,
    EnrichedLead,
    ReferencedValue,
    UpdateLeadCallback,
} from '../../types/Types';
import { getUpdatedValue, isReferencedValueKey, validateEditorValue } from '../../utils';
import { EditorErrorTooltip } from './EditorErrorTooltip';
import { InlineEditorContainer, StyledInputEditor } from './StyledInputEditor';
import { UrlPopover } from './UrlPopover';
import { useIsEditable } from './hooks/useIsEditable';

const InlineEditor = ({
    row,
    column,
    value: initialValue,
    previewMode,
    disabled,
    updateLead,
}: {
    row: Row<EnrichedLead>;
    column: ColumnInstance<EnrichedLead>;
    value?: string | ReferencedValue<string>;
    previewMode?: boolean;
    disabled?: boolean;
    updateLead: UpdateLeadCallback;
}) => {
    const [validation, setValidation] = useState<EditorValidation>({
        isValid: true,
    });
    const isEditable = useIsEditable(row, column);
    const {
        original: { id: leadId },
    } = row;
    const { id: field } = column;
    const { isValid, errorMessage } = validation;
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const { data: staticColumns } = useStaticColumns();
    const [value, setValue] = useState(
        // @ts-ignore
        (isReferencedValueKey(staticColumns, field) ? initialValue?.value : initialValue) || '',
    );

    const onBlur = () => {
        //TODO: support keyword ENTER
        const existingValue = isReferencedValueKey(staticColumns, field)
            ? // @ts-ignore
              initialValue?.value
            : initialValue;

        if (!!field && value !== existingValue) {
            if (!isValid) {
                setValue(existingValue || '');
                return;
            }
            const updatedValue = getUpdatedValue(
                isReferencedValueKey(staticColumns, field) ? { value } : value,
                field,
                row.original,
            );
            updateLead(leadId, field, updatedValue, initialValue);
        }
    };

    useEffect(() => {
        setValue(
            // @ts-ignore
            (isReferencedValueKey(staticColumns, field) ? initialValue?.value : initialValue) || '',
        );
        // @ts-ignore
        setValidation(validateEditorValue(initialValue, column.type));
        // @ts-ignore
    }, [initialValue, row.original.id, column.type, column, staticColumns, field]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        // @ts-ignore
        setValidation(validateEditorValue(newValue, column.type));
        setValue(newValue);
        setIsPopoverOpen(false);
    };

    const style = previewMode ? { color: 'black', fontSize: 'inherit' } : {};

    return (
        <InlineEditorContainer errorBorder={!previewMode && !isValid}>
            <UrlPopover url={value} open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
                <StyledInputEditor
                    style={{ ...style, backgroundColor: 'transparent' }}
                    previewMode={previewMode}
                    disabled={!isEditable || disabled}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                />
                {!isValid && <EditorErrorTooltip message={errorMessage} />}
            </UrlPopover>
        </InlineEditorContainer>
    );
};

export default InlineEditor;
