import useCoas from '@/hooks/useCoas';
import useStaticColumns from '@/hooks/useStaticColumns';
import { BooleanValues } from '@/types/enums';
import { Dispatch, InputHTMLAttributes } from 'react';

import { StyledInput } from '@components/BasicComponents/StyledInput';

import useAssignees from '../../../hooks/useAssignees';
import { StyledDatePicker } from '../../BasicComponents/DatePicker/StyledDatePicker';
import { SingleSelect } from '../../BasicComponents/SingleSelect/SingleSelect';

type TypeInputProps = InputHTMLAttributes<HTMLInputElement> & {
    value: any;
    setValue: Dispatch<any>;
    type: string;
    options: object;
    isValid?: boolean;
    accessor?: string;
};

// An input component based on type
const TypeInput = ({ value, setValue, type, options, isValid, accessor }: TypeInputProps) => {
    const { data: assignees } = useAssignees();
    const { data: staticColumns } = useStaticColumns();
    const { data: coas } = useCoas();

    const handleDateChange = (date: Date | null) => {
        setValue(date);
    };

    let formattedOptions: { label: string; value: any }[] = [];

    if (!assignees) {
        return null;
    }

    switch (type) {
        case 'single-select':
            if (!options) {
                if (accessor === staticColumns.ASSIGNEE.ACCESSOR) {
                    formattedOptions = assignees.map(assignee => ({
                        label: assignee.fullName,
                        value: assignee,
                    }));
                } else if (accessor === staticColumns.COA.ACCESSOR) {
                    formattedOptions = coas.map(coa => ({
                        label: coa.name!,
                        value: coa.id,
                    }));
                }
            } else {
                if (Array.isArray(options)) {
                    formattedOptions = options.map(option => ({
                        label: option,
                        value: option,
                    }));
                } else {
                    for (const [key, value] of Object.entries(options)) {
                        formattedOptions.push({
                            label: value,
                            value: key,
                        });
                    }
                }
            }

            return (
                <SingleSelect
                    options={formattedOptions}
                    value={value}
                    onChange={setValue}
                    isClearable
                />
            );
        case 'date':
            return (
                <StyledDatePicker
                    placeholderText="Select Date"
                    selected={value}
                    onChange={handleDateChange}
                />
            );
        case 'boolean':
            formattedOptions = [
                {
                    label: BooleanValues.TRUE,
                    value: true,
                },
                {
                    label: BooleanValues.FALSE,
                    value: false,
                },
            ];

            return (
                <SingleSelect
                    options={formattedOptions}
                    value={value}
                    onChange={setValue}
                    isClearable
                />
            );
        default:
            return (
                <StyledInput
                    type={type}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    error={!isValid}
                />
            );
    }
};

export default TypeInput;
