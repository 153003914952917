import { blackA } from '@radix-ui/colors';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import styled from 'styled-components';

const SCROLLBAR_SIZE = '0.5rem';

const StyledScrollArea = styled(ScrollAreaPrimitive.Root)`
    overflow: hidden;
`;

const StyledViewport = styled(ScrollAreaPrimitive.Viewport)`
    width: 100%;
    height: 100%;
    border-radius: inherit;
`;

const StyledScrollbar = styled(ScrollAreaPrimitive.Scrollbar)`
    display: flex;
    // ensures no selection
    user-select: none;
    // disable browser handling of all panning and zooming gestures on touch devices
    touch-action: none;
    padding: 0.25rem;
    transition: background 160ms ease-out;
    &:hover {
        background: #eeeeee;
    }
    &[data-orientation='vertical'] {
        width: ${SCROLLBAR_SIZE};
    }
    &[data-orientation='horizontal'] {
        flex-direction: column;
        height: ${SCROLLBAR_SIZE};
    }
`;

const StyledThumb = styled(ScrollAreaPrimitive.Thumb)`
    flex: 1;
    background: #cccccc;
    border-radius: ${SCROLLBAR_SIZE};
    // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        min-width: 44px;
        min-height: 44px;
    }
    z-index: 5;
`;

const StyledCorner = styled(ScrollAreaPrimitive.Corner)`
    background: ${blackA.blackA8};
`;

// Exports
export const ScrollArea = StyledScrollArea;
export const ScrollAreaViewport = StyledViewport;
export const ScrollAreaScrollbar = StyledScrollbar;
export const ScrollAreaThumb = StyledThumb;
export const ScrollAreaCorner = StyledCorner;
